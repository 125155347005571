.anchor-outline {
    border: solid 1px var(--ar-secondary);
    padding: 5px 15px;
    color: var(--ar-secondary);
    border-radius: 5px;
    transition: all 0s;
}

.anchor-outline:hover {
    border: solid 1px var(--ar-link-secondary-hover-color);
    color: var(--ar-link-secondary-hover-color);
}