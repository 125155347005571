#hero {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#hero .card-holder {
    max-width: 90%;
    max-height: 85%;
}

#hero .card-holder .card {
    background-color: var(--ar-secondary);
    border: none;
    padding: 2.5% 5%;
    border-radius: var(--standard-border-radius);
}

.react-parallax-bgimage {
    object-fit: cover;
}

@media (max-width: 768px) {
    #hero {
        height: 85vh;
    }

    #hero h1 {
        text-align: center;
        width: 100%;
    }

    #hero .contact-container {
        margin-top: 5%;
        flex-direction: column;
    }

    #hero .contact-container > button {
        width: 100%;
        margin-top: 8%;
        border-radius: 0 !important;
    }

    /*#hero .contact-container > a {
        margin-top: 8%;
        margin-left: 0 !important;
    }*/

    #hero .card-holder .card {
        margin-top: 10%;
        padding: 10% 0;
        border-radius: 0 !important;
    }
}

/* Very small screens.. */
@media (max-width: 500px) and (max-height: 600px) {
    #hero .card-title {
        font-size: 20px;
    }

    #hero .card-body > p {
        font-size: 14px !important;
    }

    #hero .contact-container > button {
        font-size: 12px;
    }

    #hero .contact-container > a {
        font-size: 10px;
    }

    #hero .card-holder {
        margin-top: var(--navbar-height);
    }
}

@media (min-width: 768px) {
    #hero {
        justify-content: flex-start;
        background-position-x: 0% !important;
    }

    #hero .card-holder {
        margin-left: 8%;
    }

    #hero .card-body > p {
        max-width: 50ch;
    }
}


@media (max-width: 1200px) and (max-height: 415px) {
    #hero .card {
        max-width: 100%;
        background-color: red;
        padding: 0 !important;
    }

    #hero {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 130vh;
    }
}