.client-item {
    text-align:center;
}

section#clients {
    scroll-margin-top: calc(var(--navbar-height) + 25vh);
}

@media (max-width: 768px) {
    .clients-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .clients-container {
        grid-template-columns: repeat(3, 1fr);
    }
}