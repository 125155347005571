.tools-section {
    display: flex;
    justify-content: center;
}

.tools-section .tools-card {
    background-color: var(--just-darker);
    padding: calc(var(--standard-x-padding) * 1.5) var(--standard-x-padding);
    border-radius: var(--standard-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7%;
}

.tools-section .tools-container {
    display: grid;
    flex: 1;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.tools-section .tool-item {
    width: 100%;
}

.tools-section .tool-item:hover {
    scale: 1.1;
}

.tools-section .tool-item > .logo {
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    aspect-ratio: 16 / 9;
    background-color: white;
    border-radius: var(--standard-border-radius);
}

.tools-section-descriptor {
    width: 55ch;
}

.tools-section-descriptor h1 {
    width: 10ch;
}

@media (max-width: 992px) {
    .tools-section .tools-card {
        flex-direction: column-reverse;
        width: 90%;
        background-color: white;
        padding: 0;
        margin-top: 0;
    }

    .tools-section .tools-container {
        grid-template-columns: repeat(2, 1fr);
        flex: auto;
        width: 100%;
        padding: 0;
    }

    .tools-section .tool-item > div {
        border-radius: 0 !important;
        background-color: var(--just-darker) !important;
    }

    .tools-section-descriptor {
        width: auto;
        margin-bottom: calc(var(--standard-seperation-distance) / 2);
    }

    .tools-section-descriptor > a {
        display: none;
    }
}