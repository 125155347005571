.university-engagement-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: var(--standard-seperation-distance) auto;
}

.university-engagement-content {
    width: 40%;
}

.university-engagement-thumbnail {
    background-image: url('../../../../assets/img/other/UniversityStudents.webp');
    background-size: cover;
    width: 30vw;
    height: 30vw;
    border-radius: var(--standard-border-radius) calc(var(--standard-border-radius) * 8) var(--standard-border-radius) calc(var(--standard-border-radius) * 8);
}


@media (max-width: 768px) {
    .university-engagement-section {
        flex-direction: column-reverse;
        padding-bottom: 0;
    }

    .university-engagement-thumbnail {
        width: 100%;
        height: 30vh;
        border-radius: 0px;
    }

    .university-engagement-content {
        margin-top: 10%;
        padding-bottom: 0;
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .university-engagement-section {
        flex-direction: row-reverse;
    }

    .university-engagement-content {
       background-color: var(--just-darker);
       display: flex;
       flex-direction: column;
       justify-content: center;
       width: 60%;
       height: 90vh;
       padding: var(--standard-x-padding);
    }

    .university-engagement-thumbnail {
        flex: 1;
        height: 90vh;
        border-radius: 0px;
    }
}

@media (min-width: 1500px) {
    .university-engagement-thumbnail {
        width: 500px;
        height: 500px;
    }
}