section#testimonials {
    margin-top: calc(var(--standard-seperation-distance));
    scroll-margin-top: calc(var(--navbar-height) + 20vh);
    display: flex;
    justify-content: center;
}

.qoute-container {
    display: grid;
    padding: 0 15%;
    grid-template-columns: 1fr 3fr;
    gap: calc(var(--standard-x-padding) * 2);
}

.quote-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.swiper {
    margin-top: 5%;
    width: 100%;
}

@media (max-width: 1200px) {
    .qoute-container {
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;
        width: 85%;
        padding: 0;
        text-align: center;
    }

    .swiper {
        margin-top: 5%;
    }

    .swiper * {
        font-size: 20px;
    }
}