.services-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.service-item {
    position: relative;
    height: 100%;
    padding: 8%;
    background-color: var(--just-darker);
    border-radius: var(--standard-border-radius);
    cursor: pointer;
}

.service-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.service-icon > h6 {
    text-align: right;
}

/* Sets inital opacity for fade animation. */
        .service-item * {
    opacity: 1;
    transition: opacity 0.4s;
}

.service-item img {
    margin-bottom: 30px;
    width: 40px;
}

/* Service description. */
.service-item p {
    margin-top: 15px;
    line-height: 180%;
    padding-bottom: 8%;
}

.service-item .service-footer {
    position: absolute;
    bottom: 4%;
}

.service-item:hover * {
    opacity: 0;
}

.service-item:hover button {
    color: var(--ar-primary) !important;
    opacity: 1; /* Do not hide button. */
}

/* Fullsize background which masks over container. */
.service-item .hover-bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
    transition: opacity 0.4s;

    background-size: cover;
    background-position: center;
    border-radius: var(--standard-border-radius);
}

/* Reveals a full-size background on hover which fades. */
.service-item:hover .hover-bg {
    opacity: 1;
}

.service-subtitle {
    color: var(--ar-gray-800);
}



@media (max-width: 768px) {
    .services-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .service-item .service-footer {
        bottom: 2%;
    }

    .service-item {
        text-align: center;
    }

    .service-item .service-footer {
        display: none;
    }

    .service-icon {
        justify-content: center;
    }

    .service-icon > h6 {
        text-align: center;
    }

    .service-icon > img {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .services-container {
        grid-template-columns: repeat(2, 1fr);
    }
}