@charset "UTF-8";
/*
 * Around | Multipurpose Bootstrap HTML Template
 * Copyright 2022 Createx Studio
 * Theme styles
*/
:root {
  --ar-blue: #0d6efd;
  --ar-indigo: #6610f2;
  --ar-purple: #6f42c1;
  --ar-pink: #d63384;
  --ar-red: #dc3545;
  --ar-orange: #fd7e14;
  --ar-yellow: #ffc107;
  --ar-green: #198754;
  --ar-teal: #20c997;
  --ar-cyan: #0dcaf0;
  --ar-black: #000;
  --ar-white: #fff;
  --ar-gray: #858c97;
  --ar-gray-dark: #434a57;
  --ar-gray-100: #f6f9fc;
  --ar-gray-200: #edf1f5;
  --ar-gray-300: #e3e9ef;
  --ar-gray-400: #d7dde2;
  --ar-gray-500: #b4bbc3;
  --ar-gray-600: #858c97;
  --ar-gray-700: #576071;
  --ar-gray-800: #434a57;
  --ar-gray-900: #121519;
  --ar-primary: #1B75BB;
  --ar-primary-hover: #103857;
  --ar-secondary: #1C2E3C;
  --ar-success: #3fca90;
  --ar-info: #3f7fca;
  --ar-warning: #edcb50;
  --ar-danger: #ed5050;
  --ar-light: #fff;
  --ar-dark: #121519;
  --ar-primary-rgb: 27, 117, 187;
  --ar-secondary-rgb: 28, 46, 60;
  --ar-success-rgb: 63, 202, 144;
  --ar-info-rgb: 63, 127, 202;
  --ar-warning-rgb: 237, 203, 80;
  --ar-danger-rgb: 237, 80, 80;
  --ar-light-rgb: 255, 255, 255;
  --ar-dark-rgb: 18, 21, 25;
  --ar-white-rgb: 255, 255, 255;
  --ar-black-rgb: 0, 0, 0;
  --ar-body-color-rgb: 87, 96, 113;
  --ar-body-bg-rgb: 255, 255, 255;
  --ar-font-sans-serif: "Inter", sans-serif;
  --ar-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --ar-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --ar-root-font-size: 1rem;
  --ar-body-font-family: var(--ar-font-sans-serif);
  --ar-body-font-size: 1rem;
  --ar-body-font-weight: 400;
  --ar-body-line-height: 1.5;
  --ar-body-color: #576071;
  --ar-body-bg: #fff;
  --ar-border-width: 1px;
  --ar-border-style: solid;
  --ar-border-color: #e3e9ef;
  --ar-border-color-translucent: rgba(0, 0, 0, 0.175);
  --ar-border-radius: 1rem;
  --ar-border-radius-sm: calc(var(--ar-border-radius) * 0.75);
  --ar-border-radius-lg: calc(var(--ar-border-radius) * 1.125);
  --ar-border-radius-xl: calc(var(--ar-border-radius) * 1.5);
  --ar-border-radius-2xl: calc(var(--ar-border-radius) * 2.25);
  --ar-border-radius-pill: 50rem;
  --ar-link-color: #231F20;
  --ar-link-hover-color: var(--ar-primary);
  --ar-link-secondary-hover-color: #0d151c;
  --ar-code-color: #e3116c;
  --ar-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--ar-root-font-size);
}

body {
  margin: 0;
  font-family: var(--ar-body-font-family);
  font-size: var(--ar-body-font-size);
  font-weight: var(--ar-body-font-weight);
  line-height: var(--ar-body-line-height);
  color: var(--ar-body-color);
  text-align: var(--ar-body-text-align);
  background-color: var(--ar-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 0 0;
  color: var(--ar-border-color);
  border: 0;
  border-top: var(--ar-border-width) solid;
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--ar-gray-900);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.125rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 800;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--ar-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--ar-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--ar-link-hover-color);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--ar-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  color: #fff;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--ar-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #010101;
  border-radius: calc(var(--ar-border-radius) * 0.75);
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--ar-gray-600);
  text-align: left;
}

th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 400;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.5rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.3875rem + 1.65vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.625rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 1rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.5rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: var(--ar-gray-900);
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.75rem;
  background-color: transparent;
  border: var(--ar-border-width) solid var(--ar-border-color);
  border-radius: var(--ar-border-radius-2xl);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: calc(var(--ar-body-font-size) * 0.75);
  color: var(--ar-gray-600);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --ar-gutter-x: 1.5rem;
  --ar-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--ar-gutter-x) * 0.5);
  padding-left: calc(var(--ar-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --ar-gutter-x: 1.5rem;
  --ar-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ar-gutter-y));
  margin-right: calc(-0.5 * var(--ar-gutter-x));
  margin-left: calc(-0.5 * var(--ar-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--ar-gutter-x) * 0.5);
  padding-left: calc(var(--ar-gutter-x) * 0.5);
  margin-top: var(--ar-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --ar-gutter-x: 0;
}

.g-0,
.gy-0 {
  --ar-gutter-y: 0;
}

.g-1,
.gx-1 {
  --ar-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --ar-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --ar-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --ar-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --ar-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --ar-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --ar-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --ar-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --ar-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --ar-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --ar-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --ar-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --ar-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --ar-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --ar-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --ar-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --ar-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --ar-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --ar-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --ar-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --ar-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --ar-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --ar-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --ar-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --ar-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --ar-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --ar-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --ar-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --ar-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --ar-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --ar-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --ar-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --ar-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --ar-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --ar-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --ar-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --ar-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --ar-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --ar-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --ar-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --ar-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --ar-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --ar-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --ar-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --ar-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --ar-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --ar-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --ar-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --ar-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --ar-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --ar-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --ar-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --ar-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --ar-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --ar-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --ar-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --ar-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --ar-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --ar-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --ar-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --ar-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --ar-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --ar-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --ar-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --ar-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --ar-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --ar-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --ar-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --ar-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --ar-gutter-y: 3rem;
  }
}
.table {
  --ar-table-color: var(--ar-body-color);
  --ar-table-bg: transparent;
  --ar-table-border-color: var(--ar-border-color);
  --ar-table-accent-bg: transparent;
  --ar-table-striped-color: var(--ar-body-color);
  --ar-table-striped-bg: rgba(0, 0, 0, 0.0375);
  --ar-table-active-color: var(--ar-body-color);
  --ar-table-active-bg: rgba(0, 0, 0, 0.05);
  --ar-table-hover-color: var(--ar-body-color);
  --ar-table-hover-bg: rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--ar-table-color);
  vertical-align: top;
  border-color: var(--ar-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--ar-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--ar-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.375rem 0.375rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --ar-table-accent-bg: var(--ar-table-striped-bg);
  color: var(--ar-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --ar-table-accent-bg: var(--ar-table-striped-bg);
  color: var(--ar-table-striped-color);
}

.table-active {
  --ar-table-accent-bg: var(--ar-table-active-bg);
  color: var(--ar-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --ar-table-accent-bg: var(--ar-table-hover-bg);
  color: var(--ar-table-hover-color);
}

.table-dark {
  --ar-table-color: #fff;
  --ar-table-bg: #121519;
  --ar-table-border-color: #36383c;
  --ar-table-striped-bg: #1b1e22;
  --ar-table-striped-color: #fff;
  --ar-table-active-bg: #1e2125;
  --ar-table-active-color: #fff;
  --ar-table-hover-bg: #1e2125;
  --ar-table-hover-color: #fff;
  color: var(--ar-table-color);
  border-color: var(--ar-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.3125rem;
  font-size: calc(var(--ar-body-font-size) * 0.875);
  font-weight: 500;
  color: var(--ar-gray-900);
}

.col-form-label {
  padding-top: calc(0.825rem + var(--ar-border-width));
  padding-bottom: calc(0.825rem + var(--ar-border-width));
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.4;
  color: var(--ar-gray-900);
}

.col-form-label-lg {
  padding-top: calc(1rem + var(--ar-border-width));
  padding-bottom: calc(1rem + var(--ar-border-width));
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.575rem + var(--ar-border-width));
  padding-bottom: calc(0.575rem + var(--ar-border-width));
  font-size: calc(var(--ar-body-font-size) * 0.75);
}

.form-text {
  margin-top: 0.25rem;
  font-size: calc(var(--ar-body-font-size) * 0.75);
  color: var(--ar-gray-600);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.825rem 1rem;
  font-size: calc(var(--ar-body-font-size) * 0.875);
  font-weight: 400;
  line-height: 1.4;
  color: var(--ar-body-color);
  background-color: transparent;
  background-clip: padding-box;
  border: var(--ar-border-width) solid var(--ar-gray-400);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: var(--ar-border-radius);
  transition: border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--ar-body-color);
  background-color: transparent;
  border-color: var(--ar-gray-700);
  outline: 0;
  box-shadow: unset;
}
.form-control::-webkit-date-and-time-value {
  height: 1.4em;
}
.form-control::-moz-placeholder {
  color: var(--ar-gray-500);
  opacity: 1;
}
.form-control::placeholder {
  color: var(--ar-gray-500);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--ar-gray-100);
  border-color: var(--ar-gray-300);
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: 0.825rem 1rem;
  margin: -0.825rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: var(--ar-body-color);
  background-color: transparent;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--ar-border-width);
  border-radius: 0;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.form-control::file-selector-button {
  padding: 0.825rem 1rem;
  margin: -0.825rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: var(--ar-body-color);
  background-color: transparent;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--ar-border-width);
  border-radius: 0;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: rgba(0, 0, 0, 0.05);
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: rgba(0, 0, 0, 0.05);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.825rem 0;
  margin-bottom: 0;
  line-height: 1.4;
  color: #576071;
  background-color: transparent;
  border: solid transparent;
  border-width: var(--ar-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.4em + 1.15rem + calc(var(--ar-border-width) * 2));
  padding: 0.575rem 0.875rem;
  font-size: calc(var(--ar-body-font-size) * 0.75);
  border-radius: var(--ar-border-radius-sm);
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.575rem 0.875rem;
  margin: -0.575rem -0.875rem;
  -webkit-margin-end: 0.875rem;
  margin-inline-end: 0.875rem;
}
.form-control-sm::file-selector-button {
  padding: 0.575rem 0.875rem;
  margin: -0.575rem -0.875rem;
  -webkit-margin-end: 0.875rem;
  margin-inline-end: 0.875rem;
}

.form-control-lg {
  min-height: calc(1.4em + 2rem + calc(var(--ar-border-width) * 2));
  padding: 1rem 1.125rem;
  font-size: 1rem;
  border-radius: var(--ar-border-radius-lg);
}
.form-control-lg::-webkit-file-upload-button {
  padding: 1rem 1.125rem;
  margin: -1rem -1.125rem;
  -webkit-margin-end: 1.125rem;
  margin-inline-end: 1.125rem;
}
.form-control-lg::file-selector-button {
  padding: 1rem 1.125rem;
  margin: -1rem -1.125rem;
  -webkit-margin-end: 1.125rem;
  margin-inline-end: 1.125rem;
}

textarea.form-control {
  min-height: calc(1.4em + 1.65rem + calc(var(--ar-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.4em + 1.15rem + calc(var(--ar-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.4em + 2rem + calc(var(--ar-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.4em + 1.65rem + calc(var(--ar-border-width) * 2));
  padding: 0.825rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--ar-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border-radius: var(--ar-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.4em + 1.15rem + calc(var(--ar-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.4em + 2rem + calc(var(--ar-border-width) * 2));
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.825rem 3rem 0.825rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: calc(var(--ar-body-font-size) * 0.875);
  font-weight: 400;
  line-height: 1.4;
  color: var(--ar-body-color);
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23697488' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: var(--ar-border-width) solid var(--ar-gray-400);
  border-radius: var(--ar-border-radius);
  transition: border-color 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: var(--ar-gray-700);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(68, 140, 116, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--ar-gray-100);
  border-color: var(--ar-gray-300);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--ar-body-color);
}

.form-select-sm {
  padding-top: 0.575rem;
  padding-bottom: 0.575rem;
  padding-left: 0.875rem;
  font-size: calc(var(--ar-body-font-size) * 0.75);
  border-radius: var(--ar-border-radius-sm);
}

.form-select-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.125rem;
  font-size: 1rem;
  border-radius: var(--ar-border-radius-lg);
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.625rem;
  margin-bottom: 0.325rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.625rem;
}

.form-check-reverse {
  padding-right: 1.625rem;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.625rem;
  margin-left: 0;
}

.form-check-input {
  width: 1.125rem;
  height: 1.125rem;
  margin-top: 0.1875rem;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid var(--ar-gray-500);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.375rem;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: var(--ar-gray-700);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(68, 140, 116, 0.25);
}
.form-check-input:checked {
  background-color: var(--ar-primary);
  border-color: var(--ar-primary);
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #448c74;
  border-color: #448c74;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.6;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.6;
}

.form-check-label {
  color: var(--ar-body-color);
}

.form-switch {
  padding-left: 3.375em;
}
.form-switch .form-check-input {
  width: 2.875em;
  margin-left: -3.375em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2.875em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 3.375em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -3.375em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, unset;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, unset;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: var(--ar-primary);
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: var(--ar-primary);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--ar-gray-300);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--ar-primary);
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: var(--ar-primary);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--ar-gray-300);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #b4bbc3;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #b4bbc3;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--ar-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--ar-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--ar-border-width) 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.825rem 0.75rem;
  font-size: calc(var(--ar-body-font-size) * 0.875);
  font-weight: 500;
  line-height: 1.4;
  color: var(--ar-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: var(--ar-border-width) solid transparent;
  border-radius: var(--ar-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1rem 1.125rem;
  font-size: 1rem;
  border-radius: var(--ar-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.575rem 0.875rem;
  font-size: calc(var(--ar-body-font-size) * 0.75);
  border-radius: var(--ar-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -var(--ar-border-width);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: calc(var(--ar-body-font-size) * 0.75);
  color: #3fca90;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: 0.1rem;
  font-size: calc(var(--ar-body-font-size) * 0.75);
  color: #000;
  background-color: rgba(63, 202, 144, 0.9);
  border-radius: calc(var(--ar-border-radius) * 0.5);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3fca90;
  padding-right: calc(1.4em + 1.65rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%233fca90'%3e%3cpath d='M12 3.9c-4.5 0-8.1 3.6-8.1 8.1s3.6 8.1 8.1 8.1 8.1-3.6 8.1-8.1-3.6-8.1-8.1-8.1zM2.5 12c0-5.2 4.3-9.5 9.5-9.5s9.5 4.2 9.5 9.5c0 5.2-4.2 9.5-9.5 9.5-5.2 0-9.5-4.3-9.5-9.5zm14.3-4c.3.2.4.6.2.9L12.4 15c-.4.5-1.2.6-1.7.1L8 12.4c-.3-.3-.3-.7 0-1s.7-.3 1 0l2.6 2.6L16 8c0-.2.5-.2.8 0z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.35em + 0.4125rem) center;
  background-size: calc(0.7em + 0.825rem) calc(0.7em + 0.825rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #3fca90;
  box-shadow: 0 0 0 0 rgba(63, 202, 144, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.4em + 1.65rem);
  background-position: top calc(0.35em + 0.4125rem) right calc(0.35em + 0.4125rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #3fca90;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23697488' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%233fca90'%3e%3cpath d='M12 3.9c-4.5 0-8.1 3.6-8.1 8.1s3.6 8.1 8.1 8.1 8.1-3.6 8.1-8.1-3.6-8.1-8.1-8.1zM2.5 12c0-5.2 4.3-9.5 9.5-9.5s9.5 4.2 9.5 9.5c0 5.2-4.2 9.5-9.5 9.5-5.2 0-9.5-4.3-9.5-9.5zm14.3-4c.3.2.4.6.2.9L12.4 15c-.4.5-1.2.6-1.7.1L8 12.4c-.3-.3-.3-.7 0-1s.7-.3 1 0l2.6 2.6L16 8c0-.2.5-.2.8 0z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.7em + 0.825rem) calc(0.7em + 0.825rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #3fca90;
  box-shadow: 0 0 0 0 rgba(63, 202, 144, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.4em + 1.65rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #3fca90;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #3fca90;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(63, 202, 144, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3fca90;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: calc(var(--ar-body-font-size) * 0.75);
  color: #ed5050;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: 0.1rem;
  font-size: calc(var(--ar-body-font-size) * 0.75);
  color: #000;
  background-color: rgba(237, 80, 80, 0.9);
  border-radius: calc(var(--ar-border-radius) * 0.5);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ed5050;
  padding-right: calc(1.4em + 1.65rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 2.5c-5.2 0-9.5 4.3-9.5 9.5s4.2 9.5 9.5 9.5c5.2 0 9.5-4.2 9.5-9.5 0-5.2-4.3-9.5-9.5-9.5zM6.7 5.8C8.1 4.6 10 3.9 12 3.9c4.5 0 8.1 3.6 8.1 8.1 0 2-.7 3.9-1.9 5.3L6.7 5.8zm-.9.9C4.6 8.1 3.9 10 3.9 12c0 4.5 3.6 8.1 8.1 8.1 2 0 3.9-.7 5.3-1.9L5.8 6.7z' fill-rule='evenodd' fill='%23ed5050'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.35em + 0.4125rem) center;
  background-size: calc(0.7em + 0.825rem) calc(0.7em + 0.825rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ed5050;
  box-shadow: 0 0 0 0 rgba(237, 80, 80, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.4em + 1.65rem);
  background-position: top calc(0.35em + 0.4125rem) right calc(0.35em + 0.4125rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ed5050;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23697488' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 2.5c-5.2 0-9.5 4.3-9.5 9.5s4.2 9.5 9.5 9.5c5.2 0 9.5-4.2 9.5-9.5 0-5.2-4.3-9.5-9.5-9.5zM6.7 5.8C8.1 4.6 10 3.9 12 3.9c4.5 0 8.1 3.6 8.1 8.1 0 2-.7 3.9-1.9 5.3L6.7 5.8zm-.9.9C4.6 8.1 3.9 10 3.9 12c0 4.5 3.6 8.1 8.1 8.1 2 0 3.9-.7 5.3-1.9L5.8 6.7z' fill-rule='evenodd' fill='%23ed5050'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.7em + 0.825rem) calc(0.7em + 0.825rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #ed5050;
  box-shadow: 0 0 0 0 rgba(237, 80, 80, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.4em + 1.65rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ed5050;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #ed5050;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(237, 80, 80, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ed5050;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --ar-btn-padding-x: 1.75rem;
  --ar-btn-padding-y: 0.825rem;
  --ar-btn-font-family: ;
  --ar-btn-font-size: calc(var(--ar-body-font-size) * 0.875);
  --ar-btn-font-weight: 600;
  --ar-btn-line-height: 1.4;
  --ar-btn-color: #576071;
  --ar-btn-bg: transparent;
  --ar-btn-border-width: var(--ar-border-width);
  --ar-btn-border-color: transparent;
  --ar-btn-border-radius: var(--ar-border-radius);
  --ar-btn-hover-border-color: transparent;
  --ar-btn-box-shadow: unset;
  --ar-btn-disabled-opacity: 0.65;
  --ar-btn-focus-box-shadow: 0 0 0 0 rgba(var(--ar-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--ar-btn-padding-y) var(--ar-btn-padding-x);
  font-family: var(--ar-btn-font-family);
  font-size: var(--ar-btn-font-size);
  font-weight: var(--ar-btn-font-weight);
  line-height: var(--ar-btn-line-height);
  color: var(--ar-btn-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--ar-btn-border-width) solid var(--ar-btn-border-color);
  border-radius: var(--ar-btn-border-radius);
  background-color: var(--ar-btn-bg);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--ar-btn-hover-color);
  background-color: var(--ar-btn-hover-bg);
  border-color: var(--ar-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--ar-btn-color);
  background-color: var(--ar-btn-bg);
  border-color: var(--ar-btn-border-color);
}
.btn:focus-visible {
  color: var(--ar-btn-hover-color);
  background-color: var(--ar-btn-hover-bg);
  border-color: var(--ar-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--ar-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--ar-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--ar-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--ar-btn-active-color);
  background-color: var(--ar-btn-active-bg);
  border-color: var(--ar-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--ar-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--ar-btn-disabled-color);
  pointer-events: none;
  background-color: var(--ar-btn-disabled-bg);
  border-color: var(--ar-btn-disabled-border-color);
  opacity: var(--ar-btn-disabled-opacity);
}

.btn-primary {
  --ar-btn-color: #000;
  --ar-btn-bg: var(--ar-primary);
  --ar-btn-border-color: var(--ar-primary);
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: var(--ar-primary-hover);
  --ar-btn-hover-border-color: var(--ar-primary-hover);
  --ar-btn-focus-shadow-rgb: 58, 119, 99;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #69a390;
  --ar-btn-active-border-color: #579882;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #000;
  --ar-btn-disabled-bg: #448c74;
  --ar-btn-disabled-border-color: #448c74;
}

.btn-secondary {
  --ar-btn-color: white;
  --ar-btn-bg: var(--ar-secondary);
  --ar-btn-border-color: #edf1f5;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: var(--ar-link-secondary-hover-color);
  --ar-btn-hover-border-color: #eff2f6;
  --ar-btn-focus-shadow-rgb: 201, 205, 208;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #f1f4f7;
  --ar-btn-active-border-color: #eff2f6;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #000;
  --ar-btn-disabled-bg: #edf1f5;
  --ar-btn-disabled-border-color: #edf1f5;
}

.btn-success {
  --ar-btn-color: #000;
  --ar-btn-bg: #3fca90;
  --ar-btn-border-color: #3fca90;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #5cd2a1;
  --ar-btn-hover-border-color: #52cf9b;
  --ar-btn-focus-shadow-rgb: 54, 172, 122;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #65d5a6;
  --ar-btn-active-border-color: #52cf9b;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #000;
  --ar-btn-disabled-bg: #3fca90;
  --ar-btn-disabled-border-color: #3fca90;
}

.btn-info {
  --ar-btn-color: #000;
  --ar-btn-bg: #3f7fca;
  --ar-btn-border-color: #3f7fca;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #5c92d2;
  --ar-btn-hover-border-color: #528ccf;
  --ar-btn-focus-shadow-rgb: 54, 108, 172;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #6599d5;
  --ar-btn-active-border-color: #528ccf;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #000;
  --ar-btn-disabled-bg: #3f7fca;
  --ar-btn-disabled-border-color: #3f7fca;
}

.btn-warning {
  --ar-btn-color: #000;
  --ar-btn-bg: #edcb50;
  --ar-btn-border-color: #edcb50;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #f0d36a;
  --ar-btn-hover-border-color: #efd062;
  --ar-btn-focus-shadow-rgb: 201, 173, 68;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #f1d573;
  --ar-btn-active-border-color: #efd062;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #000;
  --ar-btn-disabled-bg: #edcb50;
  --ar-btn-disabled-border-color: #edcb50;
}

.btn-danger {
  --ar-btn-color: #000;
  --ar-btn-bg: #ed5050;
  --ar-btn-border-color: #ed5050;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #f06a6a;
  --ar-btn-hover-border-color: #ef6262;
  --ar-btn-focus-shadow-rgb: 201, 68, 68;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #f17373;
  --ar-btn-active-border-color: #ef6262;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #000;
  --ar-btn-disabled-bg: #ed5050;
  --ar-btn-disabled-border-color: #ed5050;
}

.btn-light, .dark-mode .btn-dark:not(.ignore-dark-mode), .card-hover-primary:hover .btn-primary {
  --ar-btn-color: #000;
  --ar-btn-bg: #fff;
  --ar-btn-border-color: #fff;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #d9d9d9;
  --ar-btn-hover-border-color: #cccccc;
  --ar-btn-focus-shadow-rgb: 217, 217, 217;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #cccccc;
  --ar-btn-active-border-color: #bfbfbf;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #000;
  --ar-btn-disabled-bg: #fff;
  --ar-btn-disabled-border-color: #fff;
}

.btn-dark {
  --ar-btn-color: #fff;
  --ar-btn-bg: #121519;
  --ar-btn-border-color: #121519;
  --ar-btn-hover-color: #fff;
  --ar-btn-hover-bg: #36383c;
  --ar-btn-hover-border-color: #2a2c30;
  --ar-btn-focus-shadow-rgb: 54, 56, 60;
  --ar-btn-active-color: #fff;
  --ar-btn-active-bg: #414447;
  --ar-btn-active-border-color: #2a2c30;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #fff;
  --ar-btn-disabled-bg: #121519;
  --ar-btn-disabled-border-color: #121519;
}

.btn-outline-primary {
  --ar-btn-color: #448c74;
  --ar-btn-border-color: #448c74;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #448c74;
  --ar-btn-hover-border-color: #448c74;
  --ar-btn-focus-shadow-rgb: 68, 140, 116;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #448c74;
  --ar-btn-active-border-color: #448c74;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #448c74;
  --ar-btn-disabled-bg: transparent;
  --ar-btn-disabled-border-color: #448c74;
  --ar-gradient: none;
}

.btn-outline-secondary {
  --ar-btn-color: #edf1f5;
  --ar-btn-border-color: #edf1f5;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #edf1f5;
  --ar-btn-hover-border-color: #edf1f5;
  --ar-btn-focus-shadow-rgb: 237, 241, 245;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #edf1f5;
  --ar-btn-active-border-color: #edf1f5;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #edf1f5;
  --ar-btn-disabled-bg: transparent;
  --ar-btn-disabled-border-color: #edf1f5;
  --ar-gradient: none;
}

.btn-outline-success {
  --ar-btn-color: #3fca90;
  --ar-btn-border-color: #3fca90;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #3fca90;
  --ar-btn-hover-border-color: #3fca90;
  --ar-btn-focus-shadow-rgb: 63, 202, 144;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #3fca90;
  --ar-btn-active-border-color: #3fca90;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #3fca90;
  --ar-btn-disabled-bg: transparent;
  --ar-btn-disabled-border-color: #3fca90;
  --ar-gradient: none;
}

.btn-outline-info {
  --ar-btn-color: #3f7fca;
  --ar-btn-border-color: #3f7fca;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #3f7fca;
  --ar-btn-hover-border-color: #3f7fca;
  --ar-btn-focus-shadow-rgb: 63, 127, 202;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #3f7fca;
  --ar-btn-active-border-color: #3f7fca;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #3f7fca;
  --ar-btn-disabled-bg: transparent;
  --ar-btn-disabled-border-color: #3f7fca;
  --ar-gradient: none;
}

.btn-outline-warning {
  --ar-btn-color: #edcb50;
  --ar-btn-border-color: #edcb50;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #edcb50;
  --ar-btn-hover-border-color: #edcb50;
  --ar-btn-focus-shadow-rgb: 237, 203, 80;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #edcb50;
  --ar-btn-active-border-color: #edcb50;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #edcb50;
  --ar-btn-disabled-bg: transparent;
  --ar-btn-disabled-border-color: #edcb50;
  --ar-gradient: none;
}

.btn-outline-danger {
  --ar-btn-color: #ed5050;
  --ar-btn-border-color: #ed5050;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #ed5050;
  --ar-btn-hover-border-color: #ed5050;
  --ar-btn-focus-shadow-rgb: 237, 80, 80;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #ed5050;
  --ar-btn-active-border-color: #ed5050;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #ed5050;
  --ar-btn-disabled-bg: transparent;
  --ar-btn-disabled-border-color: #ed5050;
  --ar-gradient: none;
}

.btn-outline-light, .dark-mode .btn-outline-dark:not(.ignore-dark-mode), .card-hover-primary:hover .btn-outline-primary {
  --ar-btn-color: #fff;
  --ar-btn-border-color: #fff;
  --ar-btn-hover-color: #000;
  --ar-btn-hover-bg: #fff;
  --ar-btn-hover-border-color: #fff;
  --ar-btn-focus-shadow-rgb: 255, 255, 255;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #fff;
  --ar-btn-active-border-color: #fff;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #fff;
  --ar-btn-disabled-bg: transparent;
  --ar-btn-disabled-border-color: #fff;
  --ar-gradient: none;
}

.btn-outline-dark {
  --ar-btn-color: #121519;
  --ar-btn-border-color: #121519;
  --ar-btn-hover-color: #fff;
  --ar-btn-hover-bg: #121519;
  --ar-btn-hover-border-color: #121519;
  --ar-btn-focus-shadow-rgb: 18, 21, 25;
  --ar-btn-active-color: #fff;
  --ar-btn-active-bg: #121519;
  --ar-btn-active-border-color: #121519;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #121519;
  --ar-btn-disabled-bg: transparent;
  --ar-btn-disabled-border-color: #121519;
  --ar-gradient: none;
}

.btn-link {
  --ar-btn-font-weight: 400;
  --ar-btn-color: var(--ar-link-color);
  --ar-btn-bg: transparent;
  --ar-btn-border-color: transparent;
  --ar-btn-hover-color: var(--ar-link-hover-color);
  --ar-btn-hover-border-color: transparent;
  --ar-btn-active-color: var(--ar-link-hover-color);
  --ar-btn-active-border-color: transparent;
  --ar-btn-disabled-color: #858c97;
  --ar-btn-disabled-border-color: transparent;
  --ar-btn-box-shadow: none;
  --ar-btn-focus-shadow-rgb: 58, 119, 99;
  text-decoration: underline;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--ar-btn-color);
}
.btn-link:hover {
  color: var(--ar-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --ar-btn-padding-y: 1rem;
  --ar-btn-padding-x: 2rem;
  --ar-btn-font-size: 1rem;
  --ar-btn-border-radius: var(--ar-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --ar-btn-padding-y: 0.575rem;
  --ar-btn-padding-x: 1.25rem;
  --ar-btn-font-size: calc(var(--ar-body-font-size) * 0.75);
  --ar-btn-border-radius: var(--ar-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --ar-dropdown-zindex: 1000;
  --ar-dropdown-min-width: 12rem;
  --ar-dropdown-padding-x: 0;
  --ar-dropdown-padding-y: 0.75rem;
  --ar-dropdown-spacer: 0.25rem;
  --ar-dropdown-font-size: calc(var(--ar-body-font-size) * 0.875);
  --ar-dropdown-color: #576071;
  --ar-dropdown-bg: #fff;
  --ar-dropdown-border-color: #f0f3f6;
  --ar-dropdown-border-radius: var(--ar-border-radius-lg);
  --ar-dropdown-border-width: var(--ar-border-width);
  --ar-dropdown-inner-border-radius: calc(var(--ar-border-radius-lg) - (var(--ar-border-width)));
  --ar-dropdown-divider-bg: var(--ar-border-color);
  --ar-dropdown-divider-margin-y: 0.375rem;
  --ar-dropdown-box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32);
  --ar-dropdown-link-color: var(--ar-gray-800);
  --ar-dropdown-link-hover-color: var(--ar-primary);
  --ar-dropdown-link-hover-bg: transparent;
  --ar-dropdown-link-active-color: var(--ar-primary);
  --ar-dropdown-link-active-bg: transparent;
  --ar-dropdown-link-disabled-color: var(--ar-gray-600);
  --ar-dropdown-item-padding-x: 1.25rem;
  --ar-dropdown-item-padding-y: 0.375rem;
  --ar-dropdown-header-color: var(--ar-gray-900);
  --ar-dropdown-header-padding-x: 1.25rem;
  --ar-dropdown-header-padding-y: 0.75rem;
  position: absolute;
  z-index: var(--ar-dropdown-zindex);
  display: none;
  min-width: var(--ar-dropdown-min-width);
  padding: var(--ar-dropdown-padding-y) var(--ar-dropdown-padding-x);
  margin: 0;
  font-size: var(--ar-dropdown-font-size);
  color: var(--ar-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--ar-dropdown-bg);
  background-clip: padding-box;
  border: var(--ar-dropdown-border-width) solid var(--ar-dropdown-border-color);
  border-radius: var(--ar-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--ar-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--ar-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--ar-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--ar-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.15em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--ar-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--ar-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--ar-dropdown-item-padding-y) var(--ar-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--ar-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--ar-dropdown-link-hover-color);
  background-color: var(--ar-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--ar-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--ar-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--ar-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--ar-dropdown-header-padding-y) var(--ar-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: calc(var(--ar-body-font-size) * 0.875);
  color: var(--ar-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--ar-dropdown-item-padding-y) var(--ar-dropdown-item-padding-x);
  color: var(--ar-dropdown-link-color);
}

.dropdown-menu-dark, .dark-mode .dropdown-menu:not(.ignore-dark-mode) {
  --ar-dropdown-color: rgba(255, 255, 255, 0.7);
  --ar-dropdown-bg: var(--ar-dark);
  --ar-dropdown-border-color: rgba(255, 255, 255, 0.13);
  --ar-dropdown-box-shadow: 0 0.4375rem 1.25rem rgba(0, 0, 0, 0.4);
  --ar-dropdown-link-color: rgba(255, 255, 255, 0.9);
  --ar-dropdown-link-hover-color: var(--ar-primary);
  --ar-dropdown-divider-bg: rgba(255, 255, 255, 0.13);
  --ar-dropdown-link-hover-bg: transparent;
  --ar-dropdown-link-active-color: var(--ar-primary);
  --ar-dropdown-link-active-bg: transparent;
  --ar-dropdown-link-disabled-color: rgba(255, 255, 255, 0.5);
  --ar-dropdown-header-color: #fff;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--ar-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -var(--ar-border-width);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -var(--ar-border-width);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --ar-nav-link-padding-x: 1.75rem;
  --ar-nav-link-padding-y: 0.725rem;
  --ar-nav-link-font-weight: 500;
  --ar-nav-link-color: var(--ar-gray-800);
  --ar-nav-link-hover-color: var(--ar-primary);
  --ar-nav-link-disabled-color: var(--ar-gray-600);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--ar-nav-link-padding-y) var(--ar-nav-link-padding-x);
  font-size: var(--ar-nav-link-font-size);
  font-weight: var(--ar-nav-link-font-weight);
  color: var(--ar-nav-link-color);
  text-decoration: none;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--ar-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--ar-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --ar-nav-tabs-border-width: var(--ar-border-width);
  --ar-nav-tabs-border-color: transparent;
  --ar-nav-tabs-border-radius: var(--ar-border-radius);
  --ar-nav-tabs-link-hover-border-color: transparent;
  --ar-nav-tabs-link-active-color: var(--ar-primary);
  --ar-nav-tabs-link-active-bg: transparent;
  --ar-nav-tabs-link-active-border-color: var(--ar-primary);
  border-bottom: var(--ar-nav-tabs-border-width) solid var(--ar-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--ar-nav-tabs-border-width));
  background: none;
  border: var(--ar-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--ar-nav-tabs-border-radius);
  border-top-right-radius: var(--ar-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--ar-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--ar-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--ar-nav-tabs-link-active-color);
  background-color: var(--ar-nav-tabs-link-active-bg);
  border-color: var(--ar-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--ar-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --ar-nav-pills-border-radius: 50rem;
  --ar-nav-pills-link-active-color: #fff;
  --ar-nav-pills-link-active-bg: var(--ar-primary);
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--ar-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--ar-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--ar-nav-pills-link-active-color);
  background-color: var(--ar-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --ar-navbar-padding-x: 0;
  --ar-navbar-padding-y: 0.75rem;
  --ar-navbar-color: var(--ar-gray-800);
  --ar-navbar-hover-color: var(--ar-primary);
  --ar-navbar-disabled-color: var(--ar-gray-600);
  --ar-navbar-active-color: var(--ar-primary);
  --ar-navbar-brand-padding-y: 0.5rem;
  --ar-navbar-brand-margin-end: 1rem;
  --ar-navbar-brand-font-size: 1.375rem;
  --ar-navbar-brand-color: var(--ar-gray-800);
  --ar-navbar-brand-hover-color: var(--ar-gray-800);
  --ar-navbar-nav-link-padding-x: 1rem;
  --ar-navbar-toggler-padding-y: 0.625rem;
  --ar-navbar-toggler-padding-x: 0.25rem;
  --ar-navbar-toggler-font-size: calc(var(--ar-body-font-size) * 1.125);
  --ar-navbar-toggler-icon-bg: initial;
  --ar-navbar-toggler-border-color: transparent;
  --ar-navbar-toggler-border-radius: 0;
  --ar-navbar-toggler-focus-width: 0;
  --ar-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--ar-navbar-padding-y) var(--ar-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--ar-navbar-brand-padding-y);
  padding-bottom: var(--ar-navbar-brand-padding-y);
  margin-right: var(--ar-navbar-brand-margin-end);
  font-size: var(--ar-navbar-brand-font-size);
  color: var(--ar-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--ar-navbar-brand-hover-color);
}

.navbar-nav {
  --ar-nav-link-padding-x: 0;
  --ar-nav-link-padding-y: 0.725rem;
  --ar-nav-link-font-weight: 500;
  --ar-nav-link-color: var(--ar-navbar-color);
  --ar-nav-link-hover-color: var(--ar-navbar-hover-color);
  --ar-nav-link-disabled-color: var(--ar-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--ar-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.725rem;
  padding-bottom: 0.725rem;
  color: var(--ar-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--ar-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--ar-navbar-toggler-padding-y) var(--ar-navbar-toggler-padding-x);
  font-size: var(--ar-navbar-toggler-font-size);
  line-height: 1;
  color: var(--ar-navbar-color);
  background-color: transparent;
  border: var(--ar-border-width) solid var(--ar-navbar-toggler-border-color);
  border-radius: var(--ar-navbar-toggler-border-radius);
  transition: var(--ar-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--ar-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--ar-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--ar-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--ar-navbar-nav-link-padding-x);
    padding-left: var(--ar-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--ar-navbar-nav-link-padding-x);
    padding-left: var(--ar-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--ar-navbar-nav-link-padding-x);
    padding-left: var(--ar-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--ar-navbar-nav-link-padding-x);
    padding-left: var(--ar-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--ar-navbar-nav-link-padding-x);
    padding-left: var(--ar-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--ar-navbar-nav-link-padding-x);
  padding-left: var(--ar-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --ar-navbar-color: rgba(255, 255, 255, 0.9);
  --ar-navbar-hover-color: var(--ar-primary);
  --ar-navbar-disabled-color: rgba(255, 255, 255, 0.5);
  --ar-navbar-active-color: var(--ar-primary);
  --ar-navbar-brand-color: #fff;
  --ar-navbar-brand-hover-color: #fff;
  --ar-navbar-toggler-border-color: transparent;
  --ar-navbar-toggler-icon-bg: initial;
}

.card {
  --ar-card-spacer-y: 2.375rem;
  --ar-card-spacer-x: 2.375rem;
  --ar-card-title-spacer-y: 1rem;
  --ar-card-border-width: var(--ar-border-width);
  --ar-card-border-color: var(--ar-border-color);
  --ar-card-border-radius: var(--ar-border-radius-2xl);
  --ar-card-box-shadow: ;
  --ar-card-inner-border-radius: calc(var(--ar-border-radius-2xl) - (var(--ar-border-width)));
  --ar-card-cap-padding-y: 1.5rem;
  --ar-card-cap-padding-x: 2.375rem;
  --ar-card-cap-bg: transparent;
  --ar-card-cap-color: ;
  --ar-card-height: ;
  --ar-card-color: var(--ar-body-color);
  --ar-card-bg: #fff;
  --ar-card-img-overlay-padding: 1rem;
  --ar-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--ar-card-height);
  word-wrap: break-word;
  background-color: var(--ar-card-bg);
  background-clip: border-box;
  border: var(--ar-card-border-width) solid var(--ar-card-border-color);
  border-radius: var(--ar-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--ar-card-inner-border-radius);
  border-top-right-radius: var(--ar-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--ar-card-inner-border-radius);
  border-bottom-left-radius: var(--ar-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--ar-card-spacer-y) var(--ar-card-spacer-x);
  color: var(--ar-card-color);
}

.card-title {
  margin-bottom: var(--ar-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--ar-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--ar-card-spacer-x);
}

.card-header {
  padding: var(--ar-card-cap-padding-y) var(--ar-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--ar-card-cap-color);
  background-color: var(--ar-card-cap-bg);
  border-bottom: var(--ar-card-border-width) solid var(--ar-card-border-color);
}
.card-header:first-child {
  border-radius: var(--ar-card-inner-border-radius) var(--ar-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--ar-card-cap-padding-y) var(--ar-card-cap-padding-x);
  color: var(--ar-card-cap-color);
  background-color: var(--ar-card-cap-bg);
  border-top: var(--ar-card-border-width) solid var(--ar-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--ar-card-inner-border-radius) var(--ar-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--ar-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--ar-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--ar-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--ar-card-bg);
  border-bottom-color: var(--ar-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--ar-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--ar-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--ar-card-img-overlay-padding);
  border-radius: var(--ar-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--ar-card-inner-border-radius);
  border-top-right-radius: var(--ar-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--ar-card-inner-border-radius);
  border-bottom-left-radius: var(--ar-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--ar-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --ar-accordion-color: #576071;
  --ar-accordion-bg: #f6f9fc;
  --ar-accordion-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border-radius 0.15s ease;
  --ar-accordion-border-color: var(--ar-border-color);
  --ar-accordion-border-width: 0;
  --ar-accordion-border-radius: var(--ar-border-radius-lg);
  --ar-accordion-inner-border-radius: calc(var(--ar-border-radius-lg) - 0);
  --ar-accordion-btn-padding-x: 1.5rem;
  --ar-accordion-btn-padding-y: 1.5rem;
  --ar-accordion-btn-color: #434a57;
  --ar-accordion-btn-bg: transparent;
  --ar-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23434a57'%3e%3cpath d='M.5 6.3c.6-.6 1.6-.6 2.3 0l9.3 9.3 9.3-9.3c.6-.6 1.6-.6 2.3 0 .6.6.6 1.6 0 2.3L13.3 18.8c-.6.6-1.6.6-2.3 0L.8 8.5c-.9-.8-.9-1.6-.3-2.2z'/%3e%3c/svg%3e");
  --ar-accordion-btn-icon-width: calc(var(--ar-body-font-size) * 0.75);
  --ar-accordion-btn-icon-transform: rotate(-180deg);
  --ar-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --ar-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='var%28--ar-primary%29'%3e%3cpath d='M.5 6.3c.6-.6 1.6-.6 2.3 0l9.3 9.3 9.3-9.3c.6-.6 1.6-.6 2.3 0 .6.6.6 1.6 0 2.3L13.3 18.8c-.6.6-1.6.6-2.3 0L.8 8.5c-.9-.8-.9-1.6-.3-2.2z'/%3e%3c/svg%3e");
  --ar-accordion-btn-focus-border-color: var(--ar-gray-700);
  --ar-accordion-btn-focus-box-shadow: unset;
  --ar-accordion-body-padding-x: 1.5rem;
  --ar-accordion-body-padding-y: 1.5rem;
  --ar-accordion-active-color: var(--ar-primary);
  --ar-accordion-active-bg: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--ar-accordion-btn-padding-y) var(--ar-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--ar-accordion-btn-color);
  text-align: left;
  background-color: var(--ar-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--ar-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--ar-accordion-active-color);
  background-color: var(--ar-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--ar-accordion-border-width)) 0 var(--ar-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--ar-accordion-btn-active-icon);
  transform: var(--ar-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--ar-accordion-btn-icon-width);
  height: var(--ar-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--ar-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--ar-accordion-btn-icon-width);
  transition: var(--ar-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--ar-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--ar-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--ar-accordion-color);
  background-color: var(--ar-accordion-bg);
  border: var(--ar-accordion-border-width) solid var(--ar-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--ar-accordion-border-radius);
  border-top-right-radius: var(--ar-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--ar-accordion-inner-border-radius);
  border-top-right-radius: var(--ar-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--ar-accordion-border-radius);
  border-bottom-left-radius: var(--ar-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--ar-accordion-inner-border-radius);
  border-bottom-left-radius: var(--ar-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--ar-accordion-border-radius);
  border-bottom-left-radius: var(--ar-accordion-border-radius);
}

.accordion-body {
  padding: var(--ar-accordion-body-padding-y) var(--ar-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --ar-breadcrumb-padding-x: 0;
  --ar-breadcrumb-padding-y: 0;
  --ar-breadcrumb-margin-bottom: 1rem;
  --ar-breadcrumb-font-size: calc(var(--ar-body-font-size) * 0.875);
  --ar-breadcrumb-bg: ;
  --ar-breadcrumb-border-radius: ;
  --ar-breadcrumb-divider-color: var(--ar-gray-600);
  --ar-breadcrumb-item-padding-x: 0.5rem;
  --ar-breadcrumb-item-active-color: var(--ar-primary);
  display: flex;
  flex-wrap: wrap;
  padding: var(--ar-breadcrumb-padding-y) var(--ar-breadcrumb-padding-x);
  margin-bottom: var(--ar-breadcrumb-margin-bottom);
  font-size: var(--ar-breadcrumb-font-size);
  list-style: none;
  background-color: var(--ar-breadcrumb-bg);
  border-radius: var(--ar-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--ar-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--ar-breadcrumb-item-padding-x);
  color: var(--ar-breadcrumb-divider-color);
  content: var(--ar-breadcrumb-divider, "\e939") /* rtl: var(--ar-breadcrumb-divider, "\e938") */;
}
.breadcrumb-item.active {
  color: var(--ar-breadcrumb-item-active-color);
}

.pagination {
  --ar-pagination-padding-x: 0.75rem;
  --ar-pagination-padding-y: 0.25rem;
  --ar-pagination-font-size: 1rem;
  --ar-pagination-color: var(--ar-gray-600);
  --ar-pagination-bg: transparent;
  --ar-pagination-border-width: 0;
  --ar-pagination-border-color: #e3e9ef;
  --ar-pagination-border-radius: 1rem;
  --ar-pagination-hover-color: var(--ar-gray-800);
  --ar-pagination-hover-bg: transparent;
  --ar-pagination-hover-border-color: #e3e9ef;
  --ar-pagination-focus-color: var(--ar-gray-800);
  --ar-pagination-focus-bg: transparent;
  --ar-pagination-focus-box-shadow: 0 0 0 0 rgba(68, 140, 116, 0.25);
  --ar-pagination-active-color: var(--ar-gray-900);
  --ar-pagination-active-bg: transparent;
  --ar-pagination-active-border-color: transparent;
  --ar-pagination-disabled-color: var(--ar-gray-500);
  --ar-pagination-disabled-bg: transparent;
  --ar-pagination-disabled-border-color: #e3e9ef;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--ar-pagination-padding-y) var(--ar-pagination-padding-x);
  font-size: var(--ar-pagination-font-size);
  color: var(--ar-pagination-color);
  text-decoration: none;
  background-color: var(--ar-pagination-bg);
  border: var(--ar-pagination-border-width) solid var(--ar-pagination-border-color);
  transition: color 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--ar-pagination-hover-color);
  background-color: var(--ar-pagination-hover-bg);
  border-color: var(--ar-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--ar-pagination-focus-color);
  background-color: var(--ar-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--ar-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--ar-pagination-active-color);
  background-color: var(--ar-pagination-active-bg);
  border-color: var(--ar-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--ar-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--ar-pagination-disabled-bg);
  border-color: var(--ar-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--ar-pagination-border-radius);
  border-bottom-left-radius: var(--ar-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--ar-pagination-border-radius);
  border-bottom-right-radius: var(--ar-pagination-border-radius);
}

.pagination-lg {
  --ar-pagination-padding-x: 0.875rem;
  --ar-pagination-padding-y: 0.25rem;
  --ar-pagination-font-size: calc(var(--ar-body-font-size) * 1.125);
  --ar-pagination-border-radius: calc(var(--ar-border-radius) * 1.125);
}

.pagination-sm {
  --ar-pagination-padding-x: 0.65rem;
  --ar-pagination-padding-y: 0.25rem;
  --ar-pagination-font-size: calc(var(--ar-body-font-size) * 0.875);
  --ar-pagination-border-radius: calc(var(--ar-border-radius) * 0.75);
}

.badge {
  --ar-badge-padding-x: 0.75em;
  --ar-badge-padding-y: 0.5em;
  --ar-badge-font-size: 0.75em;
  --ar-badge-font-weight: 600;
  --ar-badge-color: #fff;
  --ar-badge-border-radius: 50rem;
  display: inline-block;
  padding: var(--ar-badge-padding-y) var(--ar-badge-padding-x);
  font-size: var(--ar-badge-font-size);
  font-weight: var(--ar-badge-font-weight);
  line-height: 1;
  color: var(--ar-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--ar-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --ar-alert-bg: transparent;
  --ar-alert-padding-x: 1rem;
  --ar-alert-padding-y: 1rem;
  --ar-alert-margin-bottom: 1rem;
  --ar-alert-color: inherit;
  --ar-alert-border-color: transparent;
  --ar-alert-border: var(--ar-border-width) solid var(--ar-alert-border-color);
  --ar-alert-border-radius: var(--ar-border-radius-lg);
  position: relative;
  padding: var(--ar-alert-padding-y) var(--ar-alert-padding-x);
  margin-bottom: var(--ar-alert-margin-bottom);
  color: var(--ar-alert-color);
  background-color: var(--ar-alert-bg);
  border: var(--ar-alert-border);
  border-radius: var(--ar-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --ar-alert-color: #3d7e68;
  --ar-alert-bg: #ecf4f1;
  --ar-alert-border-color: #a2c6ba;
}
.alert-primary .alert-link {
  color: #316553;
}

.alert-secondary {
  --ar-alert-color: #d5d9dd;
  --ar-alert-bg: #fdfefe;
  --ar-alert-border-color: #f6f8fa;
}
.alert-secondary .alert-link {
  color: #aaaeb1;
}

.alert-success {
  --ar-alert-color: #39b682;
  --ar-alert-bg: #ecfaf4;
  --ar-alert-border-color: #9fe5c8;
}
.alert-success .alert-link {
  color: #2e9268;
}

.alert-info {
  --ar-alert-color: #3972b6;
  --ar-alert-bg: #ecf2fa;
  --ar-alert-border-color: #9fbfe5;
}
.alert-info .alert-link {
  color: #2e5b92;
}

.alert-warning {
  --ar-alert-color: #d5b748;
  --ar-alert-bg: #fdfaee;
  --ar-alert-border-color: #f6e5a8;
}
.alert-warning .alert-link {
  color: #aa923a;
}

.alert-danger {
  --ar-alert-color: #d54848;
  --ar-alert-bg: #fdeeee;
  --ar-alert-border-color: #f6a8a8;
}
.alert-danger .alert-link {
  color: #aa3a3a;
}

.alert-light {
  --ar-alert-color: #e6e6e6;
  --ar-alert-bg: white;
  --ar-alert-border-color: white;
}
.alert-light .alert-link {
  color: #b8b8b8;
}

.alert-dark {
  --ar-alert-color: #101317;
  --ar-alert-bg: #e7e8e8;
  --ar-alert-border-color: #898a8c;
}
.alert-dark .alert-link {
  color: #0d0f12;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --ar-progress-height: 1rem;
  --ar-progress-font-size: 0.75rem;
  --ar-progress-bg: #e9eef3;
  --ar-progress-border-radius: 50rem;
  --ar-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --ar-progress-bar-color: #fff;
  --ar-progress-bar-bg: #448c74;
  --ar-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--ar-progress-height);
  overflow: hidden;
  font-size: var(--ar-progress-font-size);
  background-color: var(--ar-progress-bg);
  border-radius: var(--ar-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--ar-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--ar-progress-bar-bg);
  transition: var(--ar-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--ar-progress-height) var(--ar-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --ar-list-group-color: var(--ar-body-color);
  --ar-list-group-bg: transparent;
  --ar-list-group-border-color: var(--ar-border-color);
  --ar-list-group-border-width: var(--ar-border-width);
  --ar-list-group-border-radius: var(--ar-border-radius-xl);
  --ar-list-group-item-padding-x: 1.25rem;
  --ar-list-group-item-padding-y: 0.875rem;
  --ar-list-group-action-color: var(--ar-gray-800);
  --ar-list-group-action-hover-color: var(--ar-primary);
  --ar-list-group-action-hover-bg: transparent;
  --ar-list-group-action-active-color: var(--ar-primary);
  --ar-list-group-action-active-bg: transparent;
  --ar-list-group-disabled-color: var(--ar-gray-600);
  --ar-list-group-disabled-bg: transparent;
  --ar-list-group-active-color: #fff;
  --ar-list-group-active-bg: var(--ar-primary);
  --ar-list-group-active-border-color: var(--ar-primary);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--ar-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--ar-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--ar-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--ar-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--ar-list-group-action-active-color);
  background-color: var(--ar-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--ar-list-group-item-padding-y) var(--ar-list-group-item-padding-x);
  color: var(--ar-list-group-color);
  text-decoration: none;
  background-color: var(--ar-list-group-bg);
  border: var(--ar-list-group-border-width) solid var(--ar-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--ar-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--ar-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--ar-list-group-active-color);
  background-color: var(--ar-list-group-active-bg);
  border-color: var(--ar-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--ar-list-group-border-width));
  border-top-width: var(--ar-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--ar-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--ar-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--ar-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--ar-list-group-border-width));
  border-left-width: var(--ar-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ar-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ar-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--ar-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ar-list-group-border-width));
    border-left-width: var(--ar-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ar-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ar-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--ar-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ar-list-group-border-width));
    border-left-width: var(--ar-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ar-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ar-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--ar-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ar-list-group-border-width));
    border-left-width: var(--ar-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ar-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ar-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--ar-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ar-list-group-border-width));
    border-left-width: var(--ar-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ar-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ar-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--ar-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ar-list-group-border-width));
    border-left-width: var(--ar-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--ar-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #295446;
  background-color: #e7f0ed;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #295446;
  background-color: #d0d8d5;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #295446;
  border-color: #295446;
}

.list-group-item-secondary {
  color: #5f6062;
  background-color: #fdfdfe;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #5f6062;
  background-color: #e4e4e5;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #5f6062;
  border-color: #5f6062;
}

.list-group-item-success {
  color: #267956;
  background-color: #e6f8f1;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #267956;
  background-color: #cfdfd9;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #267956;
  border-color: #267956;
}

.list-group-item-info {
  color: #264c79;
  background-color: #e6eef8;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #264c79;
  background-color: #cfd6df;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #264c79;
  border-color: #264c79;
}

.list-group-item-warning {
  color: #5f5120;
  background-color: #fdf8e8;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #5f5120;
  background-color: #e4dfd1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #5f5120;
  border-color: #5f5120;
}

.list-group-item-danger {
  color: #8e3030;
  background-color: #fde8e8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #8e3030;
  background-color: #e4d1d1;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #8e3030;
  border-color: #8e3030;
}

.list-group-item-light {
  color: #666666;
  background-color: white;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-dark {
  color: #0b0d0f;
  background-color: #e0e1e1;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0b0d0f;
  background-color: #cacbcb;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0b0d0f;
  border-color: #0b0d0f;
}

.btn-close {
  box-sizing: content-box;
  width: 1.375em;
  height: 1.375em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath d='M8 1.2A6.74 6.74 0 0 0 1.2 8 6.74 6.74 0 0 0 8 14.8 6.74 6.74 0 0 0 14.8 8 6.74 6.74 0 0 0 8 1.2zM0 8c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm10.6-2.6a.61.61 0 0 1 0 .8L8.8 8l1.9 1.9a.61.61 0 0 1 0 .8.61.61 0 0 1-.8 0L8 8.8l-1.9 1.9a.61.61 0 0 1-.8 0 .61.61 0 0 1 0-.8L7.2 8 5.4 6.1a.61.61 0 0 1 0-.8.61.61 0 0 1 .8 0l1.9 1.9L10 5.3c.1-.1.4-.1.6.1z' fill-rule='evenodd' fill='%23000'/%3e%3c/svg%3e") center/1.375em auto no-repeat;
  border: 0;
  border-radius: 1rem;
  opacity: 0.55;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.85;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 0.85;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.3;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --ar-toast-zindex: 1090;
  --ar-toast-padding-x: 1.25rem;
  --ar-toast-padding-y: 0.875rem;
  --ar-toast-spacing: 1.5rem;
  --ar-toast-max-width: 350px;
  --ar-toast-font-size: 0.875rem;
  --ar-toast-color: ;
  --ar-toast-bg: #fff;
  --ar-toast-border-width: var(--ar-border-width);
  --ar-toast-border-color: #f0f3f6;
  --ar-toast-border-radius: var(--ar-border-radius-lg);
  --ar-toast-box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32);
  --ar-toast-header-color: var(--ar-gray-900);
  --ar-toast-header-bg: transparent;
  --ar-toast-header-border-color: var(--ar-border-color);
  width: var(--ar-toast-max-width);
  max-width: 100%;
  font-size: var(--ar-toast-font-size);
  color: var(--ar-toast-color);
  pointer-events: auto;
  background-color: var(--ar-toast-bg);
  background-clip: padding-box;
  border: var(--ar-toast-border-width) solid var(--ar-toast-border-color);
  box-shadow: var(--ar-toast-box-shadow);
  border-radius: var(--ar-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --ar-toast-zindex: 1090;
  position: absolute;
  z-index: var(--ar-toast-zindex);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--ar-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--ar-toast-padding-y) var(--ar-toast-padding-x);
  color: var(--ar-toast-header-color);
  background-color: var(--ar-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--ar-toast-border-width) solid var(--ar-toast-header-border-color);
  border-top-left-radius: calc(var(--ar-toast-border-radius) - var(--ar-toast-border-width));
  border-top-right-radius: calc(var(--ar-toast-border-radius) - var(--ar-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--ar-toast-padding-x));
  margin-left: var(--ar-toast-padding-x);
}

.toast-body {
  padding: var(--ar-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --ar-modal-zindex: 1055;
  --ar-modal-width: 500px;
  --ar-modal-padding: 2.375rem;
  --ar-modal-margin: 0.5rem;
  --ar-modal-color: ;
  --ar-modal-bg: #fff;
  --ar-modal-border-color: var(--ar-border-color);
  --ar-modal-border-width: var(--ar-border-width);
  --ar-modal-border-radius: var(--ar-border-radius-2xl);
  --ar-modal-box-shadow: none;
  --ar-modal-inner-border-radius: calc(var(--ar-border-radius-2xl) - (var(--ar-border-width)));
  --ar-modal-header-padding-x: 2.375rem;
  --ar-modal-header-padding-y: 1.78125rem;
  --ar-modal-header-padding: 1.78125rem 2.375rem;
  --ar-modal-header-border-color: var(--ar-border-color);
  --ar-modal-header-border-width: var(--ar-border-width);
  --ar-modal-title-line-height: 1.5;
  --ar-modal-footer-gap: 0;
  --ar-modal-footer-bg: ;
  --ar-modal-footer-border-color: var(--ar-border-color);
  --ar-modal-footer-border-width: var(--ar-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--ar-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--ar-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.2s ease-out;
  transform: scale(0.9);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--ar-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--ar-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--ar-modal-color);
  pointer-events: auto;
  background-color: var(--ar-modal-bg);
  background-clip: padding-box;
  border: var(--ar-modal-border-width) solid var(--ar-modal-border-color);
  border-radius: var(--ar-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --ar-backdrop-zindex: 1050;
  --ar-backdrop-bg: #000;
  --ar-backdrop-opacity: 0.65;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--ar-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--ar-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--ar-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--ar-modal-header-padding);
  border-bottom: var(--ar-modal-header-border-width) solid var(--ar-modal-header-border-color);
  border-top-left-radius: var(--ar-modal-inner-border-radius);
  border-top-right-radius: var(--ar-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--ar-modal-header-padding-y) * 0.5) calc(var(--ar-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--ar-modal-header-padding-y)) calc(-0.5 * var(--ar-modal-header-padding-x)) calc(-0.5 * var(--ar-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--ar-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--ar-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--ar-modal-padding) - var(--ar-modal-footer-gap) * 0.5);
  background-color: var(--ar-modal-footer-bg);
  border-top: var(--ar-modal-footer-border-width) solid var(--ar-modal-footer-border-color);
  border-bottom-right-radius: var(--ar-modal-inner-border-radius);
  border-bottom-left-radius: var(--ar-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--ar-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --ar-modal-margin: 1.75rem;
    --ar-modal-box-shadow: none;
  }
  .modal-dialog {
    max-width: var(--ar-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --ar-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --ar-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --ar-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --ar-tooltip-zindex: 1080;
  --ar-tooltip-max-width: 200px;
  --ar-tooltip-padding-x: 0.75rem;
  --ar-tooltip-padding-y: 0.375rem;
  --ar-tooltip-margin: ;
  --ar-tooltip-font-size: calc(var(--ar-body-font-size) * 0.875);
  --ar-tooltip-color: #fff;
  --ar-tooltip-bg: #000;
  --ar-tooltip-border-radius: var(--ar-border-radius-sm);
  --ar-tooltip-opacity: 0.9;
  --ar-tooltip-arrow-width: 0.8rem;
  --ar-tooltip-arrow-height: 0.4rem;
  z-index: var(--ar-tooltip-zindex);
  display: block;
  padding: var(--ar-tooltip-arrow-height);
  margin: var(--ar-tooltip-margin);
  font-family: var(--ar-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--ar-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--ar-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--ar-tooltip-arrow-width);
  height: var(--ar-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--ar-tooltip-arrow-height) calc(var(--ar-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--ar-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--ar-tooltip-arrow-height);
  height: var(--ar-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--ar-tooltip-arrow-width) * 0.5) var(--ar-tooltip-arrow-height) calc(var(--ar-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--ar-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--ar-tooltip-arrow-width) * 0.5) var(--ar-tooltip-arrow-height);
  border-bottom-color: var(--ar-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--ar-tooltip-arrow-height);
  height: var(--ar-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--ar-tooltip-arrow-width) * 0.5) 0 calc(var(--ar-tooltip-arrow-width) * 0.5) var(--ar-tooltip-arrow-height);
  border-left-color: var(--ar-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--ar-tooltip-max-width);
  padding: var(--ar-tooltip-padding-y) var(--ar-tooltip-padding-x);
  color: var(--ar-tooltip-color);
  text-align: center;
  background-color: var(--ar-tooltip-bg);
  border-radius: var(--ar-tooltip-border-radius);
}

.popover {
  --ar-popover-zindex: 1070;
  --ar-popover-max-width: 276px;
  --ar-popover-font-size: calc(var(--ar-body-font-size) * 0.875);
  --ar-popover-bg: #fff;
  --ar-popover-border-width: var(--ar-border-width);
  --ar-popover-border-color: #f0f3f6;
  --ar-popover-border-radius: var(--ar-border-radius-lg);
  --ar-popover-inner-border-radius: calc(var(--ar-border-radius-lg) - (var(--ar-border-width)));
  --ar-popover-box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32);
  --ar-popover-header-padding-x: 1.25rem;
  --ar-popover-header-padding-y: 0.875rem;
  --ar-popover-header-font-size: 1rem;
  --ar-popover-header-color: var(--ar-gray-800);
  --ar-popover-header-bg: transparent;
  --ar-popover-body-padding-x: 1.25rem;
  --ar-popover-body-padding-y: 1rem;
  --ar-popover-body-color: var(--ar-body-color);
  --ar-popover-arrow-width: 1rem;
  --ar-popover-arrow-height: 0.5rem;
  --ar-popover-arrow-border: var(--ar-popover-border-color);
  z-index: var(--ar-popover-zindex);
  display: block;
  max-width: var(--ar-popover-max-width);
  font-family: var(--ar-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--ar-popover-font-size);
  word-wrap: break-word;
  background-color: var(--ar-popover-bg);
  background-clip: padding-box;
  border: var(--ar-popover-border-width) solid var(--ar-popover-border-color);
  border-radius: var(--ar-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--ar-popover-arrow-width);
  height: var(--ar-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--ar-popover-arrow-height)) - var(--ar-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--ar-popover-arrow-height) calc(var(--ar-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--ar-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--ar-popover-border-width);
  border-top-color: var(--ar-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--ar-popover-arrow-height)) - var(--ar-popover-border-width));
  width: var(--ar-popover-arrow-height);
  height: var(--ar-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--ar-popover-arrow-width) * 0.5) var(--ar-popover-arrow-height) calc(var(--ar-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--ar-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--ar-popover-border-width);
  border-right-color: var(--ar-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--ar-popover-arrow-height)) - var(--ar-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--ar-popover-arrow-width) * 0.5) var(--ar-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--ar-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--ar-popover-border-width);
  border-bottom-color: var(--ar-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--ar-popover-arrow-width);
  margin-left: calc(-0.5 * var(--ar-popover-arrow-width));
  content: "";
  border-bottom: var(--ar-popover-border-width) solid var(--ar-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--ar-popover-arrow-height)) - var(--ar-popover-border-width));
  width: var(--ar-popover-arrow-height);
  height: var(--ar-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--ar-popover-arrow-width) * 0.5) 0 calc(var(--ar-popover-arrow-width) * 0.5) var(--ar-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--ar-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--ar-popover-border-width);
  border-left-color: var(--ar-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--ar-popover-header-padding-y) var(--ar-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--ar-popover-header-font-size);
  color: var(--ar-popover-header-color);
  background-color: var(--ar-popover-header-bg);
  border-bottom: var(--ar-popover-border-width) solid var(--ar-popover-border-color);
  border-top-left-radius: var(--ar-popover-inner-border-radius);
  border-top-right-radius: var(--ar-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--ar-popover-body-padding-y) var(--ar-popover-body-padding-x);
  color: var(--ar-popover-body-color);
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--ar-spinner-width);
  height: var(--ar-spinner-height);
  vertical-align: var(--ar-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--ar-spinner-animation-speed) linear infinite var(--ar-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --ar-spinner-width: 2rem;
  --ar-spinner-height: 2rem;
  --ar-spinner-vertical-align: -0.125em;
  --ar-spinner-border-width: 0.15em;
  --ar-spinner-animation-speed: 0.75s;
  --ar-spinner-animation-name: spinner-border;
  border: var(--ar-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --ar-spinner-width: 1rem;
  --ar-spinner-height: 1rem;
  --ar-spinner-border-width: 0.1em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --ar-spinner-width: 2rem;
  --ar-spinner-height: 2rem;
  --ar-spinner-vertical-align: -0.125em;
  --ar-spinner-animation-speed: 0.75s;
  --ar-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --ar-spinner-width: 1rem;
  --ar-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --ar-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --ar-offcanvas-zindex: 1045;
  --ar-offcanvas-width: 350px;
  --ar-offcanvas-height: 30vh;
  --ar-offcanvas-padding-x: 1.5rem;
  --ar-offcanvas-padding-y: 1.5rem;
  --ar-offcanvas-color: ;
  --ar-offcanvas-bg: #fff;
  --ar-offcanvas-border-width: 0;
  --ar-offcanvas-border-color: var(--ar-border-color);
  --ar-offcanvas-box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--ar-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ar-offcanvas-color);
    visibility: hidden;
    background-color: var(--ar-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ar-offcanvas-width);
    border-right: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ar-offcanvas-width);
    border-left: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ar-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ar-offcanvas-height);
    max-height: 100%;
    border-top: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --ar-offcanvas-height: auto;
    --ar-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--ar-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ar-offcanvas-color);
    visibility: hidden;
    background-color: var(--ar-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ar-offcanvas-width);
    border-right: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ar-offcanvas-width);
    border-left: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ar-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ar-offcanvas-height);
    max-height: 100%;
    border-top: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --ar-offcanvas-height: auto;
    --ar-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--ar-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ar-offcanvas-color);
    visibility: hidden;
    background-color: var(--ar-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ar-offcanvas-width);
    border-right: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ar-offcanvas-width);
    border-left: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ar-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ar-offcanvas-height);
    max-height: 100%;
    border-top: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --ar-offcanvas-height: auto;
    --ar-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--ar-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ar-offcanvas-color);
    visibility: hidden;
    background-color: var(--ar-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ar-offcanvas-width);
    border-right: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ar-offcanvas-width);
    border-left: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ar-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ar-offcanvas-height);
    max-height: 100%;
    border-top: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --ar-offcanvas-height: auto;
    --ar-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--ar-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ar-offcanvas-color);
    visibility: hidden;
    background-color: var(--ar-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ar-offcanvas-width);
    border-right: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ar-offcanvas-width);
    border-left: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ar-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ar-offcanvas-height);
    max-height: 100%;
    border-top: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --ar-offcanvas-height: auto;
    --ar-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--ar-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--ar-offcanvas-color);
  visibility: hidden;
  background-color: var(--ar-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--ar-offcanvas-width);
  border-right: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--ar-offcanvas-width);
  border-left: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--ar-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--ar-offcanvas-height);
  max-height: 100%;
  border-top: var(--ar-offcanvas-border-width) solid var(--ar-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.65;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--ar-offcanvas-padding-y) var(--ar-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--ar-offcanvas-padding-y) * 0.5) calc(var(--ar-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--ar-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--ar-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--ar-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--ar-offcanvas-padding-y) var(--ar-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.3;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.12;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.88) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.88) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(68, 140, 116, var(--ar-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(237, 241, 245, var(--ar-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(63, 202, 144, var(--ar-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(63, 127, 202, var(--ar-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(237, 203, 80, var(--ar-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(237, 80, 80, var(--ar-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--ar-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(18, 21, 25, var(--ar-bg-opacity, 1)) !important;
}

.link-primary {
  color: var(--ar-link-color) !important;
}
.link-primary:hover, .link-primary:focus {
  color: var(--ar-link-hover-color) !important;
}

.link-secondary {
  color: var(--ar-secondary) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: var(--ar-link-secondary-hover-color) !important;
}

.link-success {
  color: #3fca90 !important;
}
.link-success:hover, .link-success:focus {
  color: #65d5a6 !important;
}

.link-info {
  color: #3f7fca !important;
}
.link-info:hover, .link-info:focus {
  color: #6599d5 !important;
}

.link-warning {
  color: #edcb50 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #f1d573 !important;
}

.link-danger {
  color: #ed5050 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #f17373 !important;
}

.link-light {
  color: #fff !important;
}
.link-light:hover, .link-light:focus {
  color: white !important;
}

.link-dark {
  color: #121519 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #0e1114 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--ar-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --ar-aspect-ratio: 100%;
}

.ratio-4x3 {
  --ar-aspect-ratio: 75%;
}

.ratio-16x9 {
  --ar-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --ar-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32) !important;
}

.shadow-sm {
  box-shadow: 0 0.375rem 0.75rem -0.125rem rgba(208, 208, 196, 0.24) !important;
}

.shadow-lg {
  box-shadow: 0 0.5rem 1.75rem -0.125rem rgba(208, 208, 196, 0.48) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
}

.border-dashed {
  border: var(--ar-border-width) dashed #d6dfe8 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --ar-border-opacity: 1;
  border-color: rgba(var(--ar-primary-rgb), var(--ar-border-opacity)) !important;
}

.border-secondary {
  --ar-border-opacity: 1;
  border-color: rgba(var(--ar-secondary-rgb), var(--ar-border-opacity)) !important;
}

.border-success {
  --ar-border-opacity: 1;
  border-color: rgba(var(--ar-success-rgb), var(--ar-border-opacity)) !important;
}

.border-info {
  --ar-border-opacity: 1;
  border-color: rgba(var(--ar-info-rgb), var(--ar-border-opacity)) !important;
}

.border-warning {
  --ar-border-opacity: 1;
  border-color: rgba(var(--ar-warning-rgb), var(--ar-border-opacity)) !important;
}

.border-danger {
  --ar-border-opacity: 1;
  border-color: rgba(var(--ar-danger-rgb), var(--ar-border-opacity)) !important;
}

.border-light {
  --ar-border-opacity: 1;
  border-color: rgba(255, 255, 255, 0.13) !important;
}

.border-dark {
  --ar-border-opacity: 1;
  border-color: rgba(var(--ar-dark-rgb), var(--ar-border-opacity)) !important;
}

.border-white {
  --ar-border-opacity: 1;
  border-color: rgba(var(--ar-white-rgb), var(--ar-border-opacity)) !important;
}

.border-input {
  --ar-border-opacity: 1;
  border-color: var(--ar-gray-400) !important;
}

.border-1 {
  --ar-border-width: 1px;
}

.border-2 {
  --ar-border-width: 2px;
}

.border-3 {
  --ar-border-width: 3px;
}

.border-4 {
  --ar-border-width: 4px;
}

.border-5 {
  --ar-border-width: 5px;
}

.border-opacity-10 {
  --ar-border-opacity: 0.1;
}

.border-opacity-25 {
  --ar-border-opacity: 0.25;
}

.border-opacity-50 {
  --ar-border-opacity: 0.5;
}

.border-opacity-75 {
  --ar-border-opacity: 0.75;
}

.border-opacity-100 {
  --ar-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--ar-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-xl {
  font-size: calc(var(--ar-body-font-size) * 1.25) !important;
}

.fs-lg {
  font-size: calc(var(--ar-body-font-size) * 1.125) !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-sm {
  font-size: calc(var(--ar-body-font-size) * 0.875) !important;
}

.fs-xs {
  font-size: calc(var(--ar-body-font-size) * 0.75) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 800 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.4 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.6 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-primary-rgb), var(--ar-text-opacity)) !important;
}

.text-secondary {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-secondary-rgb), var(--ar-text-opacity)) !important;
}

.text-success {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-success-rgb), var(--ar-text-opacity)) !important;
}

.text-info {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-info-rgb), var(--ar-text-opacity)) !important;
}

.text-warning {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-warning-rgb), var(--ar-text-opacity)) !important;
}

.text-danger {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-danger-rgb), var(--ar-text-opacity)) !important;
}

.text-light {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-light-rgb), var(--ar-text-opacity)) !important;
}

.text-dark {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-dark-rgb), var(--ar-text-opacity)) !important;
}

.text-black {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-black-rgb), var(--ar-text-opacity)) !important;
}

.text-white {
  --ar-text-opacity: 1;
  color: #fff !important;
}

.text-body {
  --ar-text-opacity: 1;
  color: var(--ar-body-color) !important;
}

.text-muted {
  --ar-text-opacity: 1;
  color: var(--ar-gray-600) !important;
}

.text-nav {
  --ar-text-opacity: 1;
  color: var(--ar-nav-link-color) !important;
}

.text-border {
  --ar-text-opacity: 1;
  color: var(--ar-border-color) !important;
}

.text-reset {
  --ar-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --ar-text-opacity: 0.25;
}

.text-opacity-50 {
  --ar-text-opacity: 0.5;
}

.text-opacity-75 {
  --ar-text-opacity: 0.75;
}

.text-opacity-100 {
  --ar-text-opacity: 1;
}

.bg-primary {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-primary-rgb), var(--ar-bg-opacity)) !important;
}

.bg-secondary {
  --ar-bg-opacity: 1;
  background-color: var(--ar-gray-100) !important;
}

.bg-success {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-success-rgb), var(--ar-bg-opacity)) !important;
}

.bg-info {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-info-rgb), var(--ar-bg-opacity)) !important;
}

.bg-warning {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-warning-rgb), var(--ar-bg-opacity)) !important;
}

.bg-danger {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-danger-rgb), var(--ar-bg-opacity)) !important;
}

.bg-light {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-light-rgb), var(--ar-bg-opacity)) !important;
}

.bg-dark {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-dark-rgb), var(--ar-bg-opacity)) !important;
}

.bg-black {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-black-rgb), var(--ar-bg-opacity)) !important;
}

.bg-white {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-white-rgb), var(--ar-bg-opacity)) !important;
}

.bg-body {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-body-bg-rgb), var(--ar-bg-opacity)) !important;
}

.bg-transparent {
  --ar-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-gray {
  --ar-bg-opacity: 1;
  background-color: var(--ar-gray-200) !important;
}

.bg-opacity-10 {
  --ar-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --ar-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --ar-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --ar-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --ar-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--ar-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--ar-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--ar-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--ar-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--ar-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--ar-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--ar-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--ar-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--ar-border-radius) !important;
  border-top-right-radius: var(--ar-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-end {
  border-top-right-radius: var(--ar-border-radius) !important;
  border-bottom-right-radius: var(--ar-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--ar-border-radius) !important;
  border-bottom-left-radius: var(--ar-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start {
  border-bottom-left-radius: var(--ar-border-radius) !important;
  border-top-left-radius: var(--ar-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.bg-faded-primary {
  --ar-bg-opacity: 0.1;
  background-color: rgba(var(--ar-primary-rgb), var(--ar-bg-opacity)) !important;
}

.bg-faded-secondary {
  --ar-bg-opacity: 0.1;
  background-color: rgba(var(--ar-secondary-rgb), var(--ar-bg-opacity)) !important;
}

.bg-faded-success {
  --ar-bg-opacity: 0.1;
  background-color: rgba(var(--ar-success-rgb), var(--ar-bg-opacity)) !important;
}

.bg-faded-info {
  --ar-bg-opacity: 0.1;
  background-color: rgba(var(--ar-info-rgb), var(--ar-bg-opacity)) !important;
}

.bg-faded-warning {
  --ar-bg-opacity: 0.1;
  background-color: rgba(var(--ar-warning-rgb), var(--ar-bg-opacity)) !important;
}

.bg-faded-danger {
  --ar-bg-opacity: 0.1;
  background-color: rgba(var(--ar-danger-rgb), var(--ar-bg-opacity)) !important;
}

.bg-faded-light {
  --ar-bg-opacity: 0.1;
  background-color: rgba(var(--ar-light-rgb), var(--ar-bg-opacity)) !important;
}

.bg-faded-dark {
  --ar-bg-opacity: 0.1;
  background-color: rgba(var(--ar-dark-rgb), var(--ar-bg-opacity)) !important;
}

.bg-size-cover {
  background-size: cover !important;
}

.bg-size-contain {
  background-size: contain !important;
}

.bg-position-center {
  background-position: center !important;
}

.bg-position-center-y {
  background-position: left center !important;
}

.bg-position-center-x {
  background-position: center top !important;
}

.bg-position-top-end {
  background-position: top right !important;
}

.bg-position-top-start {
  background-position: top left !important;
}

.bg-position-top-center {
  background-position: top center !important;
}

.bg-position-center-end {
  background-position: center right !important;
}

.bg-position-center-start {
  background-position: center left !important;
}

.bg-position-center-bottom {
  background-position: center bottom !important;
}

.bg-position-bottom-end {
  background-position: bottom right !important;
}

.bg-position-bottom-start {
  background-position: bottom left !important;
}

.bg-position-bottom-center {
  background-position: bottom center !important;
}

.bg-repeat-0 {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-attachment-fixed {
  background-attachment: fixed !important;
}

.bg-image-none {
  background-image: none !important;
}

.table-layout-fixed {
  table-layout: fixed !important;
}

.zindex-1 {
  z-index: 1 !important;
}

.zindex-2 {
  z-index: 2 !important;
}

.zindex-3 {
  z-index: 3 !important;
}

.zindex-4 {
  z-index: 4 !important;
}

.zindex-5 {
  z-index: 5 !important;
}

.zindex-10 {
  z-index: 10 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .border-sm {
    border: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-sm-dashed {
    border: var(--ar-border-width) dashed #d6dfe8 !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-top-sm {
    border-top: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-end-sm {
    border-right: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-end-sm-0 {
    border-right: 0 !important;
  }
  .border-bottom-sm {
    border-bottom: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-start-sm {
    border-left: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-start-sm-0 {
    border-left: 0 !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .border-md {
    border: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-md-dashed {
    border: var(--ar-border-width) dashed #d6dfe8 !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-top-md {
    border-top: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-end-md {
    border-right: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-end-md-0 {
    border-right: 0 !important;
  }
  .border-bottom-md {
    border-bottom: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-start-md {
    border-left: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-start-md-0 {
    border-left: 0 !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .border-lg {
    border: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-lg-dashed {
    border: var(--ar-border-width) dashed #d6dfe8 !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-top-lg {
    border-top: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-end-lg {
    border-right: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-end-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-start-lg {
    border-left: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-start-lg-0 {
    border-left: 0 !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .border-xl {
    border: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-xl-dashed {
    border: var(--ar-border-width) dashed #d6dfe8 !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-top-xl {
    border-top: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-end-xl {
    border-right: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-end-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xl {
    border-left: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-start-xl-0 {
    border-left: 0 !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .border-xxl {
    border: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-xxl-dashed {
    border: var(--ar-border-width) dashed #d6dfe8 !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-top-xxl {
    border-top: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-end-xxl {
    border-right: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-end-xxl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xxl {
    border-bottom: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xxl {
    border-left: var(--ar-border-width) var(--ar-border-style) var(--ar-border-color) !important;
  }
  .border-start-xxl-0 {
    border-left: 0 !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.page-wrapper {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

:root {
  --ar-user-selection-color: rgba(var(--ar-primary-rgb), 0.22);
  --ar-heading-color: var(--ar-gray-900);
  --ar-heading-font-weight: 600;
  --ar-heading-link-color: var(--ar-gray-800);
  --ar-heading-link-hover-color: var(--ar-primary);
  --ar-blockquote-color: var(--ar-gray-900);
  --ar-blockquote-font-size: 1.5rem;
  --ar-blockquote-footer-color: var(--ar-gray-900);
  --ar-blockquote-footer-font-size: 1rem;
  --ar-blockquote-mark-size: 3rem;
  --ar-blockquote-mark-color: var(--ar-primary);
  --ar-nav-link-color: var(--ar-gray-800);
}

a {
  transition: color 0.2s ease-in-out;
}
a:focus {
  outline: none;
}

a[class^=bg-]:not(.btn),
a[class*=bg-]:not(.btn) {
  transition: background-color 0.25s ease;
}

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

svg {
  max-width: 100%;
}

.dark-mode-img {
  display: none;
}

iframe {
  width: 100%;
}

::-moz-selection {
  background: var(--ar-user-selection-color);
}

::selection {
  background: var(--ar-user-selection-color);
}

::-moz-selection {
  background: var(--ar-user-selection-color);
}

b,
strong {
  font-weight: 700;
}

small, .small {
  font-weight: inherit;
}

button:focus {
  outline: none;
}

.container-start {
  margin-left: calc((100% - (1320px - 1.5rem)) / 2);
}

.container-end {
  margin-right: calc((100% - (1320px - 1.5rem)) / 2);
}

@media (max-width: 1399.98px) {
  .container-start {
    margin-left: calc((100% - (95% - 1.5rem)) / 2);
  }
  .container-end {
    margin-right: calc((100% - (95% - 1.5rem)) / 2);
  }
}
@media (max-width: 575.98px) {
  .container-start,
  .container-end {
    width: 100%;
    padding: 0 0.75rem;
    margin-right: auto;
    margin-left: auto;
  }
}
@font-face {
  font-family: "around-icons";
  src: url("../fonts/around-icons.ttf?den6mh") format("truetype"), url("../fonts/around-icons.woff?den6mh") format("woff"), url("../fonts/around-icons.svg?den6mh#around-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=ai-], [class*=" ai-"],
[class^=bi-], [class*=" bi-"] {
  display: inline-block;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "around-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  line-height: 1;
}

.ai-activity:before {
  content: "\e900";
}

.ai-airbnb:before {
  content: "\e901";
}

.ai-align-center:before {
  content: "\e902";
}

.ai-align-justify:before {
  content: "\e903";
}

.ai-align-left:before {
  content: "\e904";
}

.ai-align-right:before {
  content: "\e905";
}

.ai-anchor:before {
  content: "\e906";
}

.ai-apple:before {
  content: "\e907";
}

.ai-archive:before {
  content: "\e908";
}

.ai-arrow-down-left:before {
  content: "\e909";
}

.ai-arrow-down-right:before {
  content: "\e90a";
}

.ai-arrow-down:before {
  content: "\e90b";
}

.ai-arrow-left:before {
  content: "\e90c";
}

.ai-arrow-right:before {
  content: "\e90d";
}

.ai-arrow-up-left:before {
  content: "\e90e";
}

.ai-arrow-up-right:before {
  content: "\e90f";
}

.ai-arrow-up:before {
  content: "\e910";
}

.ai-at:before {
  content: "\e911";
}

.ai-award:before {
  content: "\e912";
}

.ai-bar-chart-1:before {
  content: "\e913";
}

.ai-bar-chart-2:before {
  content: "\e914";
}

.ai-bar-chart-3:before {
  content: "\e915";
}

.ai-battery-1:before {
  content: "\e916";
}

.ai-battery-2:before {
  content: "\e917";
}

.ai-battery-3:before {
  content: "\e918";
}

.ai-battery-charge:before {
  content: "\e919";
}

.ai-battery:before {
  content: "\e91a";
}

.ai-behance:before {
  content: "\e91b";
}

.ai-bell-minus:before {
  content: "\e91c";
}

.ai-bell-off:before {
  content: "\e91d";
}

.ai-bell-plus:before {
  content: "\e91e";
}

.ai-bell:before {
  content: "\e91f";
}

.ai-blogger:before {
  content: "\e920";
}

.ai-bluetooth:before {
  content: "\e921";
}

.ai-book:before {
  content: "\e922";
}

.ai-bookmark-minus:before {
  content: "\e923";
}

.ai-bookmark-plus:before {
  content: "\e924";
}

.ai-bookmark:before {
  content: "\e925";
}

.ai-box:before {
  content: "\e926";
}

.ai-briefcase-plus:before {
  content: "\e927";
}

.ai-briefcase:before {
  content: "\e928";
}

.ai-bulb:before {
  content: "\e929";
}

.ai-calendar-check:before {
  content: "\e92a";
}

.ai-calendar-minus:before {
  content: "\e92b";
}

.ai-calendar-plus:before {
  content: "\e92c";
}

.ai-calendar-x:before {
  content: "\e92d";
}

.ai-calendar:before {
  content: "\e92e";
}

.ai-camera-off:before {
  content: "\e92f";
}

.ai-camera:before {
  content: "\e930";
}

.ai-capsule:before {
  content: "\e931";
}

.ai-card:before {
  content: "\e932";
}

.ai-cart:before {
  content: "\e933";
}

.ai-cast:before {
  content: "\e934";
}

.ai-check:before {
  content: "\e935";
}

.ai-checks:before {
  content: "\e936";
}

.ai-chevron-down:before {
  content: "\e937";
}

.ai-chevron-left:before,
.bi-chevron-left:before {
  content: "\e938";
}

.ai-chevron-right:before,
.bi-chevron-right:before {
  content: "\e939";
}

.ai-chevron-up:before {
  content: "\e93a";
}

.ai-chevrons-down:before {
  content: "\e93b";
}

.ai-chevrons-left:before {
  content: "\e93c";
}

.ai-chevrons-right:before {
  content: "\e93d";
}

.ai-chevrons-up:before {
  content: "\e93e";
}

.ai-chip:before {
  content: "\e93f";
}

.ai-circle-alert:before {
  content: "\e940";
}

.ai-circle-arrow-down:before {
  content: "\e941";
}

.ai-circle-arrow-left:before {
  content: "\e942";
}

.ai-circle-arrow-right:before {
  content: "\e943";
}

.ai-circle-arrow-up:before {
  content: "\e944";
}

.ai-circle-check:before {
  content: "\e945";
}

.ai-circle-help:before {
  content: "\e946";
}

.ai-circle-info:before {
  content: "\e947";
}

.ai-circle-minus:before {
  content: "\e948";
}

.ai-circle-plus:before {
  content: "\e949";
}

.ai-circle-slash:before {
  content: "\e94a";
}

.ai-circle-x:before {
  content: "\e94b";
}

.ai-clipboard:before {
  content: "\e94c";
}

.ai-cloud-download:before {
  content: "\e94d";
}

.ai-cloud-drizzle:before {
  content: "\e94e";
}

.ai-cloud-lightning:before {
  content: "\e94f";
}

.ai-cloud-rain:before {
  content: "\e950";
}

.ai-cloud-snow:before {
  content: "\e951";
}

.ai-cloud-upload:before {
  content: "\e952";
}

.ai-cloud:before {
  content: "\e953";
}

.ai-code-curly:before {
  content: "\e954";
}

.ai-code:before {
  content: "\e955";
}

.ai-codepen:before {
  content: "\e956";
}

.ai-collapse:before {
  content: "\e957";
}

.ai-command:before {
  content: "\e958";
}

.ai-compass:before {
  content: "\e959";
}

.ai-copy:before {
  content: "\e95a";
}

.ai-crop:before {
  content: "\e95b";
}

.ai-cross:before {
  content: "\e95c";
}

.ai-crosshair:before {
  content: "\e95d";
}

.ai-cup-hot:before {
  content: "\e95e";
}

.ai-cup:before {
  content: "\e95f";
}

.ai-database:before {
  content: "\e960";
}

.ai-delete:before {
  content: "\e961";
}

.ai-disc:before {
  content: "\e962";
}

.ai-discord:before {
  content: "\e963";
}

.ai-discount:before {
  content: "\e964";
}

.ai-dislike:before {
  content: "\e965";
}

.ai-disqus:before {
  content: "\e966";
}

.ai-dollar:before {
  content: "\e967";
}

.ai-dots-horizontal:before {
  content: "\e968";
}

.ai-dots-vertical:before {
  content: "\e969";
}

.ai-dots:before {
  content: "\e96a";
}

.ai-download:before {
  content: "\e96b";
}

.ai-drawer:before {
  content: "\e96c";
}

.ai-dribbble:before {
  content: "\e96d";
}

.ai-dropbox:before {
  content: "\e96e";
}

.ai-droplet:before {
  content: "\e96f";
}

.ai-edit-alt:before {
  content: "\e970";
}

.ai-edit:before {
  content: "\e971";
}

.ai-emoji-happy:before {
  content: "\e972";
}

.ai-emoji-meh:before {
  content: "\e973";
}

.ai-emoji-sad:before {
  content: "\e974";
}

.ai-emoji-smile:before {
  content: "\e975";
}

.ai-emoji-surprise:before {
  content: "\e976";
}

.ai-evernote:before {
  content: "\e977";
}

.ai-expand:before {
  content: "\e978";
}

.ai-external-link:before {
  content: "\e979";
}

.ai-facebook:before {
  content: "\e97a";
}

.ai-fast-forward:before {
  content: "\e97b";
}

.ai-file-minus:before {
  content: "\e97c";
}

.ai-file-plus:before {
  content: "\e97d";
}

.ai-file-text:before {
  content: "\e97e";
}

.ai-file:before {
  content: "\e97f";
}

.ai-filter:before {
  content: "\e980";
}

.ai-flag:before {
  content: "\e981";
}

.ai-flickr:before {
  content: "\e982";
}

.ai-folder-minus:before {
  content: "\e983";
}

.ai-folder-plus:before {
  content: "\e984";
}

.ai-folder:before {
  content: "\e985";
}

.ai-foursquare:before {
  content: "\e986";
}

.ai-gift:before {
  content: "\e987";
}

.ai-github:before {
  content: "\e988";
}

.ai-globe:before {
  content: "\e989";
}

.ai-google-drive:before {
  content: "\e98a";
}

.ai-google-play:before {
  content: "\e98b";
}

.ai-google:before {
  content: "\e98c";
}

.ai-grid:before {
  content: "\e98d";
}

.ai-hangouts:before {
  content: "\e98e";
}

.ai-hard-drive:before {
  content: "\e98f";
}

.ai-hashtag:before {
  content: "\e990";
}

.ai-headphone:before {
  content: "\e991";
}

.ai-heart-filled:before {
  content: "\e992";
}

.ai-heart:before {
  content: "\e993";
}

.ai-help:before {
  content: "\e994";
}

.ai-hide:before {
  content: "\e995";
}

.ai-home:before {
  content: "\e996";
}

.ai-horizontal-align-center:before {
  content: "\e997";
}

.ai-horizontal-align-left:before {
  content: "\e998";
}

.ai-horizontal-align-right:before {
  content: "\e999";
}

.ai-image:before {
  content: "\e99a";
}

.ai-inbox:before {
  content: "\e99b";
}

.ai-instagram:before {
  content: "\e99c";
}

.ai-joystick:before {
  content: "\e99d";
}

.ai-key:before {
  content: "\e99e";
}

.ai-kickstarter:before {
  content: "\e99f";
}

.ai-layer:before {
  content: "\e9a0";
}

.ai-layout-column:before {
  content: "\e9a1";
}

.ai-layout-grid:before {
  content: "\e9a2";
}

.ai-layout-row:before {
  content: "\e9a3";
}

.ai-layout-side:before {
  content: "\e9a4";
}

.ai-like:before {
  content: "\e9a5";
}

.ai-link:before {
  content: "\e9a6";
}

.ai-linkedin:before {
  content: "\e9a7";
}

.ai-list:before {
  content: "\e9a8";
}

.ai-lock-closed:before {
  content: "\e9a9";
}

.ai-lock-open:before {
  content: "\e9aa";
}

.ai-login:before {
  content: "\e9ab";
}

.ai-logout:before {
  content: "\e9ac";
}

.ai-mail:before {
  content: "\e9ad";
}

.ai-map-pin:before {
  content: "\e9ae";
}

.ai-map:before {
  content: "\e9af";
}

.ai-maximize:before {
  content: "\e9b0";
}

.ai-medium:before {
  content: "\e9b1";
}

.ai-menu:before {
  content: "\e9b2";
}

.ai-message-minus:before {
  content: "\e9b3";
}

.ai-message-plus:before {
  content: "\e9b4";
}

.ai-message:before {
  content: "\e9b5";
}

.ai-messages:before {
  content: "\e9b6";
}

.ai-messenger:before {
  content: "\e9b7";
}

.ai-microphone-off:before {
  content: "\e9b8";
}

.ai-microphone:before {
  content: "\e9b9";
}

.ai-minimize:before {
  content: "\e9ba";
}

.ai-mobile:before {
  content: "\e9bb";
}

.ai-monitor:before {
  content: "\e9bc";
}

.ai-moon:before {
  content: "\e9bd";
}

.ai-movie:before {
  content: "\e9be";
}

.ai-music:before {
  content: "\e9bf";
}

.ai-note:before {
  content: "\e9c0";
}

.ai-octagon-alert:before {
  content: "\e9c1";
}

.ai-octagon:before {
  content: "\e9c2";
}

.ai-odnoklassniki:before {
  content: "\e9c3";
}

.ai-open-book:before {
  content: "\e9c4";
}

.ai-paint-roll:before {
  content: "\e9c5";
}

.ai-paperclip:before {
  content: "\e9c6";
}

.ai-patreon:before {
  content: "\e9c7";
}

.ai-pause:before {
  content: "\e9c8";
}

.ai-paypal:before {
  content: "\e9c9";
}

.ai-pen:before {
  content: "\e9ca";
}

.ai-pencil:before {
  content: "\e9cb";
}

.ai-phone-call:before {
  content: "\e9cc";
}

.ai-phone-in:before {
  content: "\e9cd";
}

.ai-phone-out:before {
  content: "\e9ce";
}

.ai-phone-x:before {
  content: "\e9cf";
}

.ai-phone:before {
  content: "\e9d0";
}

.ai-pie-chart:before {
  content: "\e9d1";
}

.ai-pin:before {
  content: "\e9d2";
}

.ai-pinterest:before {
  content: "\e9d3";
}

.ai-planet:before {
  content: "\e9d4";
}

.ai-play-filled:before {
  content: "\e9d5";
}

.ai-play-next:before {
  content: "\e9d6";
}

.ai-play-previous:before {
  content: "\e9d7";
}

.ai-play:before {
  content: "\e9d8";
}

.ai-plug:before {
  content: "\e9d9";
}

.ai-power:before {
  content: "\e9da";
}

.ai-printer:before {
  content: "\e9db";
}

.ai-pulse:before {
  content: "\e9dc";
}

.ai-quora:before {
  content: "\e9dd";
}

.ai-reddit:before {
  content: "\e9de";
}

.ai-redo:before {
  content: "\e9df";
}

.ai-refresh:before {
  content: "\e9e0";
}

.ai-repeat:before {
  content: "\e9e1";
}

.ai-rewind:before {
  content: "\e9e2";
}

.ai-rotate-left:before {
  content: "\e9e3";
}

.ai-rotate-right:before {
  content: "\e9e4";
}

.ai-rss:before {
  content: "\e9e5";
}

.ai-save:before {
  content: "\e9e6";
}

.ai-scan:before {
  content: "\e9e7";
}

.ai-search:before {
  content: "\e9e8";
}

.ai-send:before {
  content: "\e9e9";
}

.ai-server:before {
  content: "\e9ea";
}

.ai-settings:before {
  content: "\e9eb";
}

.ai-share:before {
  content: "\e9ec";
}

.ai-shield:before {
  content: "\e9ed";
}

.ai-shopping-bag:before {
  content: "\e9ee";
}

.ai-show:before {
  content: "\e9ef";
}

.ai-shuffle:before {
  content: "\e9f0";
}

.ai-skype:before {
  content: "\e9f1";
}

.ai-slack:before {
  content: "\e9f2";
}

.ai-slider:before {
  content: "\e9f3";
}

.ai-snapchat:before {
  content: "\e9f4";
}

.ai-soundcloud:before {
  content: "\e9f5";
}

.ai-speaker:before {
  content: "\e9f6";
}

.ai-spotify:before {
  content: "\e9f7";
}

.ai-square-info:before {
  content: "\e9f8";
}

.ai-square-minus:before {
  content: "\e9f9";
}

.ai-square-plus:before {
  content: "\e9fa";
}

.ai-square-x:before {
  content: "\e9fb";
}

.ai-square:before {
  content: "\e9fc";
}

.ai-stack-overflow:before {
  content: "\e9fd";
}

.ai-star-filled:before {
  content: "\e9fe";
}

.ai-star-half:before {
  content: "\e9ff";
}

.ai-star:before {
  content: "\ea00";
}

.ai-steam:before {
  content: "\ea01";
}

.ai-sticker:before {
  content: "\ea02";
}

.ai-sun:before {
  content: "\ea03";
}

.ai-table-column:before {
  content: "\ea04";
}

.ai-table-row:before {
  content: "\ea05";
}

.ai-table:before {
  content: "\ea06";
}

.ai-tablet:before {
  content: "\ea07";
}

.ai-tag:before {
  content: "\ea08";
}

.ai-telegram:before {
  content: "\ea09";
}

.ai-terminal:before {
  content: "\ea0a";
}

.ai-time:before {
  content: "\ea0b";
}

.ai-tinder:before {
  content: "\ea0c";
}

.ai-toggle-left:before {
  content: "\ea0d";
}

.ai-toggle-right:before {
  content: "\ea0e";
}

.ai-tool:before {
  content: "\ea0f";
}

.ai-trash:before {
  content: "\ea10";
}

.ai-trello:before {
  content: "\ea11";
}

.ai-triangle-alert:before {
  content: "\ea12";
}

.ai-triangle:before {
  content: "\ea13";
}

.ai-tumblr:before {
  content: "\ea14";
}

.ai-twitch:before {
  content: "\ea15";
}

.ai-twitter:before {
  content: "\ea16";
}

.ai-undo:before {
  content: "\ea17";
}

.ai-upload:before {
  content: "\ea18";
}

.ai-user-check:before {
  content: "\ea19";
}

.ai-user-group:before {
  content: "\ea1a";
}

.ai-user-minus:before {
  content: "\ea1b";
}

.ai-user-plus:before {
  content: "\ea1c";
}

.ai-user-x:before {
  content: "\ea1d";
}

.ai-user:before {
  content: "\ea1e";
}

.ai-vertical-align-bottom:before {
  content: "\ea1f";
}

.ai-vertical-align-center:before {
  content: "\ea20";
}

.ai-vertical-align-top:before {
  content: "\ea21";
}

.ai-viber:before {
  content: "\ea22";
}

.ai-video-off:before {
  content: "\ea23";
}

.ai-video:before {
  content: "\ea24";
}

.ai-vimeo:before {
  content: "\ea25";
}

.ai-vk:before {
  content: "\ea26";
}

.ai-volume-1:before {
  content: "\ea27";
}

.ai-volume-2:before {
  content: "\ea28";
}

.ai-volume-x:before {
  content: "\ea29";
}

.ai-volume:before {
  content: "\ea2a";
}

.ai-vote-down:before {
  content: "\ea2b";
}

.ai-vote-up:before {
  content: "\ea2c";
}

.ai-wallet:before {
  content: "\ea2d";
}

.ai-wechat:before {
  content: "\ea2e";
}

.ai-whatsapp:before {
  content: "\ea2f";
}

.ai-wifi-off:before {
  content: "\ea30";
}

.ai-wifi:before {
  content: "\ea31";
}

.ai-wordpress:before {
  content: "\ea32";
}

.ai-xing:before {
  content: "\ea33";
}

.ai-yelp:before {
  content: "\ea34";
}

.ai-youtube:before {
  content: "\ea35";
}

.ai-zoom-in:before {
  content: "\ea36";
}

.ai-zoom-out:before {
  content: "\ea37";
}

.ai-bulb-alt:before {
  content: "\ea38";
}

.ai-chart:before {
  content: "\ea39";
}

.ai-check-alt:before {
  content: "\ea3a";
}

.ai-circle-check-filled:before {
  content: "\ea3b";
}

.ai-dashboard:before {
  content: "\ea3c";
}

.ai-mail-filled:before {
  content: "\ea3d";
}

.ai-rocket:before {
  content: "\ea3e";
}

.ai-quotes:before {
  content: "\ea3f";
}

.ai-search-settings:before {
  content: "\ea40";
}

.ai-settings-filled:before {
  content: "\ea41";
}

.ai-tiktok:before {
  content: "\ea42";
}

.ai-zoom:before {
  content: "\ea43";
}

.ai-cross-alt:before {
  content: "\ea44";
}

.ai-cafe:before {
  content: "\ea45";
}

.ai-flower:before {
  content: "\ea46";
}

.ai-rug:before {
  content: "\ea47";
}

.ai-sofa:before {
  content: "\ea48";
}

.ai-spa:before {
  content: "\ea49";
}

.ai-towel:before {
  content: "\ea4a";
}

.ai-plus:before {
  content: "\ea4b";
}

.ai-canteen:before {
  content: "\ea4c";
}

.ai-clock:before {
  content: "\ea4d";
}

.ai-parking:before {
  content: "\ea4e";
}

.ai-shower:before {
  content: "\ea4f";
}

.ai-space:before {
  content: "\ea50";
}

.ai-stairs:before {
  content: "\ea51";
}

.ai-chat:before {
  content: "\ea52";
}

.ai-drop:before {
  content: "\ea53";
}

.ai-fruit:before {
  content: "\ea54";
}

.ai-heart-alt:before {
  content: "\ea55";
}

.ai-stones:before {
  content: "\ea56";
}

.ai-weight:before {
  content: "\ea57";
}

.ai-body:before {
  content: "\ea58";
}

.ai-target:before {
  content: "\ea59";
}

h4, .h4, .fs-4,
h5, .h5, .fs-5,
h6, .h6, .fs-6 {
  line-height: 1.4;
}

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
  font-weight: var(--ar-heading-font-weight);
}
h1 > a, .h1 > a, h2 > a, .h2 > a, h3 > a, .h3 > a,
h4 > a, .h4 > a, h5 > a, .h5 > a, h6 > a, .h6 > a {
  color: var(--ar-heading-link-color);
  text-decoration: none;
}
h1 > a:hover, .h1 > a:hover, h2 > a:hover, .h2 > a:hover, h3 > a:hover, .h3 > a:hover,
h4 > a:hover, .h4 > a:hover, h5 > a:hover, .h5 > a:hover, h6 > a:hover, .h6 > a:hover {
  color: var(--ar-heading-link-hover-color);
}

.blockquote {
  position: relative;
  color: var(--ar-blockquote-color);
  font-size: var(--ar-blockquote-font-size);
  padding-left: calc(var(--ar-blockquote-mark-size) + 1.75rem);
}
.blockquote::before {
  position: absolute;
  top: 0;
  left: 0;
  margin: -0.1875rem -0.1875rem 1rem -0.1875rem;
  color: var(--ar-blockquote-mark-color);
  font-family: "around-icons";
  font-size: var(--ar-blockquote-mark-size);
  line-height: 1;
  content: "\ea3f";
}

.blockquote-footer {
  display: flex;
  margin: 0;
  padding-top: 0.5rem;
  padding-left: calc(var(--ar-blockquote-mark-size) + 1.75rem);
  color: var(--ar-blockquote-footer-color);
  font-size: var(--ar-blockquote-footer-font-size);
  font-weight: 600;
}
.blockquote-footer::before {
  display: block;
  width: 2rem;
  height: 0.0625rem;
  margin-top: 0.75rem;
  margin-right: 1rem;
  background-color: var(--ar-blockquote-footer-color);
  content: "";
}

.text-center .blockquote {
  padding-left: 0;
}
.text-center .blockquote::before {
  position: static;
  display: block;
}
.text-center .blockquote-footer {
  justify-content: center;
  padding-left: 0;
}
.text-center .blockquote-footer::before {
  display: none;
}

.text-end .blockquote {
  padding-left: 0;
  padding-right: calc(var(--ar-blockquote-mark-size) + 1.75rem);
}
.text-end .blockquote::before {
  right: 0;
  left: auto;
}
.text-end .blockquote-footer {
  justify-content: end;
  padding-left: 0;
  padding-right: calc(var(--ar-blockquote-mark-size) + 1.75rem);
}

@media (max-width: 575.98px) {
  .blockquote {
    padding-left: calc(var(--ar-blockquote-mark-size) * 0.75 + 1rem);
  }
  .blockquote::before {
    font-size: calc(var(--ar-blockquote-mark-size) * 0.75);
    margin-bottom: 0.75rem;
  }
  .blockquote-footer {
    padding-left: calc(var(--ar-blockquote-mark-size) * 0.75 + 1rem);
  }
  .blockquote-footer::before {
    width: 1.25rem;
    margin-right: 0.75rem;
  }
  .text-end .blockquote,
  .text-end .blockquote-footer {
    padding-right: calc(var(--ar-blockquote-mark-size) * 0.75 + 1rem);
  }
}
dt {
  color: var(--ar-heading-color);
  font-weight: var(--ar-heading-font-weight);
}

body .dark-mode p, body .dark-mode ul, body .dark-mode ol, body .dark-mode span, body .dark-mode dd {
  color: var(--ar-body-color);
}

del.text-muted {
  color: var(--ar-gray-500) !important;
}

:not(pre) > code[class*=language-],
pre[class*=language-],
pre {
  --ar-pre-padding-y: 1.5rem;
  --ar-pre-padding-x: 1rem;
  --ar-pre-border-width: var(--ar-border-width);
  --ar-pre-border-color: rgba(255, 255, 255, 0.13);
  --ar-pre-bg: #121519;
  --ar-pre-line-numbers-border-width: var(--ar-border-width);
  --ar-pre-line-numbers-border-color: rgba(255, 255, 255, 0.13);
  --ar-pre-line-numbers-color: rgba(255, 255, 255, 0.4);
  --ar-pre-tag-color: #47abfd;
  --ar-pre-comment-color: rgba(255, 255, 255, .4);
  --ar-pre-attr-name-color: #a8e1ff;
  --ar-pre-attr-value-color: #f0c93e;
  --ar-pre-class-name-color: #35bcba;
  margin: 0;
  padding: 1.5rem 1rem;
  border: var(--ar-border-width) solid rgba(255, 255, 255, 0.13);
  border-radius: var(--ar-border-radius-xl);
  background-color: #121519;
  font-size: 0.875em;
}

.code-toolbar :not(pre) > code[class*=language-],
.code-toolbar pre[class*=language-] {
  padding-top: calc(var(--ar-pre-padding-y) * 1.5);
}

code[class*=language-],
pre[class*=language-],
pre {
  --ar-pre-color: #fff;
  color: var(--ar-pre-color);
  text-shadow: none;
}

.line-numbers .line-numbers-rows {
  border-right: var(--ar-pre-line-numbers-border-width) solid var(--ar-pre-line-numbers-border-color);
}
.line-numbers .line-numbers-rows > span::before {
  color: var(--ar-pre-line-numbers-color);
}

div.code-toolbar > .toolbar {
  opacity: 1;
}
div.code-toolbar > .toolbar > .toolbar-item > a,
div.code-toolbar > .toolbar > .toolbar-item > button,
div.code-toolbar > .toolbar .toolbar-item > span {
  display: inline-block;
  margin: 0.375rem 0.5rem;
  padding: 0.25rem 0.75rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border-radius: calc(var(--ar-border-radius) * 0.75);
  border: 1px solid rgba(var(--ar-primary-rgb), 0.35);
  background-color: rgba(68, 140, 116, 0.12);
  color: var(--ar-primary);
  font-size: calc(var(--ar-body-font-size) * 0.75);
  font-weight: 700;
  box-shadow: none;
}
div.code-toolbar > .toolbar > .toolbar-item > a:focus,
div.code-toolbar > .toolbar > .toolbar-item > button:focus,
div.code-toolbar > .toolbar .toolbar-item > span:focus {
  outline: none;
  box-shadow: none;
}
div.code-toolbar > .toolbar > .toolbar-item > a:hover,
div.code-toolbar > .toolbar > .toolbar-item > button:hover,
div.code-toolbar > .toolbar .toolbar-item > span:hover {
  background-color: var(--ar-primary);
  color: #fff !important;
}

.token.boolean, .token.constant, .token.deleted, .token.number,
.token.property, .token.symbol, .token.tag {
  color: var(--ar-pre-tag-color);
}

.token.cdata, .token.comment, .token.doctype, .token.prolog {
  color: var(--ar-pre-comment-color);
}

.token.attr-name, .token.builtin, .token.char,
.token.inserted, .token.selector {
  color: var(--ar-pre-attr-name-color);
}

.token.atrule, .token.attr-value,
.token.keyword, .token.string {
  color: var(--ar-pre-attr-value-color);
}

.language-css .token.string, .style .token.string,
.token.entity, .token.operator, .token.url,
.token.class-name, .token.function {
  color: var(--ar-pre-class-name-color);
  background: none;
}

kbd {
  border-radius: 0.5rem;
}

.table {
  --ar-table-th-color: var(--ar-gray-900);
}
.table thead th, .table tbody th {
  color: var(--ar-table-th-color) !important;
}

.table-dark {
  --ar-table-th-color: #fff;
  --ar-table-color: rgba(255, 255, 255, 0.7);
  --ar-table-striped-color: var(--ar-table-dark-color);
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #c3d0dc;
}

.table.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}

.table > :not(:first-child) {
  border-top: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
}

.form-control:disabled,
.form-select:disabled {
  cursor: not-allowed;
  box-shadow: none !important;
}

.form-control[readonly],
.form-control:disabled {
  box-shadow: none !important;
}

label {
  font-size: calc(var(--ar-body-font-size) * 0.875);
}

.form-floating > label {
  color: var(--ar-gray-500);
  font-weight: 500;
}

.input-group-text .form-check-input {
  margin-top: 0;
}

.form-switch {
  margin-bottom: 0.5rem;
}
.form-switch .form-check-input {
  height: 1.5rem;
  margin-top: unset;
  border: unset;
  background-color: var(--ar-gray-500);
  filter: none;
}
.form-switch .form-check-input:checked {
  background-color: var(--ar-primary);
}
.form-switch .form-check-input:disabled {
  box-shadow: none;
}
.form-switch.mode-switch {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
}
.form-switch.mode-switch .form-check-label {
  transition: color 0.15s ease-in-out;
  font-size: 1rem;
  font-weight: 500;
}
.form-switch.mode-switch .form-check-label:first-of-type {
  order: 1;
  margin-right: 0.625rem;
  color: var(--ar-primary);
}
.form-switch.mode-switch .form-check-label:last-of-type {
  order: 3;
  margin-left: 0.625rem;
}
.form-switch.mode-switch .form-check-input {
  order: 2;
  margin-left: 0;
  background-color: var(--ar-primary) !important;
}
.form-switch.mode-switch .form-check-input:checked ~ .form-check-label:first-of-type {
  color: var(--ar-body-color);
}
.form-switch.mode-switch .form-check-input:checked ~ .form-check-label:last-of-type {
  color: var(--ar-primary);
}

.bg-primary .form-check-input:checked {
  border-color: #fff !important;
  background-color: #fff !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23448c74' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
}

.form-control-flush,
.form-select-flush {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  border: 0;
  color: var(--ar-gray-800);
  font-weight: 500;
}

.form-select-flush {
  padding-right: 2.25rem;
}

.password-toggle {
  position: relative;
}
.password-toggle .form-control {
  padding-right: 3rem;
}

.password-toggle-btn {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  margin-bottom: 0;
  padding: 0.5rem;
  transform: translateY(-50%);
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
}
.password-toggle-btn .password-toggle-indicator {
  transition: color 0.2s ease-in-out;
  color: var(--ar-gray-600);
  font-family: "around-icons";
  font-size: 1.25em;
  font-style: normal;
}
.password-toggle-btn .password-toggle-indicator::before {
  content: "\e9ef";
}
.password-toggle-btn .password-toggle-indicator:hover {
  color: var(--ar-gray-800);
}
.password-toggle-btn .password-toggle-check {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.password-toggle-btn .password-toggle-check:checked ~ .password-toggle-indicator::before {
  content: "\e995";
}

.valid-feedback,
.invalid-feedback,
.valid-tooltip,
.invalid-tooltip {
  font-weight: 500;
}

.valid-tooltip {
  position: static;
  background-color: rgba(63, 202, 144, 0.1);
  color: #3fca90;
}

.invalid-tooltip {
  position: static;
  background-color: rgba(237, 80, 80, 0.1);
  color: #ed5050;
}

.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-tooltip,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-tooltip {
  display: table;
}

.was-validated .password-toggle .form-control:invalid,
.was-validated .password-toggle .form-control.is-invalid,
.was-validated .password-toggle .form-control:valid,
.was-validated .password-toggle .form-control.is-valid {
  padding-right: 4rem;
}
.was-validated .password-toggle .form-control:invalid + .password-toggle-btn,
.was-validated .password-toggle .form-control.is-invalid + .password-toggle-btn,
.was-validated .password-toggle .form-control:valid + .password-toggle-btn,
.was-validated .password-toggle .form-control.is-valid + .password-toggle-btn {
  right: 2.1rem;
}

.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-control:valid {
  z-index: 4;
}

.was-validated :valid:not(:required):not(.form-check-input):not(.btn),
.is-valid:not(:required):not(.form-check-input):not(.btn) {
  border-color: var(--ar-gray-400) !important;
  background: none;
}
.was-validated :valid:not(:required):not(.form-check-input):not(.btn):focus,
.is-valid:not(:required):not(.form-check-input):not(.btn):focus {
  border-color: var(--ar-gray-700) !important;
}

.was-validated .input-group :valid:not(:required):not(.btn) {
  border-color: transparent !important;
}
.was-validated .input-group :valid:not(:required):not(.btn):focus {
  border-color: transparent !important;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--ar-primary);
  border-color: var(--ar-primary);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: transparent;
  border-color: 1px solid var(--ar-gray-500);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.was-validated .form-check-input.is-valid ~ .form-check-label {
  color: inherit;
}

.range-slider {
  --ar-range-slider-height: 0.1875rem;
  --ar-range-slider-bg: var(--ar-border-color);
  --ar-range-slider-connect-bg: var(--ar-primary);
  --ar-range-slider-handle-size: 1.0625rem;
  --ar-range-slider-handle-bg: var(--ar-primary);
  --ar-range-slider-handle-border-radius: 50%;
  --ar-range-slider-pips-color: var(--ar-body-color);
  --ar-range-slider-pips-font-size: calc(var(--ar-body-font-size) * 0.75);
  --ar-range-slider-pips-border-width: var(--ar-border-width);
  --ar-range-slider-pips-border-color: #d0dae4;
  --ar-range-slider-tooltip-padding-y: 0.375rem;
  --ar-range-slider-tooltip-padding-x: 0.75rem;
  --ar-range-slider-tooltip-bg: transparent;
  --ar-range-slider-tooltip-color: var(--ar-gray-800);
  --ar-range-slider-tooltip-font-size: calc(var(--ar-body-font-size) * 0.875);
  --ar-range-slider-tooltip-border-radius: var(--ar-border-radius-sm);
}

.range-slider-ui {
  height: var(--ar-range-slider-height);
  margin: 0;
  margin-top: 3.25rem;
  margin-right: 0;
  margin-bottom: 1.75rem;
  margin-left: 0;
  border: 0;
  background-color: var(--ar-range-slider-bg);
  box-shadow: none;
}
[data-pips] .range-slider-ui {
  margin-bottom: 3.5rem;
}
.range-slider-ui .noUi-connect {
  background-color: var(--ar-range-slider-connect-bg);
}
.range-slider-ui .noUi-handle {
  top: 50%;
  width: var(--ar-range-slider-handle-size);
  height: var(--ar-range-slider-handle-size);
  margin-top: calc(var(--ar-range-slider-handle-size) * -0.5);
  border: 0;
  border-radius: var(--ar-range-slider-handle-border-radius);
  background-color: var(--ar-range-slider-handle-bg);
  box-shadow: none;
}
.range-slider-ui .noUi-handle::before, .range-slider-ui .noUi-handle::after {
  display: none;
}
.range-slider-ui .noUi-handle:focus {
  outline: none;
}
.range-slider-ui .noUi-marker-normal {
  display: none;
}
.range-slider-ui .noUi-marker-horizontal.noUi-marker {
  width: var(--ar-range-slider-pips-border-width);
  background-color: var(--ar-range-slider-pips-border-color);
}
.range-slider-ui .noUi-marker-horizontal.noUi-marker-large {
  height: 0.75rem;
}
.range-slider-ui .noUi-value {
  padding-top: 0.125rem;
  color: var(--ar-range-slider-pips-color);
  font-size: var(--ar-range-slider-pips-font-size);
}
.range-slider-ui .noUi-tooltip {
  padding: var(--ar-range-slider-tooltip-padding-y) var(--ar-range-slider-tooltip-padding-x);
  border: 0;
  background-color: var(--ar-range-slider-tooltip-bg);
  color: var(--ar-range-slider-tooltip-color);
  font-size: var(--ar-range-slider-tooltip-font-size);
  font-weight: 500;
  line-height: 1.2;
  border-radius: var(--ar-range-slider-tooltip-border-radius);
}

html:not([dir=rtl]) .range-slider-ui.noUi-horizontal .noUi-handle {
  right: calc(var(--ar-range-slider-handle-size) * -0.5);
}

.subscription-status {
  display: table;
  border-radius: calc(var(--ar-border-radius) * 0.5);
  font-size: 0.8125rem;
}
.subscription-status.status-success, .subscription-status.status-error {
  margin-top: 0.5rem;
  padding: 0.375rem 0.75rem;
}
.subscription-status.status-success {
  background-color: rgba(63, 202, 144, 0.1);
  color: #2ea875;
}
.subscription-status.status-error {
  background-color: rgba(237, 80, 80, 0.1);
  color: #ed5050;
}

/* .credit-card-icon {
  width: 40px;
  height: 26px;
  background-image: url(../img/credit-cards.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
} */
.credit-card-icon.amex {
  background-position: 0 -52px !important;
}
.credit-card-icon.diners {
  background-position: 0 -78px !important;
}
.credit-card-icon.discover {
  background-position: 0 -104px !important;
}
.credit-card-icon.jcb {
  background-position: 0 -130px !important;
}
.credit-card-icon.mastercard {
  background-position: 0 -156px !important;
}
.credit-card-icon.visa {
  background-position: 0 -182px !important;
}

.btn-check + .btn-outline-secondary {
  --ar-btn-hover-bg: transparent;
  --ar-btn-active-bg: transparent;
}
.btn-check + .btn-outline-secondary:not(.btn-icon) {
  --ar-btn-active-border-color: var(--ar-primary);
  --ar-btn-active-color: var(--ar-primary);
}
.btn-check + .btn-icon.btn-outline-secondary {
  --ar-btn-border-color: transparent;
  --ar-btn-hover-border-color: transparent;
}

.count-input {
  display: inline-flex;
  align-items: center;
}
.count-input .form-control {
  width: 1.5rem;
  padding: 0;
  border: 0;
  background: 0 !important;
  font-size: var(--ar-body-font-size);
  font-weight: 500;
  text-align: center;
  -moz-appearance: textfield;
}
.count-input .form-control::-webkit-outer-spin-button, .count-input .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.count-input .btn {
  --ar-btn-color: var(--ar-gray-800) !important;
  --ar-btn-hover-color: var(--ar-primary) !important;
  --ar-btn-active-color: var(--ar-primary) !important;
  --ar-btn-hover-border-color: transparent;
  --ar-btn-active-border-color: transparent;
  --ar-btn-font-weight: 500;
}

.input-group {
  --ar-input-group-padding-y: 0.5rem;
  --ar-input-group-padding-x: 0.5rem;
  --ar-input-group-border-color: var(--ar-gray-400);
  --ar-input-group-border-width: var(--ar-border-width);
  --ar-input-group-border-radius: var(--ar-border-radius-lg);
  padding: var(--ar-input-group-padding-y) var(--ar-input-group-padding-x);
  border: var(--ar-input-group-border-width) solid var(--ar-input-group-border-color);
  border-radius: var(--ar-input-group-border-radius);
}
.input-group .form-control,
.input-group .form-select {
  border-color: transparent !important;
  border-radius: 0 !important;
}
.input-group [class^=ai-],
.input-group [class*=" ai-"] {
  font-size: 1.25em;
}
.input-group .btn {
  --ar-btn-padding-y: 0.825rem;
  --ar-btn-padding-x: 1.75rem;
  --ar-btn-border-radius: var(--ar-border-radius);
  padding: var(--ar-btn-padding-y) var(--ar-btn-padding-x);
}
.input-group .btn:not(.rounded-pill) {
  border-radius: var(--ar-btn-border-radius) !important;
}

.input-group-lg {
  --ar-input-group-border-radius: var(--ar-border-radius-xl);
}
.input-group-lg .btn {
  --ar-btn-padding-y: 1rem;
  --ar-btn-padding-x: 2rem;
  --ar-btn-border-radius: var(--ar-border-radius-lg);
}

.input-group-sm {
  --ar-input-group-border-radius: var(--ar-border-radius);
}
.input-group-sm .btn {
  --ar-btn-padding-y: 0.575rem;
  --ar-btn-padding-x: 1.25rem;
  --ar-btn-border-radius: var(--ar-border-radius-sm);
}

.input-group-text {
  border-radius: 0 !important;
}
.input-group-text + .form-control, .input-group-text + .form-select, .input-group-text + .input-group-text {
  padding-left: 0;
}

.form-control + .input-group-text,
.form-select + .input-group-text {
  padding-left: 0;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn:not([class^=btn-outline-]):not([class*=" btn-outline-"]):not(.btn-secondary):not(.btn-light):not(.btn-link) {
  --ar-btn-color: #fff;
}
.btn > [class^=ai-],
.btn > [class*=" ai-"] {
  font-size: 1.25em;
}

.btn-primary {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
  --ar-btn-hover-bg: var(--ar-primary-hover);
  --ar-btn-active-bg: #37715d;
  --ar-btn-hover-border-color: #37715d;
  --ar-btn-active-border-color: #37715d;
  --ar-btn-disabled-color: #fff;
}

.btn-secondary {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
  --ar-btn-hover-bg: #d3dde6;
  --ar-btn-active-bg: #d3dde6;
  --ar-btn-hover-border-color: #d3dde6;
  --ar-btn-active-border-color: #d3dde6;
  --ar-btn-disabled-color: #fff;
}

.btn-success {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
  --ar-btn-hover-bg: #31b07b;
  --ar-btn-active-bg: #31b07b;
  --ar-btn-hover-border-color: #31b07b;
  --ar-btn-active-border-color: #31b07b;
  --ar-btn-disabled-color: #fff;
}

.btn-info {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
  --ar-btn-hover-bg: #316bb0;
  --ar-btn-active-bg: #316bb0;
  --ar-btn-hover-border-color: #316bb0;
  --ar-btn-active-border-color: #316bb0;
  --ar-btn-disabled-color: #fff;
}

.btn-warning {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
  --ar-btn-hover-bg: #e9c02b;
  --ar-btn-active-bg: #e9c02b;
  --ar-btn-hover-border-color: #e9c02b;
  --ar-btn-active-border-color: #e9c02b;
  --ar-btn-disabled-color: #fff;
}

.btn-danger {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
  --ar-btn-hover-bg: #e92b2b;
  --ar-btn-active-bg: #e92b2b;
  --ar-btn-hover-border-color: #e92b2b;
  --ar-btn-active-border-color: #e92b2b;
  --ar-btn-disabled-color: #fff;
}

.btn-light, .dark-mode .btn-dark:not(.ignore-dark-mode), .card-hover-primary:hover .btn-primary {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
  --ar-btn-hover-bg: #ebebeb;
  --ar-btn-active-bg: #ebebeb;
  --ar-btn-hover-border-color: #ebebeb;
  --ar-btn-active-border-color: #ebebeb;
  --ar-btn-disabled-color: #fff;
}

.btn-dark {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
  --ar-btn-hover-bg: #010101;
  --ar-btn-active-bg: #010101;
  --ar-btn-hover-border-color: #010101;
  --ar-btn-active-border-color: #010101;
  --ar-btn-disabled-color: #fff;
}

.btn-light,
.dark-mode .btn-dark:not(.ignore-dark-mode),
.card-hover-primary:hover .btn-primary {
  --ar-btn-color: #434a57 !important;
  --ar-btn-hover-color: #121519 !important;
  --ar-btn-active-color: #121519 !important;
  --ar-btn-disabled-color: #576071;
}

[class^=btn-outline-],
[class*=" btn-outline-"] {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
}

.btn-outline-secondary {
  --ar-btn-color: #434a57;
  --ar-btn-hover-color: #121519;
  --ar-btn-active-color: #121519;
  --ar-btn-hover-bg: #edf1f5;
  --ar-btn-active-bg: #edf1f5;
  --ar-btn-border-color: #d7dde2;
  --ar-btn-hover-border-color: #d7dde2;
  --ar-btn-active-border-color: #d7dde2;
  --ar-btn-disabled-color: #576071;
  --ar-btn-disabled-border-color: #e3e9ef;
}

.btn-outline-light, .dark-mode .btn-outline-dark:not(.ignore-dark-mode), .card-hover-primary:hover .btn-outline-primary {
  --ar-btn-hover-color: #434a57;
  --ar-btn-active-color: #434a57;
  --ar-btn-hover-bg: #fff;
  --ar-btn-active-bg: #fff;
}

.btn-link {
  --ar-btn-font-weight: 600;
  text-decoration: none;
}

.btn-icon {
  --ar-btn-size: 3rem;
  flex-shrink: 0;
  width: var(--ar-btn-size);
  height: var(--ar-btn-size);
  padding: 0;
}
.btn-icon.btn-xs {
  --ar-btn-size: 1rem;
  --ar-btn-font-size: calc(var(--ar-body-font-size) * 0.75);
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  --ar-btn-size: 2.25rem;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  --ar-btn-size: 3.5rem;
}
.btn-icon.btn-xl {
  --ar-btn-size: 4.5rem;
  --ar-btn-font-size: calc(var(--ar-body-font-size) * 1.125);
}
.btn-icon > .ai-play,
.btn-icon > .ai-play-filled {
  margin-left: 0.1875rem;
}

.btn-group .btn {
  --ar-btn-padding-x: 1.3125rem;
}
.btn-group .btn.dropdown-toggle-split {
  --ar-btn-padding-x: 0.875rem;
  padding-right: var(--ar-btn-padding-x);
  padding-left: var(--ar-btn-padding-x);
}

.btn-group-lg .btn,
.btn-group .btn-lg {
  --ar-btn-padding-x: 1.5rem;
}
.btn-group-lg .btn.dropdown-toggle-split,
.btn-group .btn-lg.dropdown-toggle-split {
  --ar-btn-padding-x: 1rem;
  padding-right: var(--ar-btn-padding-x);
  padding-left: var(--ar-btn-padding-x);
}

.btn-group-sm .btn,
.btn-group .btn-sm {
  --ar-btn-padding-x: 0.9375rem;
}
.btn-group-sm .btn.dropdown-toggle-split,
.btn-group .btn-sm.dropdown-toggle-split {
  --ar-btn-padding-x: 0.625rem;
  padding-right: var(--ar-btn-padding-x);
  padding-left: var(--ar-btn-padding-x);
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--ar-border-width) * -1);
}

.btn-close {
  transition: opacity 0.25s ease-in-out;
}

.btn-more::before {
  content: attr(data-hide-label);
}
.btn-more::after {
  display: inline-block;
  margin-left: 0.25rem;
  will-change: transform;
  transition: transform 0.2s ease-in-out;
  transform: rotate(180deg);
  font-family: "around-icons";
  font-size: 1.5em;
  content: "\e937";
}
.btn-more.fs-2::after, .btn-more.fs-3::after, .btn-more.fs-4::after, .btn-more.fs-5::after {
  font-size: 1em;
}
.btn-more.h2::after, .btn-more.h3::after, .btn-more.h4::after, .btn-more.h5::after, .btn-more.h6::after {
  font-weight: 500;
}
.btn-more.collapsed::before {
  content: attr(data-show-label);
}
.btn-more.collapsed::after {
  transform: none;
}

.btn-scroll-top {
  --ar-btn-scroll-top-size: 2.5rem;
  --ar-btn-scroll-top-font-size: 1.25rem;
  --ar-btn-scroll-top-color: var(--ar-gray-800);
  --ar-btn-scroll-top-bg: var(--ar-body-bg);
  --ar-btn-scroll-top-border-radius: 50%;
  --ar-btn-scroll-top-box-shadow: 0 0.275rem 1.125rem rgba(18, 21, 25, 0.1);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1rem;
  bottom: 1rem;
  width: var(--ar-btn-scroll-top-size);
  height: var(--ar-btn-scroll-top-size);
  transition: transform 0.25s ease-in-out, opacity 0.25s;
  transform: scale(0);
  border-radius: 50%;
  color: var(--ar-btn-scroll-top-color) !important;
  font-size: var(--ar-btn-scroll-top-font-size);
  box-shadow: var(--ar-btn-scroll-top-box-shadow);
  text-decoration: none;
  opacity: 0;
  z-index: 1030;
}
.btn-scroll-top::before {
  position: absolute;
  top: 0.0625rem;
  left: 0.0625rem;
  width: calc(var(--ar-btn-scroll-top-size) - 0.125rem);
  height: calc(var(--ar-btn-scroll-top-size) - 0.125rem);
  background-color: var(--ar-btn-scroll-top-bg);
  border-radius: 50%;
  z-index: -1;
  content: "";
}
.btn-scroll-top > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}
.btn-scroll-top.show {
  transform: scale(1);
  opacity: 1;
}

.btn-airbnb:hover, .btn-airbnb:focus, .btn-airbnb:active {
  border-color: #fd5c63 !important;
  background-color: #fd5c63 !important;
  color: #fff !important;
}

.btn-behance:hover, .btn-behance:focus, .btn-behance:active {
  border-color: #1769ff !important;
  background-color: #1769ff !important;
  color: #fff !important;
}

.btn-blogger:hover, .btn-blogger:focus, .btn-blogger:active {
  border-color: #f57d00 !important;
  background-color: #f57d00 !important;
  color: #fff !important;
}

.btn-codepen:hover, .btn-codepen:focus, .btn-codepen:active {
  border-color: #0ebeff !important;
  background-color: #0ebeff !important;
  color: #fff !important;
}

.btn-discord:hover, .btn-discord:focus, .btn-discord:active {
  border-color: #7289da !important;
  background-color: #7289da !important;
  color: #fff !important;
}

.btn-disqus:hover, .btn-disqus:focus, .btn-disqus:active {
  border-color: #2e9fff !important;
  background-color: #2e9fff !important;
  color: #fff !important;
}

.btn-dribbble:hover, .btn-dribbble:focus, .btn-dribbble:active {
  border-color: #ea4c89 !important;
  background-color: #ea4c89 !important;
  color: #fff !important;
}

.btn-dropbox:hover, .btn-dropbox:focus, .btn-dropbox:active {
  border-color: #007ee5 !important;
  background-color: #007ee5 !important;
  color: #fff !important;
}

.btn-evernote:hover, .btn-evernote:focus, .btn-evernote:active {
  border-color: #2dbe60 !important;
  background-color: #2dbe60 !important;
  color: #fff !important;
}

.btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active {
  border-color: #1877f2 !important;
  background-color: #1877f2 !important;
  color: #fff !important;
}

.btn-flickr:hover, .btn-flickr:focus, .btn-flickr:active {
  border-color: #0063dc !important;
  background-color: #0063dc !important;
  color: #fff !important;
}

.btn-foursquare:hover, .btn-foursquare:focus, .btn-foursquare:active {
  border-color: #f94877 !important;
  background-color: #f94877 !important;
  color: #fff !important;
}

.btn-github:hover, .btn-github:focus, .btn-github:active {
  border-color: #4078c0 !important;
  background-color: #4078c0 !important;
  color: #fff !important;
}

.btn-google:hover, .btn-google:focus, .btn-google:active {
  border-color: #ea4335 !important;
  background-color: #ea4335 !important;
  color: #fff !important;
}

.btn-hangouts:hover, .btn-hangouts:focus, .btn-hangouts:active {
  border-color: #0f9d58 !important;
  background-color: #0f9d58 !important;
  color: #fff !important;
}

.btn-instagram:hover, .btn-instagram:focus, .btn-instagram:active {
  border-color: #405de6 !important;
  background-color: #405de6 !important;
  color: #fff !important;
}

.btn-kickstarter:hover, .btn-kickstarter:focus, .btn-kickstarter:active {
  border-color: #2bde73 !important;
  background-color: #2bde73 !important;
  color: #fff !important;
}

.btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active {
  border-color: #0077b5 !important;
  background-color: #0077b5 !important;
  color: #fff !important;
}

.btn-medium:hover, .btn-medium:focus, .btn-medium:active {
  border-color: #00ab6c !important;
  background-color: #00ab6c !important;
  color: #fff !important;
}

.btn-messenger:hover, .btn-messenger:focus, .btn-messenger:active {
  border-color: #0084ff !important;
  background-color: #0084ff !important;
  color: #fff !important;
}

.btn-odnoklassniki:hover, .btn-odnoklassniki:focus, .btn-odnoklassniki:active {
  border-color: #ed812b !important;
  background-color: #ed812b !important;
  color: #fff !important;
}

.btn-patreon:hover, .btn-patreon:focus, .btn-patreon:active {
  border-color: #f96854 !important;
  background-color: #f96854 !important;
  color: #fff !important;
}

.btn-pinterest:hover, .btn-pinterest:focus, .btn-pinterest:active {
  border-color: #e60023 !important;
  background-color: #e60023 !important;
  color: #fff !important;
}

.btn-quora:hover, .btn-quora:focus, .btn-quora:active {
  border-color: #a82400 !important;
  background-color: #a82400 !important;
  color: #fff !important;
}

.btn-reddit:hover, .btn-reddit:focus, .btn-reddit:active {
  border-color: #ff4500 !important;
  background-color: #ff4500 !important;
  color: #fff !important;
}

.btn-skype:hover, .btn-skype:focus, .btn-skype:active {
  border-color: #00aff0 !important;
  background-color: #00aff0 !important;
  color: #fff !important;
}

.btn-slack:hover, .btn-slack:focus, .btn-slack:active {
  border-color: #611f69 !important;
  background-color: #611f69 !important;
  color: #fff !important;
}

.btn-snapchat:hover, .btn-snapchat:focus, .btn-snapchat:active {
  border-color: #fffc00 !important;
  background-color: #fffc00 !important;
  color: #fff !important;
}

.btn-soundcloud:hover, .btn-soundcloud:focus, .btn-soundcloud:active {
  border-color: #ff8800 !important;
  background-color: #ff8800 !important;
  color: #fff !important;
}

.btn-spotify:hover, .btn-spotify:focus, .btn-spotify:active {
  border-color: #1db954 !important;
  background-color: #1db954 !important;
  color: #fff !important;
}

.btn-stack-overflow:hover, .btn-stack-overflow:focus, .btn-stack-overflow:active {
  border-color: #f48024 !important;
  background-color: #f48024 !important;
  color: #fff !important;
}

.btn-steam:hover, .btn-steam:focus, .btn-steam:active {
  border-color: #00adee !important;
  background-color: #00adee !important;
  color: #fff !important;
}

.btn-telegram:hover, .btn-telegram:focus, .btn-telegram:active {
  border-color: #0088cc !important;
  background-color: #0088cc !important;
  color: #fff !important;
}

.btn-tiktok:hover, .btn-tiktok:focus, .btn-tiktok:active {
  border-color: #fe2c55 !important;
  background-color: #fe2c55 !important;
  color: #fff !important;
}

.btn-tinder:hover, .btn-tinder:focus, .btn-tinder:active {
  border-color: #fe3c72 !important;
  background-color: #fe3c72 !important;
  color: #fff !important;
}

.btn-trello:hover, .btn-trello:focus, .btn-trello:active {
  border-color: #0079bf !important;
  background-color: #0079bf !important;
  color: #fff !important;
}

.btn-tumblr:hover, .btn-tumblr:focus, .btn-tumblr:active {
  border-color: #35465c !important;
  background-color: #35465c !important;
  color: #fff !important;
}

.btn-twitch:hover, .btn-twitch:focus, .btn-twitch:active {
  border-color: #9146ff !important;
  background-color: #9146ff !important;
  color: #fff !important;
}

.btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active {
  border-color: #1da1f2 !important;
  background-color: #1da1f2 !important;
  color: #fff !important;
}

.btn-viber:hover, .btn-viber:focus, .btn-viber:active {
  border-color: #59267c !important;
  background-color: #59267c !important;
  color: #fff !important;
}

.btn-vimeo:hover, .btn-vimeo:focus, .btn-vimeo:active {
  border-color: #1ab7ea !important;
  background-color: #1ab7ea !important;
  color: #fff !important;
}

.btn-vk:hover, .btn-vk:focus, .btn-vk:active {
  border-color: #45668e !important;
  background-color: #45668e !important;
  color: #fff !important;
}

.btn-wechat:hover, .btn-wechat:focus, .btn-wechat:active {
  border-color: #7bb32e !important;
  background-color: #7bb32e !important;
  color: #fff !important;
}

.btn-whatsapp:hover, .btn-whatsapp:focus, .btn-whatsapp:active {
  border-color: #128c7e !important;
  background-color: #128c7e !important;
  color: #fff !important;
}

.btn-wordpress:hover, .btn-wordpress:focus, .btn-wordpress:active {
  border-color: #0087be !important;
  background-color: #0087be !important;
  color: #fff !important;
}

.btn-xing:hover, .btn-xing:focus, .btn-xing:active {
  border-color: #026466 !important;
  background-color: #026466 !important;
  color: #fff !important;
}

.btn-yelp:hover, .btn-yelp:focus, .btn-yelp:active {
  border-color: #af0606 !important;
  background-color: #af0606 !important;
  color: #fff !important;
}

.btn-youtube:hover, .btn-youtube:focus, .btn-youtube:active {
  border-color: #ff0000 !important;
  background-color: #ff0000 !important;
  color: #fff !important;
}

.btn-zoom:hover, .btn-zoom:focus, .btn-zoom:active {
  border-color: #2d8cff !important;
  background-color: #2d8cff !important;
  color: #fff !important;
}

.btn-snapchat:hover, .btn-snapchat:focus, .btn-snapchat:active {
  color: #434a57 !important;
}

.dropdown-toggle::before, .dropdown-toggle::after {
  font-family: "around-icons";
  font-size: 1.375em;
  font-weight: normal;
  vertical-align: middle !important;
  border: none !important;
  line-height: 1;
}
.dropdown-toggle::after {
  margin-right: -0.25rem;
  content: "\e937";
}
.dropdown-toggle.fs-sm::after {
  margin-top: -0.0625rem !important;
}
.dropdown-toggle.btn:not(.btn-lg)::before, .dropdown-toggle.btn:not(.btn-lg)::after {
  font-size: 1.375em;
}

.dropup .dropdown-toggle::after {
  content: "\e93a";
}

.dropstart .dropdown-toggle::before {
  margin-left: -0.3125rem;
  content: "\e938";
}

.dropend .dropdown-toggle::after {
  margin-right: -0.3125rem;
  font-size: 1.15em !important;
  content: "\e939";
}

.dropdown-toggle-split::before, .dropdown-toggle-split::after {
  margin: 0;
}

.dropdown-menu {
  --ar-dropdown-link-font-weight: 500;
  box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32);
}
.dropdown-menu li:hover > .dropdown-item,
.dropdown-menu .dropdown-item.show {
  color: var(--ar-dropdown-link-hover-color);
}
.dropdown-menu .active > .dropdown-item {
  color: var(--ar-dropdown-link-active-color);
}
.dropdown-menu .active > .dropdown-item,
.dropdown-menu .dropdown-item.active {
  pointer-events: none;
}
.dropdown-menu.w-100 {
  min-width: 100%;
}

.dropdown-menu-dark, .dark-mode .dropdown-menu:not(.ignore-dark-mode) {
  box-shadow: 0 0.4375rem 1.25rem rgba(0, 0, 0, 0.4);
}
.dropdown-menu-dark li:hover > .dropdown-item, .dark-mode .dropdown-menu:not(.ignore-dark-mode) li:hover > .dropdown-item,
.dropdown-menu-dark .dropdown-item.show,
.dark-mode .dropdown-menu:not(.ignore-dark-mode) .dropdown-item.show {
  color: var(--ar-dropdown-link-hover-color);
}
.dropdown-menu-dark .active > .dropdown-item, .dark-mode .dropdown-menu:not(.ignore-dark-mode) .active > .dropdown-item {
  color: var(--ar-dropdown-link-active-color);
}

.dropdown-item {
  font-weight: var(--ar-dropdown-link-font-weight);
}
.dropdown-item [class^=opacity-], .dropdown-item [class*=" opacity-"] {
  transition: opacity 0.2s ease-in-out;
}
.dropdown-item:hover *, .dropdown-item.active *, .dropdown-item.show * {
  opacity: 1 !important;
}

li:hover > .dropdown-item *,
li.active > .dropdown-item * {
  opacity: 1 !important;
}

.dropdown-divider {
  margin-right: var(--ar-dropdown-item-padding-x);
  margin-left: var(--ar-dropdown-item-padding-x);
}

hr.dropdown-divider {
  border: 0;
  height: var(--ar-dropdown-border-width);
  background-color: var(--ar-dropdown-divider-bg);
}

.dropdown-menu-dark hr.dropdown-divider, .dark-mode .dropdown-menu:not(.ignore-dark-mode) hr.dropdown-divider {
  border: 0;
  background-color: var(--ar-dropdown-divider-bg);
}

.dropdown-menu.show {
  animation: fade-in 0.2s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.nav {
  --ar-nav-link-active-color: var(--ar-primary);
}

.nav-item {
  margin-bottom: 0;
}

.nav-link {
  display: flex;
  align-items: center;
  background: none;
  border: 0;
}
.nav-link [class^=opacity-], .nav-link [class*=" opacity-"] {
  transition: opacity 0.2s ease-in-out;
}
.nav-link:hover *, .nav-link.active * {
  opacity: 1 !important;
}
.nav-link.active {
  color: var(--ar-nav-link-active-color);
}
.nav-link.active:not([data-bs-toggle=dropdown]) {
  pointer-events: none;
  cursor: default;
}
.nav-link.text-muted:hover, .nav-link.text-muted:active {
  color: var(--ar-gray-800) !important;
}

.dropdown:hover > .nav-link *,
.nav-item:hover > .nav-link *,
.dropdown.active > .nav-link *,
.nav-item.active > .nav-link * {
  opacity: 1 !important;
}

.nav-tabs.nav-fill .nav-link, .nav-tabs.nav-justified .nav-link,
.nav-pills.nav-fill .nav-link,
.nav-pills.nav-justified .nav-link {
  justify-content: center;
}
.nav-tabs .dropdown-menu,
.nav-pills .dropdown-menu {
  border-top-left-radius: var(--ar-dropdown-border-radius);
  border-top-right-radius: var(--ar-dropdown-border-radius);
}

.nav-tabs {
  margin-bottom: 1rem;
}
.nav-tabs .nav-item {
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
}
.nav-tabs .nav-item:last-child {
  margin-right: 0;
}
.nav-tabs.justify-content-center .nav-item {
  margin-right: 0.1875rem;
  margin-left: 0.1875rem;
}
.nav-tabs.justify-content-end .nav-item {
  margin-right: 0;
  margin-left: 0.375rem;
}
.nav-tabs.flex-column .nav-item {
  margin-right: 0;
  margin-left: 0;
}
.nav-tabs.flex-column .nav-item:last-child {
  margin-bottom: 0;
}
.nav-tabs .nav-link {
  border-radius: var(--ar-nav-tabs-border-radius);
  font-weight: 600;
}

.nav-pills {
  margin-bottom: 0.625rem;
}
.nav-pills .nav-item {
  margin-right: 0.875rem;
  margin-bottom: 0.875rem;
}
.nav-pills .nav-item:last-child {
  margin-right: 0;
}
.nav-pills.justify-content-center .nav-item {
  margin-right: 0.4375rem;
  margin-left: 0.4375rem;
}
.nav-pills.justify-content-end .nav-item {
  margin-right: 0;
  margin-left: 1rem;
}
.nav-pills.flex-column .nav-item {
  margin-right: 0;
  margin-left: 0;
}
.nav-pills.flex-column .nav-item:last-child {
  margin-bottom: 0;
}
.nav-pills .nav-link {
  font-weight: 600;
}

.navbar-brand {
  --ar-navbar-brand-padding-y: 0.5rem;
  --ar-navbar-brand-margin-end: 1rem;
  --ar-navbar-brand-font-size: 1.375rem;
  --ar-navbar-brand-font-weight: 600;
  display: flex;
  align-items: center;
  padding: calc(var(--ar-navbar-brand-padding-y) * 0.5) 0;
  font-weight: var(--ar-navbar-brand-font-weight);
}

.navbar-collapse {
  margin-top: 1rem;
  border: 1px solid transparent;
  border-radius: var(--ar-border-radius-lg);
}

.navbar-nav {
  --ar-nav-link-font-weight: 500;
  --ar-mega-dropdown-column-width: 14rem;
  padding: 0.75rem 1.25rem;
}
.navbar-nav .nav-link {
  padding-top: calc(var(--ar-nav-link-padding-y) * 0.75);
  padding-bottom: calc(var(--ar-nav-link-padding-y) * 0.75);
}

.navbar-toggler {
  position: relative;
  width: 1.875rem;
  height: 2.25rem;
}

.navbar-toggler-icon {
  display: block;
  top: 50%;
  margin-top: -0.0625rem;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.navbar-toggler-icon, .navbar-toggler-icon::before, .navbar-toggler-icon::after {
  position: absolute;
  width: 1.375rem;
  height: 0.125rem;
  transition-property: transform;
  background-color: var(--ar-navbar-toggler-color);
}
.navbar-toggler-icon::before, .navbar-toggler-icon::after {
  display: block;
  width: 0.9625rem;
  content: "";
}
.navbar-toggler-icon:after {
  right: 0;
}
.navbar-toggler-icon::before {
  top: -0.4375rem;
  transition: top 0.075s 0.12s ease, opacity 0.075s ease, width 0.15s ease-in-out;
}
.navbar-toggler-icon::after {
  bottom: -0.4375rem;
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.15s ease-in-out;
}

.navbar-toggler:hover .navbar-toggler-icon, .navbar-toggler:hover .navbar-toggler-icon::before, .navbar-toggler:hover .navbar-toggler-icon::after {
  width: 1.375rem;
}

[aria-expanded=true] .navbar-toggler-icon {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
[aria-expanded=true] .navbar-toggler-icon::before, [aria-expanded=true] .navbar-toggler-icon::after {
  width: 1.375rem;
}
[aria-expanded=true] .navbar-toggler-icon::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
[aria-expanded=true] .navbar-toggler-icon::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.navbar .dropdown-toggle::after {
  margin-top: 0.0625rem;
  font-size: 1.25em;
}
.navbar .navbar-nav .dropdown-menu {
  padding-top: calc(var(--ar-dropdown-padding-y) * 0.25);
  padding-bottom: calc(var(--ar-dropdown-padding-y) * 0.5);
  border: 0;
  border-left: var(--ar-dropdown-border-width, 1px) solid var(--ar-border-color);
  border-radius: 0;
  background-color: transparent;
  background: none;
  box-shadow: none;
}
.navbar .navbar-nav .dropdown-menu.dropdown-menu-dark, .navbar .navbar-nav .dark-mode .dropdown-menu:not(.ignore-dark-mode), .dark-mode .navbar .navbar-nav .dropdown-menu:not(.ignore-dark-mode) {
  --ar-border-color: rgba(255, 255, 255, 0.13);
}
.navbar .navbar-nav .dropdown-menu .dropdown-menu {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.navbar .navbar-nav .dropdown-menu .dropdown-toggle::after {
  margin-top: -0.0625rem;
  margin-right: 0;
  margin-left: 0.125rem;
}
.navbar .navbar-nav .dropdown-menu .dropdown-divider {
  display: none;
}
.navbar .navbar-nav .dropdown-menu[data-bs-popper] {
  margin-top: 0;
}

@keyframes dropdown-show {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-brand {
    padding: var(--ar-navbar-brand-padding-y) 0;
  }
  .navbar-expand-sm .navbar-collapse {
    margin-top: 0;
    border: 0 !important;
    background-color: transparent !important;
  }
  .navbar-expand-sm .navbar-nav {
    padding: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-top: var(--ar-nav-link-padding-y);
    padding-bottom: var(--ar-nav-link-padding-y);
  }
  .navbar-expand-sm .offcanvas {
    background-color: transparent !important;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .dropdown-divider {
    display: block;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    padding-top: var(--ar-dropdown-padding-y);
    padding-bottom: var(--ar-dropdown-padding-y);
    margin-top: var(--ar-dropdown-spacer) !important;
    border: var(--ar-dropdown-border-width) solid var(--ar-dropdown-border-color);
    border-radius: var(--ar-dropdown-border-radius);
    background-color: var(--ar-dropdown-bg);
    box-shadow: var(--ar-dropdown-box-shadow);
  }
  .navbar-expand-sm .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-sm .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }
  .navbar-expand-sm .dropdown:hover > .dropdown-menu {
    display: block;
    animation: dropdown-show 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar-expand-sm .dropdown .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .navbar-expand-sm .dropdown .dropdown .dropdown-toggle::after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.375em;
    content: "\e939";
  }
  .navbar-expand-sm .dropdown .dropdown .dropdown-menu {
    top: calc((var(--ar-dropdown-item-padding-y) + var(--ar-dropdown-padding-y)) * -1);
    left: 100%;
    margin-right: 0;
    margin-left: -0.375rem;
  }
  .navbar-expand-sm .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-expand-sm .mega-dropdown-column {
    width: var(--ar-mega-dropdown-column-width);
    padding: 0 0.5rem;
  }
  .navbar-expand-sm .mega-dropdown-column li:hover > .mega-dropdown-column.opacity-0 {
    opacity: 100% !important;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-brand {
    padding: var(--ar-navbar-brand-padding-y) 0;
  }
  .navbar-expand-md .navbar-collapse {
    margin-top: 0;
    border: 0 !important;
    background-color: transparent !important;
  }
  .navbar-expand-md .navbar-nav {
    padding: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: var(--ar-nav-link-padding-y);
    padding-bottom: var(--ar-nav-link-padding-y);
  }
  .navbar-expand-md .offcanvas {
    background-color: transparent !important;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .dropdown-divider {
    display: block;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    padding-top: var(--ar-dropdown-padding-y);
    padding-bottom: var(--ar-dropdown-padding-y);
    margin-top: var(--ar-dropdown-spacer) !important;
    border: var(--ar-dropdown-border-width) solid var(--ar-dropdown-border-color);
    border-radius: var(--ar-dropdown-border-radius);
    background-color: var(--ar-dropdown-bg);
    box-shadow: var(--ar-dropdown-box-shadow);
  }
  .navbar-expand-md .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-md .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }
  .navbar-expand-md .dropdown:hover > .dropdown-menu {
    display: block;
    animation: dropdown-show 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar-expand-md .dropdown .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .navbar-expand-md .dropdown .dropdown .dropdown-toggle::after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.375em;
    content: "\e939";
  }
  .navbar-expand-md .dropdown .dropdown .dropdown-menu {
    top: calc((var(--ar-dropdown-item-padding-y) + var(--ar-dropdown-padding-y)) * -1);
    left: 100%;
    margin-right: 0;
    margin-left: -0.375rem;
  }
  .navbar-expand-md .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-expand-md .mega-dropdown-column {
    width: var(--ar-mega-dropdown-column-width);
    padding: 0 0.5rem;
  }
  .navbar-expand-md .mega-dropdown-column li:hover > .mega-dropdown-column.opacity-0 {
    opacity: 100% !important;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-brand {
    padding: var(--ar-navbar-brand-padding-y) 0;
  }
  .navbar-expand-lg .navbar-collapse {
    margin-top: 0;
    border: 0 !important;
    background-color: transparent !important;
  }
  .navbar-expand-lg .navbar-nav {
    padding: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: var(--ar-nav-link-padding-y);
    padding-bottom: var(--ar-nav-link-padding-y);
  }
  .navbar-expand-lg .offcanvas {
    background-color: transparent !important;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .dropdown-divider {
    display: block;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    padding-top: var(--ar-dropdown-padding-y);
    padding-bottom: var(--ar-dropdown-padding-y);
    margin-top: var(--ar-dropdown-spacer) !important;
    border: var(--ar-dropdown-border-width) solid var(--ar-dropdown-border-color);
    border-radius: var(--ar-dropdown-border-radius);
    background-color: var(--ar-dropdown-bg);
    box-shadow: var(--ar-dropdown-box-shadow);
  }
  .navbar-expand-lg .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-lg .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }
  .navbar-expand-lg .dropdown:hover > .dropdown-menu {
    display: block;
    animation: dropdown-show 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar-expand-lg .dropdown .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .navbar-expand-lg .dropdown .dropdown .dropdown-toggle::after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.375em;
    content: "\e939";
  }
  .navbar-expand-lg .dropdown .dropdown .dropdown-menu {
    top: calc((var(--ar-dropdown-item-padding-y) + var(--ar-dropdown-padding-y)) * -1);
    left: 100%;
    margin-right: 0;
    margin-left: -0.375rem;
  }
  .navbar-expand-lg .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-expand-lg .mega-dropdown-column {
    width: var(--ar-mega-dropdown-column-width);
    padding: 0 0.5rem;
  }
  .navbar-expand-lg .mega-dropdown-column li:hover > .mega-dropdown-column.opacity-0 {
    opacity: 100% !important;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-brand {
    padding: var(--ar-navbar-brand-padding-y) 0;
  }
  .navbar-expand-xl .navbar-collapse {
    margin-top: 0;
    border: 0 !important;
    background-color: transparent !important;
  }
  .navbar-expand-xl .navbar-nav {
    padding: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-top: var(--ar-nav-link-padding-y);
    padding-bottom: var(--ar-nav-link-padding-y);
  }
  .navbar-expand-xl .offcanvas {
    background-color: transparent !important;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .dropdown-divider {
    display: block;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    padding-top: var(--ar-dropdown-padding-y);
    padding-bottom: var(--ar-dropdown-padding-y);
    margin-top: var(--ar-dropdown-spacer) !important;
    border: var(--ar-dropdown-border-width) solid var(--ar-dropdown-border-color);
    border-radius: var(--ar-dropdown-border-radius);
    background-color: var(--ar-dropdown-bg);
    box-shadow: var(--ar-dropdown-box-shadow);
  }
  .navbar-expand-xl .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-xl .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }
  .navbar-expand-xl .dropdown:hover > .dropdown-menu {
    display: block;
    animation: dropdown-show 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar-expand-xl .dropdown .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .navbar-expand-xl .dropdown .dropdown .dropdown-toggle::after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.375em;
    content: "\e939";
  }
  .navbar-expand-xl .dropdown .dropdown .dropdown-menu {
    top: calc((var(--ar-dropdown-item-padding-y) + var(--ar-dropdown-padding-y)) * -1);
    left: 100%;
    margin-right: 0;
    margin-left: -0.375rem;
  }
  .navbar-expand-xl .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-expand-xl .mega-dropdown-column {
    width: var(--ar-mega-dropdown-column-width);
    padding: 0 0.5rem;
  }
  .navbar-expand-xl .mega-dropdown-column li:hover > .mega-dropdown-column.opacity-0 {
    opacity: 100% !important;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-brand {
    padding: var(--ar-navbar-brand-padding-y) 0;
  }
  .navbar-expand-xxl .navbar-collapse {
    margin-top: 0;
    border: 0 !important;
    background-color: transparent !important;
  }
  .navbar-expand-xxl .navbar-nav {
    padding: 0;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-top: var(--ar-nav-link-padding-y);
    padding-bottom: var(--ar-nav-link-padding-y);
  }
  .navbar-expand-xxl .offcanvas {
    background-color: transparent !important;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .dropdown-divider {
    display: block;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    padding-top: var(--ar-dropdown-padding-y);
    padding-bottom: var(--ar-dropdown-padding-y);
    margin-top: var(--ar-dropdown-spacer) !important;
    border: var(--ar-dropdown-border-width) solid var(--ar-dropdown-border-color);
    border-radius: var(--ar-dropdown-border-radius);
    background-color: var(--ar-dropdown-bg);
    box-shadow: var(--ar-dropdown-box-shadow);
  }
  .navbar-expand-xxl .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-xxl .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }
  .navbar-expand-xxl .dropdown:hover > .dropdown-menu {
    display: block;
    animation: dropdown-show 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar-expand-xxl .dropdown .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .navbar-expand-xxl .dropdown .dropdown .dropdown-toggle::after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.375em;
    content: "\e939";
  }
  .navbar-expand-xxl .dropdown .dropdown .dropdown-menu {
    top: calc((var(--ar-dropdown-item-padding-y) + var(--ar-dropdown-padding-y)) * -1);
    left: 100%;
    margin-right: 0;
    margin-left: -0.375rem;
  }
  .navbar-expand-xxl .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-expand-xxl .mega-dropdown-column {
    width: var(--ar-mega-dropdown-column-width);
    padding: 0 0.5rem;
  }
  .navbar-expand-xxl .mega-dropdown-column li:hover > .mega-dropdown-column.opacity-0 {
    opacity: 100% !important;
  }
}
.navbar-expand .navbar-brand {
  padding: var(--ar-navbar-brand-padding-y) 0;
}
.navbar-expand .navbar-collapse {
  margin-top: 0;
  border: 0 !important;
  background-color: transparent !important;
}
.navbar-expand .navbar-nav {
  padding: 0;
}
.navbar-expand .navbar-nav .nav-link {
  padding-top: var(--ar-nav-link-padding-y);
  padding-bottom: var(--ar-nav-link-padding-y);
}
.navbar-expand .offcanvas {
  background-color: transparent !important;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .dropdown-divider {
  display: block;
}
.navbar-expand .navbar-nav .dropdown-menu {
  padding-top: var(--ar-dropdown-padding-y);
  padding-bottom: var(--ar-dropdown-padding-y);
  margin-top: var(--ar-dropdown-spacer) !important;
  border: var(--ar-dropdown-border-width) solid var(--ar-dropdown-border-color);
  border-radius: var(--ar-dropdown-border-radius);
  background-color: var(--ar-dropdown-bg);
  box-shadow: var(--ar-dropdown-box-shadow);
}
.navbar-expand .dropdown-menu.show {
  display: none;
}
.navbar-expand .dropdown::before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0.375rem;
  content: "";
}
.navbar-expand .dropdown:hover > .dropdown-menu {
  display: block;
  animation: dropdown-show 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.navbar-expand .dropdown .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
}
.navbar-expand .dropdown .dropdown .dropdown-toggle::after {
  margin-top: 0;
  margin-right: -0.3125rem;
  margin-left: auto;
  font-size: 1.375em;
  content: "\e939";
}
.navbar-expand .dropdown .dropdown .dropdown-menu {
  top: calc((var(--ar-dropdown-item-padding-y) + var(--ar-dropdown-padding-y)) * -1);
  left: 100%;
  margin-right: 0;
  margin-left: -0.375rem;
}
.navbar-expand .dropdown .dropdown-menu-end {
  left: auto;
  right: 0;
}
.navbar-expand .mega-dropdown-column {
  width: var(--ar-mega-dropdown-column-width);
  padding: 0 0.5rem;
}
.navbar-expand .mega-dropdown-column li:hover > .mega-dropdown-column.opacity-0 {
  opacity: 100% !important;
}

.navbar {
  --ar-navbar-stuck-bg: var(--ar-light);
  --ar-navbar-toggler-color: var(--ar-gray-800);
  --ar-navbar-collapse-bg: var(--ar-light);
  --ar-navbar-collapse-border-color: var(--ar-border-color);
  transition: background-color 0.1s ease-in-out;
}
.navbar.navbar-stuck {
  background-color: var(--ar-navbar-stuck-bg);
}
.navbar .navbar-collapse {
  border-color: var(--ar-navbar-collapse-border-color);
  background-color: var(--ar-navbar-collapse-bg);
}
.navbar .nav-item:hover > .nav-link:not(.disabled),
.navbar .nav-item .nav-link.show:not(.disabled) {
  color: var(--ar-navbar-hover-color);
}
.navbar .nav-item.active > .nav-link:not(.disabled) {
  color: var(--ar-navbar-active-color);
}
.navbar .dropdown:hover > .nav-link {
  color: var(--ar-navbar-hover-color);
}

.navbar-dark {
  --ar-navbar-stuck-bg: var(--ar-dark);
  --ar-navbar-toggler-color: rgba(255, 255, 255, 0.9);
  --ar-navbar-collapse-bg: var(--ar-dark);
  --ar-navbar-collapse-border-color: rgba(255, 255, 255, 0.13);
}

.card-header,
.card-footer {
  width: calc(100% - var(--ar-card-spacer-x) * 2);
  margin: auto;
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 450px) {
  .card-header,
  .card-footer {
    width: calc(100% - var(--ar-card-spacer-x) * 0.75 * 2);
  }
  .card-body {
    padding: calc(var(--ar-card-spacer-y) * 0.75) calc(var(--ar-card-spacer-x) * 0.75);
  }
}
.card-title {
  margin-top: -0.25rem;
}

.card-text {
  margin-bottom: 1.5rem;
}

.card-header-tabs,
.card-header-pills {
  margin: 0;
}
.card-header-tabs .nav-item,
.card-header-pills .nav-item {
  margin-bottom: 0;
}

.card-header-tabs .nav-link.active {
  border-bottom-color: var(--ar-nav-tabs-link-active-border-color);
  background-color: transparent;
}

.card-body > .list-group {
  border-top: var(--ar-list-group-border-width) solid var(--ar-list-group-border-color);
  border-bottom: var(--ar-list-group-border-width) solid var(--ar-list-group-border-color);
}

[class^=rounded-].overflow-hidden,
[class*=" rounded-"].overflow-hidden {
  transform: translateZ(0);
}

.card-hover {
  position: relative;
}
.card-hover .opacity-0,
.card-hover .opacity-100 {
  transition: opacity 0.3s ease-in-out;
}
.card-hover .card-lifted, .card-hover.card-lifted {
  transition: transform 0.25s ease-in-out;
}
.card-hover:hover .opacity-0 {
  opacity: 1 !important;
}
.card-hover:hover .opacity-100 {
  opacity: 0 !important;
}
.card-hover:hover .card-lifted, .card-hover:hover.card-lifted {
  transform: translateY(-0.3rem);
}

.card-hover-primary {
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.card-hover-primary .card-title,
.card-hover-primary .card-text {
  transition: color 0.25s ease-in-out;
}
.card-hover-primary:hover {
  --ar-heading-color: #fff;
  --ar-body-color: rgba(255, 255, 255, .7);
  border-color: var(--ar-primary) !important;
  background-color: var(--ar-primary) !important;
}
.card-hover-primary:hover .card-title {
  color: var(--ar-heading-color);
}
.card-flip {
  display: block;
  height: 100%;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  perspective: 1000px;
  text-decoration: none;
}

.card-flip-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
}

.card-flip-front,
.card-flip-back {
  height: 100%;
  padding: 1.5rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateX(0deg);
}

.card-flip-front {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-flip-back {
  position: relative;
  transform: rotateY(180deg);
  background-color: #fff;
}

.card-flip:hover .card-flip-inner {
  transform: rotateY(180deg);
}

.message-box-start,
.message-box-end {
  width: 100%;
  padding: 1rem;
  background-color: var(--ar-gray-200);
}

.message-box-start {
  border-top-left-radius: var(--ar-border-radius-lg);
  border-top-right-radius: var(--ar-border-radius-lg);
  border-bottom-right-radius: var(--ar-border-radius-lg);
}

.message-box-end {
  border-top-left-radius: var(--ar-border-radius-lg);
  border-top-right-radius: var(--ar-border-radius-lg);
  border-bottom-left-radius: var(--ar-border-radius-lg);
}

.accordion {
  --ar-accordion-color: var(--ar-body-color);
  --ar-accordion-spacer: 1rem;
  --ar-accordion-btn-color: #434a57;
  --ar-accordion-btn-font-size: var(--ar-body-font-size);
  --ar-accordion-btn-font-weight: 600;
}

.accordion-item {
  border-radius: var(--ar-accordion-border-radius) !important;
}
.accordion-item:not(:last-child) {
  margin-bottom: var(--ar-accordion-spacer);
}

.accordion-button {
  border-radius: var(--ar-accordion-border-radius) !important;
  font-size: var(--ar-accordion-btn-font-size);
  font-weight: var(--ar-accordion-btn-font-weight);
  line-height: 1.5;
}

.accordion-body {
  padding-top: 0;
}

.accordion-alt {
  --ar-accordion-btn-font-size: 1.75rem;
}
.accordion-alt .accordion-item {
  border-radius: 0 !important;
  background: none !important;
}
.accordion-alt .accordion-item:not(:last-child) {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .accordion-alt .accordion-item:not(:last-child) {
    margin-bottom: calc(var(--ar-accordion-spacer) * 0.25);
  }
}
.accordion-alt .accordion-button {
  padding-top: calc(var(--ar-accordion-spacer) * 0.75);
  padding-right: 0;
  padding-bottom: var(--ar-accordion-spacer);
  padding-left: 0;
}
.accordion-alt .accordion-button::after {
  width: 0.5em;
  height: 0.5em;
  background-size: 0.5em;
}
.accordion-alt .accordion-body {
  padding-right: 0;
  padding-left: 0;
}

.accordion-orders .accordion-button::after {
  margin-right: 0.25rem;
  margin-left: 0.5rem;
}
.accordion-orders .accordion-button .accordion-button-img {
  transition: opacity 0.15s ease-in-out;
  opacity: 0;
}
.accordion-orders .accordion-button.collapsed .accordion-button-img {
  opacity: 1;
}

.breadcrumb {
  --ar-breadcrumb-color: var(--ar-gray-600);
  --ar-breadcrumb-font-weight: 500;
  --ar-breadcrumb-divider-font-size: 1.2em;
  --ar-breadcrumb-item-hover-color: var(--ar-gray-900);
  font-weight: var(--ar-breadcrumb-font-weight);
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}
.breadcrumb-item + .breadcrumb-item::before {
  margin-top: 0.15em;
  font-family: "around-icons";
  font-weight: normal;
  font-size: var(--ar-breadcrumb-divider-font-size);
  line-height: 1;
}
.breadcrumb-item > a {
  display: flex;
  align-items: center;
  color: var(--ar-breadcrumb-color);
  text-decoration: none;
}
.breadcrumb-item:hover > a {
  color: var(--ar-breadcrumb-item-hover-color);
}

.pagination {
  margin-bottom: 0;
}

.page-item {
  font-weight: 500;
}

.badge.bg-secondary,
.badge.bg-light {
  --ar-badge-color: var(--ar-gray-800);
}

.badge.bg-secondary {
  background-color: var(--ar-secondary) !important;
}

a.badge {
  text-decoration: none;
}

.alert {
  --ar-alert-link-color: inherit;
}
.alert .alert-link {
  color: var(--ar-alert-link-color);
}

.alert > [class^=ai-],
.alert > [class*=" ai-"] {
  margin-top: 0.125rem;
}

.alert-secondary {
  --ar-alert-bg: #f4f6f9;
}

.alert-secondary,
.alert-light {
  --ar-alert-color: #434a57;
  --ar-alert-border-color: var(--ar-border-color);
  --ar-alert-link-color: #121519;
}

.alert-dark {
  --ar-alert-border-color: #121519;
  --ar-alert-bg: #121519;
  --ar-alert-color: rgba(255, 255, 255, .8);
  --ar-alert-link-color: #fff;
}

.alert-dismissible .btn-close {
  padding: calc(var(--ar-alert-padding-y) * 1.125) var(--ar-alert-padding-x);
}

.alert-primary .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath d='M8 1.2A6.74 6.74 0 0 0 1.2 8 6.74 6.74 0 0 0 8 14.8 6.74 6.74 0 0 0 14.8 8 6.74 6.74 0 0 0 8 1.2zM0 8c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm10.6-2.6a.61.61 0 0 1 0 .8L8.8 8l1.9 1.9a.61.61 0 0 1 0 .8.61.61 0 0 1-.8 0L8 8.8l-1.9 1.9a.61.61 0 0 1-.8 0 .61.61 0 0 1 0-.8L7.2 8 5.4 6.1a.61.61 0 0 1 0-.8.61.61 0 0 1 .8 0l1.9 1.9L10 5.3c.1-.1.4-.1.6.1z' fill-rule='evenodd' fill='%23264e41'/%3e%3c/svg%3e") center/1.375em auto no-repeat;
}

.alert-success .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath d='M8 1.2A6.74 6.74 0 0 0 1.2 8 6.74 6.74 0 0 0 8 14.8 6.74 6.74 0 0 0 14.8 8 6.74 6.74 0 0 0 8 1.2zM0 8c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm10.6-2.6a.61.61 0 0 1 0 .8L8.8 8l1.9 1.9a.61.61 0 0 1 0 .8.61.61 0 0 1-.8 0L8 8.8l-1.9 1.9a.61.61 0 0 1-.8 0 .61.61 0 0 1 0-.8L7.2 8 5.4 6.1a.61.61 0 0 1 0-.8.61.61 0 0 1 .8 0l1.9 1.9L10 5.3c.1-.1.4-.1.6.1z' fill-rule='evenodd' fill='%2325885f'/%3e%3c/svg%3e") center/1.375em auto no-repeat;
}

.alert-info .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath d='M8 1.2A6.74 6.74 0 0 0 1.2 8 6.74 6.74 0 0 0 8 14.8 6.74 6.74 0 0 0 14.8 8 6.74 6.74 0 0 0 8 1.2zM0 8c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm10.6-2.6a.61.61 0 0 1 0 .8L8.8 8l1.9 1.9a.61.61 0 0 1 0 .8.61.61 0 0 1-.8 0L8 8.8l-1.9 1.9a.61.61 0 0 1-.8 0 .61.61 0 0 1 0-.8L7.2 8 5.4 6.1a.61.61 0 0 1 0-.8.61.61 0 0 1 .8 0l1.9 1.9L10 5.3c.1-.1.4-.1.6.1z' fill-rule='evenodd' fill='%23255388'/%3e%3c/svg%3e") center/1.375em auto no-repeat;
}

.alert-warning .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath d='M8 1.2A6.74 6.74 0 0 0 1.2 8 6.74 6.74 0 0 0 8 14.8 6.74 6.74 0 0 0 14.8 8 6.74 6.74 0 0 0 8 1.2zM0 8c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm10.6-2.6a.61.61 0 0 1 0 .8L8.8 8l1.9 1.9a.61.61 0 0 1 0 .8.61.61 0 0 1-.8 0L8 8.8l-1.9 1.9a.61.61 0 0 1-.8 0 .61.61 0 0 1 0-.8L7.2 8 5.4 6.1a.61.61 0 0 1 0-.8.61.61 0 0 1 .8 0l1.9 1.9L10 5.3c.1-.1.4-.1.6.1z' fill-rule='evenodd' fill='%23cca515'/%3e%3c/svg%3e") center/1.375em auto no-repeat;
}

.alert-danger .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath d='M8 1.2A6.74 6.74 0 0 0 1.2 8 6.74 6.74 0 0 0 8 14.8 6.74 6.74 0 0 0 14.8 8 6.74 6.74 0 0 0 8 1.2zM0 8c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm10.6-2.6a.61.61 0 0 1 0 .8L8.8 8l1.9 1.9a.61.61 0 0 1 0 .8.61.61 0 0 1-.8 0L8 8.8l-1.9 1.9a.61.61 0 0 1-.8 0 .61.61 0 0 1 0-.8L7.2 8 5.4 6.1a.61.61 0 0 1 0-.8.61.61 0 0 1 .8 0l1.9 1.9L10 5.3c.1-.1.4-.1.6.1z' fill-rule='evenodd' fill='%23cc1515'/%3e%3c/svg%3e") center/1.375em auto no-repeat;
}

.list-group-item-action {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  color: var(--ar-list-group-action-color);
  font-weight: 500;
}
.list-group-item-action:active {
  transition: none;
}

.list-group-flush .list-group-item {
  padding-right: 0;
  padding-left: 0;
}

.toast {
  --ar-toast-header-font-size: 1rem;
}

.toast-header {
  position: relative;
  border: 0;
  font-size: var(--ar-toast-header-font-size);
  font-weight: var(--ar-heading-font-weight);
}
.toast-header::after {
  position: absolute;
  left: var(--ar-toast-padding-x);
  bottom: calc(var(--ar-toast-border-width) * -1);
  width: calc(100% - var(--ar-toast-padding-x) * 2);
  height: var(--ar-toast-border-width);
  background-color: var(--ar-toast-header-border-color);
  content: "";
}
.toast-header[class^=bg-]::after, .toast-header[class*=" bg-"]::after {
  display: none;
}
.toast-header .btn-close {
  font-size: 0.875em;
  margin-right: 0;
  padding-right: 0;
}

.toast-body {
  padding-top: 0.9625rem;
}

.modal-content {
  padding-right: var(--ar-modal-padding);
  padding-left: var(--ar-modal-padding);
}

.modal-header,
.modal-footer {
  padding: var(--ar-modal-header-padding-y) 0;
}

.modal-body {
  margin-right: calc(var(--ar-modal-padding) * -1);
  margin-left: calc(var(--ar-modal-padding) * -1);
}

@media (max-width: 450px) {
  .modal-header,
  .modal-footer {
    padding: calc(var(--ar-modal-header-padding-y) * 0.875) 0;
  }
  .modal-content {
    padding-right: calc(var(--ar-modal-padding) * 0.75);
    padding-left: calc(var(--ar-modal-padding) * 0.75);
  }
  .modal-body {
    padding: calc(var(--ar-modal-padding) * 0.75);
    margin-right: calc(var(--ar-modal-padding) * -0.75);
    margin-left: calc(var(--ar-modal-padding) * -0.75);
  }
}
.popover {
  --ar-popover-arrow-border: var(--ar-border-color);
  box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32);
}

.popover-header {
  width: calc(100% - var(--ar-popover-header-padding-x) * 2);
  margin: auto;
  padding-right: 0;
  padding-left: 0;
  border-bottom-color: var(--ar-border-color);
}

.offcanvas-header {
  padding: calc(var(--ar-offcanvas-padding-y) * 0.75) var(--ar-offcanvas-padding-x);
}

.offcanvas-body {
  display: block !important;
}

.offcanvas.show, .offcanvas-sm.show, .offcanvas-md.show,
.offcanvas-lg.show, .offcanvas-xl.show, .offcanvas-xxl.show {
  box-shadow: var(--ar-offcanvas-box-shadow);
}

[data-simplebar] {
  --ar-scrollbar-width: 0.25rem;
  --ar-scrollbar-bg: #c5cad1;
  --ar-scrollbar-border-radius: var(--ar-border-radius);
  --ar-scrollbar-track-width: 0.125rem;
  --ar-scrollbar-track-bg: #e9eef3;
}

.simplebar-scrollbar::before {
  right: 0;
  left: 0;
  transition: none;
  background-color: var(--ar-scrollbar-bg);
  border-radius: var(--ar-scrollbar-border-radius);
  opacity: 1 !important;
}

.simplebar-track {
  transition: opacity 0.15s ease-in-out;
  border-radius: var(--ar-scrollbar-border-radius);
  background-color: var(--ar-scrollbar-track-bg);
  overflow: initial;
  opacity: 0;
}
.simplebar-track.simplebar-vertical {
  right: calc(var(--ar-scrollbar-width) * 0.5);
  width: var(--ar-scrollbar-track-width);
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar {
  left: 50%;
  width: var(--ar-scrollbar-width);
  margin-left: calc(var(--ar-scrollbar-width) * -0.5);
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 0;
  bottom: 0;
}
.simplebar-track.simplebar-horizontal {
  bottom: calc(var(--ar-scrollbar-width) * 0.5);
  height: var(--ar-scrollbar-track-width);
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  height: var(--ar-scrollbar-width);
  top: 50%;
  margin-top: calc(var(--ar-scrollbar-width) * -0.5);
  bottom: 0;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
  right: 0;
  left: 0;
}

.simplebar-wrapper:hover ~ .simplebar-track,
[data-simplebar-auto-hide=false] .simplebar-track {
  opacity: 1;
}

.masonry-grid[data-columns] {
  overflow: hidden;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.masonry-grid[data-columns]::after {
  display: block;
  clear: both;
  content: "";
}
.masonry-grid[data-columns] .masonry-grid-item {
  width: 100%;
  float: left;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 1.5rem;
}

.masonry-grid[data-columns="1"] .masonry-grid-item {
  width: 100%;
}

@media (min-width: 576px) {
  .masonry-grid[data-columns="2"] .masonry-grid-item,
  .masonry-grid[data-columns="3"] .masonry-grid-item,
  .masonry-grid[data-columns="4"] .masonry-grid-item,
  .masonry-grid[data-columns="5"] .masonry-grid-item,
  .masonry-grid[data-columns="6"] .masonry-grid-item {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .masonry-grid[data-columns="4"] .masonry-grid-item,
  .masonry-grid[data-columns="5"] .masonry-grid-item,
  .masonry-grid[data-columns="6"] .masonry-grid-item {
    width: 33.333%;
  }
}
@media (min-width: 992px) {
  .masonry-grid[data-columns="3"] .masonry-grid-item,
  .masonry-grid[data-columns="4"] .masonry-grid-item,
  .masonry-grid[data-columns="5"] .masonry-grid-item,
  .masonry-grid[data-columns="6"] .masonry-grid-item {
    width: 33.333%;
  }
}
@media (min-width: 1200px) {
  .masonry-grid[data-columns="4"] .masonry-grid-item,
  .masonry-grid[data-columns="5"] .masonry-grid-item,
  .masonry-grid[data-columns="6"] .masonry-grid-item {
    width: 25%;
  }
}
@media (min-width: 1400px) {
  .masonry-grid[data-columns="5"] .masonry-grid-item {
    width: 20%;
  }
  .masonry-grid[data-columns="6"] .masonry-grid-item {
    width: 16.666%;
  }
}
.btn-prev, .btn-next {
  transition: all 0.25s ease-in-out;
}
.btn-prev > [class^=ai-],
.btn-prev > [class*=" ai-"], .btn-next > [class^=ai-],
.btn-next > [class*=" ai-"] {
  font-size: 1.4em;
}

.swiper-button-disabled,
.swiper-button-disabled:disabled {
  opacity: 0.55;
}

.swiper .btn-prev:not(.position-static),
.swiper .btn-next:not(.position-static) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}
.swiper .btn-prev {
  left: 1.25rem;
}
.swiper .btn-next {
  right: 1.25rem;
}

.swiper-nav-onhover .btn-prev,
.swiper-nav-onhover .btn-next {
  opacity: 0 !important;
}
.swiper-nav-onhover:hover .btn-prev, .swiper-nav-onhover:hover .btn-next {
  opacity: 1 !important;
}
.swiper-nav-onhover:hover .btn-prev.swiper-button-disabled, .swiper-nav-onhover:hover .btn-prev.swiper-button-disabled:disabled, .swiper-nav-onhover:hover .btn-next.swiper-button-disabled, .swiper-nav-onhover:hover .btn-next.swiper-button-disabled:disabled {
  opacity: 0.55 !important;
}

.swiper-pagination {
  --ar-carousel-pagination-bullet-size: 0.75rem;
  --ar-carousel-pagination-bullet-spacer: 1rem;
  --ar-carousel-pagination-bullet-border-color: var(--ar-gray-600);
  --ar-carousel-pagination-bullet-border-width: var(--ar-border-width);
  --ar-carousel-pagination-bullet-border-radius: 50%;
  --ar-carousel-pagination-bullet-bg: transparent;
  --ar-carousel-pagination-bullet-active-border-color: var(--ar-primary);
  --ar-carousel-pagination-bullet-active-bg: var(--ar-primary);
  --ar-carousel-pagination-progressbar-height: 0.1875rem;
  --ar-carousel-pagination-progressbar-bg: var(--ar-border-color);
  --ar-carousel-pagination-progressbar-fill-bg: var(--ar-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1.25rem;
}

.swiper-pagination-bullet {
  position: relative;
  width: var(--ar-carousel-pagination-bullet-size);
  height: var(--ar-carousel-pagination-bullet-size);
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  border: var(--ar-carousel-pagination-bullet-border-width) solid var(--ar-carousel-pagination-bullet-border-color);
  border-radius: var(--ar-carousel-pagination-bullet-border-radius);
  background-color: var(--ar-carousel-pagination-bullet-bg);
  opacity: 1;
}
.swiper-pagination-bullet:focus {
  outline: none;
}

.swiper-pagination-bullet-active {
  border-color: var(--ar-carousel-pagination-bullet-active-border-color);
  background-color: var(--ar-carousel-pagination-bullet-active-bg);
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 1.25rem;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet {
  margin-right: calc(var(--ar-carousel-pagination-bullet-spacer) * 0.5);
  margin-left: calc(var(--ar-carousel-pagination-bullet-spacer) * 0.5);
}

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-vertical {
  flex-direction: column;
  right: 1.25rem;
}
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-bullets.swiper-pagination-vertical .swiper-pagination-bullet {
  margin-top: calc(var(--ar-carousel-pagination-bullet-spacer) * 0.5);
  margin-bottom: calc(var(--ar-carousel-pagination-bullet-spacer) * 0.5);
}

.swiper-thumbnails {
  --ar-carousel-thumbnail-width: 5.875rem;
  --ar-carousel-thumbnail-border-width: var(--ar-border-width);
  --ar-carousel-thumbnail-border-radius: var(--ar-border-radius-xl);
  --ar-carousel-thumbnail-border-color: var(--ar-border-color);
  --ar-carousel-thumbnail-opacity: 0.45;
  --ar-carousel-thumbnail-hover-opacity: 0.8;
  --ar-carousel-thumbnail-active-border-color: var(--ar-primary);
  --ar-carousel-thumbnail-active-opacity: 1;
  --ar-carousel-thumbnail-spacer: 1rem;
  position: static;
  display: flex;
  flex-wrap: wrap;
}
.swiper-thumbnails:not(.justify-content-center) {
  margin-left: calc(var(--ar-carousel-thumbnail-spacer) * 0.5 * -1);
  padding-top: var(--ar-carousel-thumbnail-spacer);
}
.swiper-thumbnails.justify-content-end {
  margin-right: calc(var(--ar-carousel-thumbnail-spacer) * 0.5 * -1);
  margin-left: 0;
}

.swiper-thumbnail {
  width: var(--ar-carousel-thumbnail-width);
  margin: calc(var(--ar-carousel-thumbnail-spacer) * 0.5) !important;
  padding: 0.5rem;
  border: var(--ar-carousel-thumbnail-border-width) solid var(--ar-carousel-thumbnail-border-color);
  border-radius: var(--ar-carousel-thumbnail-border-radius);
}
.swiper-thumbnail:last-child {
  margin-right: 0 !important;
}
.swiper-thumbnail > img {
  transition: opacity 0.25s ease-in-out;
  border-radius: calc(var(--ar-carousel-thumbnail-border-radius) - 0.25rem);
  opacity: var(--ar-carousel-thumbnail-opacity);
}
.swiper-thumbnail:hover > img {
  opacity: var(--ar-carousel-thumbnail-hover-opacity);
}
.swiper-thumbnail.active {
  border-color: var(--ar-carousel-thumbnail-active-border-color);
}
.swiper-thumbnail.active > img {
  opacity: var(--ar-carousel-thumbnail-active-opacity);
}

.swiper-thumbnails-circle .swiper-thumbnail,
.swiper-thumbnails-circle .swiper-thumbnail > img {
  border-radius: 50%;
}

.swiper-pagination-progressbar {
  background-color: var(--ar-carousel-pagination-progressbar-bg);
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: var(--ar-carousel-pagination-progressbar-fill-bg);
}

.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
  height: var(--ar-carousel-pagination-progressbar-height);
}

.swiper-vertical > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-vertical {
  width: var(--ar-carousel-pagination-progressbar-height);
}

.swiper-scrollbar {
  --ar-carousel-scrollbar-width: 0.125rem;
  --ar-carousel-scrollbar-bg: var(--ar-border-color);
  --ar-carousel-scrollbar-drag-width: 0.25rem;
  --ar-carousel-scrollbar-drag-bg: var(--ar-gray-500);
  background-color: var(--ar-carousel-scrollbar-bg);
}

.swiper-scrollbar-drag {
  background-color: var(--ar-gray-500);
}

.swiper-vertical > .swiper-scrollbar {
  right: 0.25rem;
  width: var(--ar-carousel-scrollbar-width);
}
.swiper-vertical > .swiper-scrollbar .swiper-scrollbar-drag {
  left: 50%;
  width: var(--ar-carousel-scrollbar-drag-width);
  margin-left: calc(var(--ar-carousel-scrollbar-drag-width) * -0.5);
}

.swiper-horizontal > .swiper-scrollbar {
  bottom: 0.25rem;
  height: var(--ar-carousel-scrollbar-width);
}
.swiper-horizontal > .swiper-scrollbar .swiper-scrollbar-drag {
  top: 50%;
  height: var(--ar-carousel-scrollbar-drag-width);
  margin-top: calc(var(--ar-carousel-scrollbar-drag-width) * -0.5);
}

.from-top, .from-bottom,
.from-start, .from-end,
.scale-up, .scale-down {
  transition: all 0.4s 0.4s ease-in-out;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.from-top {
  transform: translateY(-2.25rem);
}

.from-bottom {
  transform: translateY(2.25rem);
}

.from-start {
  transform: translateX(-2.25rem);
}

.from-end {
  transform: translateX(2.25rem);
}

.scale-up {
  transform: scale(0.85);
}

.swiper-slide-active .from-top, .swiper-slide-active .from-bottom,
.active .from-top,
.active .from-bottom {
  transform: translateY(0);
  opacity: 1;
}
.swiper-slide-active .from-start, .swiper-slide-active .from-end,
.active .from-start,
.active .from-end {
  transform: translateX(0);
  opacity: 1;
}
.swiper-slide-active .scale-up, .swiper-slide-active .scale-down,
.active .scale-up,
.active .scale-down {
  transform: scale(1);
  opacity: 1;
}

.swiper-slide-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-scale-effect .swiper-slide-cover {
  transition: 8.5s ease-out;
  transform: scale(1.1);
}

.swiper-scale-effect .swiper-slide.swiper-slide-active .swiper-slide-cover {
  transform: scale(1);
}

.lg-backdrop {
  z-index: 1055;
}

.lg-outer {
  z-index: 1060;
}
.lg-outer .lg-thumb-item:hover,
.lg-outer .lg-thumb-item.active {
  border-color: var(--ar-primary);
}

.lg-on {
  overflow: hidden;
}

.lg-item:focus, .lg:focus, .lg-outer:focus,
.lg-inner:focus {
  outline: none;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax .jarallax-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-position: center;
  background-size: cover;
}

[data-disable-parallax-down=xs] {
  transform: none !important;
  opacity: 1 !important;
}

@media (max-width: 575.98px) {
  [data-disable-parallax-down=sm] {
    transform: none !important;
    opacity: 1 !important;
  }
}

@media (max-width: 767.98px) {
  [data-disable-parallax-down=md] {
    transform: none !important;
    opacity: 1 !important;
  }
}

@media (max-width: 991.98px) {
  [data-disable-parallax-down=lg] {
    transform: none !important;
    opacity: 1 !important;
  }
}

@media (min-width: 576px) {
  [data-disable-parallax-up=sm] {
    transform: none !important;
    opacity: 1 !important;
  }
}

@media (min-width: 768px) {
  [data-disable-parallax-up=md] {
    transform: none !important;
    opacity: 1 !important;
  }
}

@media (min-width: 992px) {
  [data-disable-parallax-up=lg] {
    transform: none !important;
    opacity: 1 !important;
  }
}

@media (min-width: 1200px) {
  [data-disable-parallax-up=xl] {
    transform: none !important;
    opacity: 1 !important;
  }
}

.parallax {
  position: relative;
}

.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.parallax-layer:first-child {
  position: relative;
}
.parallax-layer > img {
  display: block;
  width: 100%;
}

.interactive-map:focus {
  outline: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 0;
  border-radius: var(--ar-border-radius-sm);
  box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32);
}
.leaflet-touch .leaflet-bar a {
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  color: #434a57;
  font-weight: normal;
  line-height: 26px;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-bottom-color: #e3e9ef;
  border-top-left-radius: var(--ar-border-radius-sm);
  border-top-right-radius: var(--ar-border-radius-sm);
}
.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: var(--ar-border-radius-sm);
  border-bottom-right-radius: var(--ar-border-radius-sm);
}
.leaflet-touch .leaflet-bar a:hover {
  background-color: #fff;
  color: var(--ar-primary);
}
.leaflet-touch .leaflet-bar a.leaflet-disabled {
  background-color: #fff;
  color: rgba(133, 140, 151, 0.8);
}

.leaflet-left .leaflet-control {
  margin-left: 1rem;
}

.leaflet-top .leaflet-control {
  margin-top: 1rem;
}

.leaflet-container a {
  color: var(--ar-primary);
  font-weight: 500;
}

.leaflet-popup-content-wrapper {
  min-width: 17.5rem;
  padding: 0;
  border-radius: var(--ar-border-radius-lg);
  overflow: hidden;
}
.leaflet-popup-content-wrapper .leaflet-popup-content {
  width: 100% !important;
  margin: 0 !important;
  color: var(--ar-body-color);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0 0.1875rem 0.875rem rgba(18, 21, 25, 0.32);
}

.leaflet-container {
  font-family: "Inter", sans-serif;
}
.leaflet-container a.leaflet-popup-close-button {
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  border: 1px solid #e3e9ef;
  border-radius: 50%;
  color: rgba(67, 74, 87, 0.7);
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
}
.leaflet-container a.leaflet-popup-close-button:hover {
  color: #434a57;
}

.leaflet-popup:focus,
.leaflet-marker-icon:focus {
  outline: none;
}

.leaflet-popup-tip-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@-moz-document url-prefix() {
  .leaflet-container a.leaflet-popup-close-button {
    line-height: 1.375rem;
  }
}
.date-picker[readonly] {
  background-color: transparent;
}
.date-picker:focus {
  border-color: var(--ar-gray-700);
}

.flatpickr-calendar {
  width: 325px;
  padding: 0 0.5rem;
  border: var(--ar-border-width) solid #f0f3f6;
  border-radius: var(--ar-border-radius-lg);
  box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32);
}
.flatpickr-calendar::before, .flatpickr-calendar::after {
  display: none;
}

.flatpickr-innerContainer {
  padding-bottom: 1.125rem;
}

.flatpickr-months {
  padding: 0.75rem 0;
}
.flatpickr-months svg {
  vertical-align: top;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  top: 0.75rem;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  fill: var(--ar-gray-800);
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--ar-primary);
}

span.flatpickr-weekday,
.flatpickr-time .flatpickr-time-separator {
  color: var(--ar-gray-600);
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  padding: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.numInputWrapper,
.numInput,
.flatpickr-current-month .flatpickr-monthDropdown-months {
  color: var(--ar-gray-900) !important;
}

.numInputWrapper:hover,
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: var(--ar-gray-200);
}

.numInput:hover {
  background-color: transparent !important;
}

.flatpickr-day {
  height: 38px;
  border-radius: 0;
  border-radius: var(--ar-border-radius);
  color: var(--ar-gray-900);
  line-height: 37px;
}
.flatpickr-day:hover:not(.flatpickr-disabled):not(.today):not(.selected) {
  border-color: var(--ar-gray-200);
  background-color: var(--ar-gray-200);
}
.flatpickr-day.today {
  border-color: rgba(var(--ar-primary-rgb), 0.5) !important;
  color: var(--ar-primary) !important;
}
.flatpickr-day.today.selected {
  color: #fff !important;
}
.flatpickr-day.today:hover {
  background-color: transparent;
}
.flatpickr-day.selected {
  border-color: var(--ar-primary) !important;
  background-color: var(--ar-primary) !important;
}
.flatpickr-day.flatpickr-disabled {
  color: var(--ar-gray-500) !important;
}
.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
  color: var(--ar-gray-600);
}
.flatpickr-day.inRange {
  border-color: var(--ar-gray-200) !important;
  background-color: var(--ar-gray-200) !important;
  box-shadow: -5px 0 0 var(--ar-gray-200), 5px 0 0 var(--ar-gray-200);
}
.flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.endRange.seleced, .flatpickr-day.endRange:hover {
  border-color: var(--ar-primary) !important;
  background-color: var(--ar-primary) !important;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0;
  border-radius: 0 1rem 1rem 0;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 0;
  border-radius: 1rem 0 0 1rem;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #448c74;
}

.flatpickr-time {
  margin-bottom: 0.5rem;
}
.flatpickr-time .flatpickr-am-pm {
  color: var(--ar-gray-800);
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background-color: var(--ar-gray-200);
}
.flatpickr-time input.flatpickr-hour {
  font-weight: 600;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-color: var(--ar-border-color);
}

.fc-daygrid,
.fc-timegrid,
.fc-list {
  padding: 0.5rem;
  border-radius: calc(var(--ar-border-radius) * 1.5);
  background-color: var(--ar-gray-100);
}

.fc-col-header-cell {
  padding: 0.75rem 0 !important;
  border-bottom: 1px dashed var(--ar-gray-400) !important;
}

.fc-col-header-cell-cushion,
.fc-list-day-cushion {
  color: var(--ar-gray-800) !important;
}

.fc .fc-list-sticky .fc-list-day > * {
  background-color: transparent;
}

.fc-list-day-cushion {
  border-radius: calc(var(--ar-border-radius) * 0.75);
  background-color: #fff;
}

.fc .fc-list-event:hover td {
  background-color: var(--ar-gray-200);
}

.fc-scrollgrid td {
  border-top: 1px dashed var(--ar-gray-400);
  border-left: 1px dashed var(--ar-gray-400);
}
@media (min-width: 992px) {
  .fc-scrollgrid td {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}

.fc-prev-button,
.fc-next-button {
  --ar-btn-size: 3rem;
  width: var(--ar-btn-size);
  height: var(--ar-btn-size);
  padding: 0;
}
.fc-prev-button > [class^=bi-],
.fc-prev-button > [class*=" bi-"],
.fc-next-button > [class^=bi-],
.fc-next-button > [class*=" bi-"] {
  font-size: 1.625em;
}

.fc-today-button {
  --ar-btn-padding-x: 1.25rem;
}

.calendar .btn-group .btn-primary {
  background-color: transparent;
  color: var(--ar-primary);
}
.calendar .btn-group .btn-primary.active {
  background-color: var(--ar-primary);
  color: #fff;
}

.fc .fc-toolbar {
  overflow: auto;
  margin-bottom: 0.75rem !important;
  padding-bottom: 0.875rem;
}

.fc-toolbar-title {
  margin: 0 1rem !important;
  font-size: calc(1.275rem + 0.3vw) !important;
  font-weight: 500;
}
@media (min-width: 1200px) {
  .fc-toolbar-title {
    font-size: 1.5rem !important;
  }
}

.fc-toolbar-chunk {
  white-space: nowrap;
}

.fc-list-empty {
  background-color: transparent !important;
}

.fc-h-event,
.fc-v-event {
  --fc-event-border-color: var(--ar-primary);
  --fc-event-bg-color: var(--ar-primary);
}
.fc-h-event.text-nav .fc-event-main, .fc-h-event.text-dark .fc-event-main,
.fc-v-event.text-nav .fc-event-main,
.fc-v-event.text-dark .fc-event-main {
  color: currentColor;
}
.fc-h-event .fc-event-title,
.fc-v-event .fc-event-title {
  font-weight: 600 !important;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background-color: var(--ar-gray-200);
}

.fc-daygrid-event-dot,
.fc-list-event-dot {
  --fc-event-border-color: var(--ar-primary);
}

.event-custom-style.fc-daygrid-event {
  flex-direction: column;
  align-items: start;
}
.event-custom-style .fc-daygrid-event-dot {
  display: none;
}
.event-custom-style .fc-event-time {
  font-size: calc(var(--ar-body-font-size) * 0.75);
}

tr.event-custom-style {
  background-color: transparent !important;
}

.border-purple {
  border-color: #7950ed !important;
}

.fc-daygrid-event-harness {
  margin-bottom: 0.25rem;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
}

.steps {
  --ar-steps-padding-y: 1.5rem;
  --ar-steps-padding-x: 1.5rem;
  --ar-steps-number-size: 4.5rem;
  --ar-steps-number-inner-size: 3.5rem;
  --ar-steps-number-border-radius: 50%;
  --ar-steps-number-font-size: calc(var(--ar-body-font-size) * 1.25);
  --ar-steps-number-inner-bg: var(--ar-gray-100);
  --ar-steps-number-color: var(--ar-primary);
  --ar-steps-connect-width: var(--ar-border-width);
  --ar-steps-connect-style: dashed;
  --ar-steps-connect-color: var(--ar-gray-400);
  --ar-steps-active-number-bg: rgba(var(--ar-primary-rgb), 0.1);
  --ar-steps-active-number-inner-bg: var(--ar-primary);
  --ar-steps-active-number-color: #fff;
  display: flex;
  flex-direction: column;
  margin: calc(var(--ar-steps-padding-y) * -1) 0;
}

.step {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--ar-steps-padding-y) 0;
}
.step::before, .step::after {
  position: absolute;
  left: calc(var(--ar-steps-number-size) * 0.5);
  height: calc(50% - var(--ar-steps-number-size) * 0.5);
  border-left: var(--ar-steps-connect-width) var(--ar-steps-connect-style) var(--ar-steps-connect-color);
}
.step::before {
  top: 0;
}
.step::after {
  bottom: 0;
}
.step:not(:first-child)::before {
  content: "";
}
.step:not(:last-child)::after {
  content: "";
}

.step-number {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: var(--ar-steps-number-size);
  height: var(--ar-steps-number-size);
}
.step-number::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transition: transform 0.3s ease-in-out, opacity 0.2s;
  border-radius: var(--ar-steps-number-border-radius);
  background-color: var(--ar-steps-active-number-bg);
  opacity: 0;
  content: "";
}

.step-number-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--ar-steps-number-inner-size);
  height: var(--ar-steps-number-inner-size);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  border-radius: var(--ar-steps-number-border-radius);
  background-color: var(--ar-steps-number-inner-bg);
  color: var(--ar-steps-number-color);
  font-size: var(--ar-steps-number-font-size);
  font-weight: 700;
  z-index: 2;
}

.step-body {
  padding-left: var(--ar-steps-padding-x);
}

.steps-hoverable .step:hover .step-number::before,
.step.active .step-number::before {
  transform: scale(1);
  opacity: 1;
}
.steps-hoverable .step:hover .step-number-inner,
.step.active .step-number-inner {
  background-color: var(--ar-steps-active-number-inner-bg);
  color: var(--ar-steps-active-number-color);
}

.steps-no-connector .step::before, .steps-no-connector .step::after {
  display: none !important;
}

@media (min-width: 576px) {
  .steps-horizontal-sm.steps {
    flex-direction: row;
    margin: 0 calc(var(--ar-steps-padding-x) * -1);
  }
  .steps-horizontal-sm .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--ar-steps-padding-x);
  }
  .steps-horizontal-sm .step::before, .steps-horizontal-sm .step::after {
    top: calc(var(--ar-steps-number-size) * 0.5);
    border-top: var(--ar-steps-connect-width) var(--ar-steps-connect-style) var(--ar-steps-connect-color);
    border-left: 0;
  }
  .steps-horizontal-sm .step::before {
    display: none;
  }
  .steps-horizontal-sm .step::after {
    right: calc(var(--ar-steps-padding-y) * -1);
    left: auto;
    width: calc(100% - var(--ar-steps-number-size));
    height: auto;
  }
  .steps-horizontal-sm .step-body {
    padding-top: var(--ar-steps-padding-y);
    padding-left: 0;
  }
  .steps-horizontal-sm.steps-center {
    text-align: center;
  }
  .steps-horizontal-sm.steps-center .step::before, .steps-horizontal-sm.steps-center .step::after {
    display: block;
    width: calc(50% - var(--ar-steps-number-size) * 0.5);
  }
  .steps-horizontal-sm.steps-center .step::before {
    left: 0;
  }
  .steps-horizontal-sm.steps-center .step::after {
    right: 0;
  }
  .steps-horizontal-sm.steps-center .step:first-child::before {
    display: none;
  }
  .steps-horizontal-sm.steps-center .step:last-child::after {
    display: none;
  }
  .steps-horizontal-sm.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-sm.steps-end {
    text-align: right;
  }
  .steps-horizontal-sm.steps-end .step::after {
    right: auto;
    left: calc(var(--ar-steps-padding-y) * -1);
  }
  .steps-horizontal-sm.steps-end .step:first-child::after {
    display: none;
  }
  .steps-horizontal-sm.steps-end .step:last-child::after {
    content: "";
  }
  .steps-horizontal-sm.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .steps-horizontal-md.steps {
    flex-direction: row;
    margin: 0 calc(var(--ar-steps-padding-x) * -1);
  }
  .steps-horizontal-md .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--ar-steps-padding-x);
  }
  .steps-horizontal-md .step::before, .steps-horizontal-md .step::after {
    top: calc(var(--ar-steps-number-size) * 0.5);
    border-top: var(--ar-steps-connect-width) var(--ar-steps-connect-style) var(--ar-steps-connect-color);
    border-left: 0;
  }
  .steps-horizontal-md .step::before {
    display: none;
  }
  .steps-horizontal-md .step::after {
    right: calc(var(--ar-steps-padding-y) * -1);
    left: auto;
    width: calc(100% - var(--ar-steps-number-size));
    height: auto;
  }
  .steps-horizontal-md .step-body {
    padding-top: var(--ar-steps-padding-y);
    padding-left: 0;
  }
  .steps-horizontal-md.steps-center {
    text-align: center;
  }
  .steps-horizontal-md.steps-center .step::before, .steps-horizontal-md.steps-center .step::after {
    display: block;
    width: calc(50% - var(--ar-steps-number-size) * 0.5);
  }
  .steps-horizontal-md.steps-center .step::before {
    left: 0;
  }
  .steps-horizontal-md.steps-center .step::after {
    right: 0;
  }
  .steps-horizontal-md.steps-center .step:first-child::before {
    display: none;
  }
  .steps-horizontal-md.steps-center .step:last-child::after {
    display: none;
  }
  .steps-horizontal-md.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-md.steps-end {
    text-align: right;
  }
  .steps-horizontal-md.steps-end .step::after {
    right: auto;
    left: calc(var(--ar-steps-padding-y) * -1);
  }
  .steps-horizontal-md.steps-end .step:first-child::after {
    display: none;
  }
  .steps-horizontal-md.steps-end .step:last-child::after {
    content: "";
  }
  .steps-horizontal-md.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .steps-horizontal-lg.steps {
    flex-direction: row;
    margin: 0 calc(var(--ar-steps-padding-x) * -1);
  }
  .steps-horizontal-lg .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--ar-steps-padding-x);
  }
  .steps-horizontal-lg .step::before, .steps-horizontal-lg .step::after {
    top: calc(var(--ar-steps-number-size) * 0.5);
    border-top: var(--ar-steps-connect-width) var(--ar-steps-connect-style) var(--ar-steps-connect-color);
    border-left: 0;
  }
  .steps-horizontal-lg .step::before {
    display: none;
  }
  .steps-horizontal-lg .step::after {
    right: calc(var(--ar-steps-padding-y) * -1);
    left: auto;
    width: calc(100% - var(--ar-steps-number-size));
    height: auto;
  }
  .steps-horizontal-lg .step-body {
    padding-top: var(--ar-steps-padding-y);
    padding-left: 0;
  }
  .steps-horizontal-lg.steps-center {
    text-align: center;
  }
  .steps-horizontal-lg.steps-center .step::before, .steps-horizontal-lg.steps-center .step::after {
    display: block;
    width: calc(50% - var(--ar-steps-number-size) * 0.5);
  }
  .steps-horizontal-lg.steps-center .step::before {
    left: 0;
  }
  .steps-horizontal-lg.steps-center .step::after {
    right: 0;
  }
  .steps-horizontal-lg.steps-center .step:first-child::before {
    display: none;
  }
  .steps-horizontal-lg.steps-center .step:last-child::after {
    display: none;
  }
  .steps-horizontal-lg.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-lg.steps-end {
    text-align: right;
  }
  .steps-horizontal-lg.steps-end .step::after {
    right: auto;
    left: calc(var(--ar-steps-padding-y) * -1);
  }
  .steps-horizontal-lg.steps-end .step:first-child::after {
    display: none;
  }
  .steps-horizontal-lg.steps-end .step:last-child::after {
    content: "";
  }
  .steps-horizontal-lg.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .steps-horizontal-xl.steps {
    flex-direction: row;
    margin: 0 calc(var(--ar-steps-padding-x) * -1);
  }
  .steps-horizontal-xl .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--ar-steps-padding-x);
  }
  .steps-horizontal-xl .step::before, .steps-horizontal-xl .step::after {
    top: calc(var(--ar-steps-number-size) * 0.5);
    border-top: var(--ar-steps-connect-width) var(--ar-steps-connect-style) var(--ar-steps-connect-color);
    border-left: 0;
  }
  .steps-horizontal-xl .step::before {
    display: none;
  }
  .steps-horizontal-xl .step::after {
    right: calc(var(--ar-steps-padding-y) * -1);
    left: auto;
    width: calc(100% - var(--ar-steps-number-size));
    height: auto;
  }
  .steps-horizontal-xl .step-body {
    padding-top: var(--ar-steps-padding-y);
    padding-left: 0;
  }
  .steps-horizontal-xl.steps-center {
    text-align: center;
  }
  .steps-horizontal-xl.steps-center .step::before, .steps-horizontal-xl.steps-center .step::after {
    display: block;
    width: calc(50% - var(--ar-steps-number-size) * 0.5);
  }
  .steps-horizontal-xl.steps-center .step::before {
    left: 0;
  }
  .steps-horizontal-xl.steps-center .step::after {
    right: 0;
  }
  .steps-horizontal-xl.steps-center .step:first-child::before {
    display: none;
  }
  .steps-horizontal-xl.steps-center .step:last-child::after {
    display: none;
  }
  .steps-horizontal-xl.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-xl.steps-end {
    text-align: right;
  }
  .steps-horizontal-xl.steps-end .step::after {
    right: auto;
    left: calc(var(--ar-steps-padding-y) * -1);
  }
  .steps-horizontal-xl.steps-end .step:first-child::after {
    display: none;
  }
  .steps-horizontal-xl.steps-end .step:last-child::after {
    content: "";
  }
  .steps-horizontal-xl.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 1400px) {
  .steps-horizontal-xxl.steps {
    flex-direction: row;
    margin: 0 calc(var(--ar-steps-padding-x) * -1);
  }
  .steps-horizontal-xxl .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--ar-steps-padding-x);
  }
  .steps-horizontal-xxl .step::before, .steps-horizontal-xxl .step::after {
    top: calc(var(--ar-steps-number-size) * 0.5);
    border-top: var(--ar-steps-connect-width) var(--ar-steps-connect-style) var(--ar-steps-connect-color);
    border-left: 0;
  }
  .steps-horizontal-xxl .step::before {
    display: none;
  }
  .steps-horizontal-xxl .step::after {
    right: calc(var(--ar-steps-padding-y) * -1);
    left: auto;
    width: calc(100% - var(--ar-steps-number-size));
    height: auto;
  }
  .steps-horizontal-xxl .step-body {
    padding-top: var(--ar-steps-padding-y);
    padding-left: 0;
  }
  .steps-horizontal-xxl.steps-center {
    text-align: center;
  }
  .steps-horizontal-xxl.steps-center .step::before, .steps-horizontal-xxl.steps-center .step::after {
    display: block;
    width: calc(50% - var(--ar-steps-number-size) * 0.5);
  }
  .steps-horizontal-xxl.steps-center .step::before {
    left: 0;
  }
  .steps-horizontal-xxl.steps-center .step::after {
    right: 0;
  }
  .steps-horizontal-xxl.steps-center .step:first-child::before {
    display: none;
  }
  .steps-horizontal-xxl.steps-center .step:last-child::after {
    display: none;
  }
  .steps-horizontal-xxl.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-xxl.steps-end {
    text-align: right;
  }
  .steps-horizontal-xxl.steps-end .step::after {
    right: auto;
    left: calc(var(--ar-steps-padding-y) * -1);
  }
  .steps-horizontal-xxl.steps-end .step:first-child::after {
    display: none;
  }
  .steps-horizontal-xxl.steps-end .step:last-child::after {
    content: "";
  }
  .steps-horizontal-xxl.steps-end .step-number {
    margin-left: auto;
  }
}
.steps-horizontal.steps {
  flex-direction: row;
  margin: 0 calc(var(--ar-steps-padding-x) * -1);
}
.steps-horizontal .step {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 var(--ar-steps-padding-x);
}
.steps-horizontal .step::before, .steps-horizontal .step::after {
  top: calc(var(--ar-steps-number-size) * 0.5);
  border-top: var(--ar-steps-connect-width) var(--ar-steps-connect-style) var(--ar-steps-connect-color);
  border-left: 0;
}
.steps-horizontal .step::before {
  display: none;
}
.steps-horizontal .step::after {
  right: calc(var(--ar-steps-padding-y) * -1);
  left: auto;
  width: calc(100% - var(--ar-steps-number-size));
  height: auto;
}
.steps-horizontal .step-body {
  padding-top: var(--ar-steps-padding-y);
  padding-left: 0;
}
.steps-horizontal.steps-center {
  text-align: center;
}
.steps-horizontal.steps-center .step::before, .steps-horizontal.steps-center .step::after {
  display: block;
  width: calc(50% - var(--ar-steps-number-size) * 0.5);
}
.steps-horizontal.steps-center .step::before {
  left: 0;
}
.steps-horizontal.steps-center .step::after {
  right: 0;
}
.steps-horizontal.steps-center .step:first-child::before {
  display: none;
}
.steps-horizontal.steps-center .step:last-child::after {
  display: none;
}
.steps-horizontal.steps-center .step-number {
  margin-right: auto;
  margin-left: auto;
}
.steps-horizontal.steps-end {
  text-align: right;
}
.steps-horizontal.steps-end .step::after {
  right: auto;
  left: calc(var(--ar-steps-padding-y) * -1);
}
.steps-horizontal.steps-end .step:first-child::after {
  display: none;
}
.steps-horizontal.steps-end .step:last-child::after {
  content: "";
}
.steps-horizontal.steps-end .step-number {
  margin-left: auto;
}

.hotspots,
.hotspots-alt {
  position: relative;
}
.hotspots .hotspot,
.hotspots-alt .hotspot {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}
.hotspots .hotspot-inner,
.hotspots-alt .hotspot-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  border-radius: 50%;
}

.hotspots {
  --ar-hotspots-size: 4rem;
  --ar-hotspots-border-width: var(--ar-border-width);
  --ar-hotspots-border-color: rgba(255, 255, 255, 0.5);
  --ar-hotspots-color: var(--ar-primary);
  --ar-hotspots-inner-bg: #fff;
  --ar-hotspots-hover-border-color: rgba(var(--ar-primary-rgb), 0.5);
  --ar-hotspots-hover-color: #fff;
  --ar-hotspots-hover-inner-bg: var(--ar-primary);
}
.hotspots .hotspot {
  width: var(--ar-hotspots-size);
  height: var(--ar-hotspots-size);
  border: var(--ar-hotspots-border-width) solid var(--ar-hotspots-border-color);
}
.hotspots .hotspot-inner {
  width: calc(var(--ar-hotspots-size) - 1rem);
  height: calc(var(--ar-hotspots-size) - 1rem);
  background-color: var(--ar-hotspots-inner-bg);
  color: var(--ar-hotspots-color);
  font-weight: 600;
}
.hotspots [class^=ai-], .hotspots [class*=" ai-"] {
  font-size: 1.2em;
}
.hotspots .hotspot:hover,
.hotspots .hotspot:focus {
  border-color: var(--ar-hotspots-hover-border-color);
}
.hotspots .hotspot:hover .hotspot-inner,
.hotspots .hotspot:focus .hotspot-inner {
  background-color: var(--ar-hotspots-hover-inner-bg);
  color: var(--ar-hotspots-hover-color);
}

.hotspots-alt {
  --ar-hotspots-size: 2rem;
  --ar-hotspots-border-width: var(--ar-border-width);
  --ar-hotspots-border-color: rgba(255, 255, 255, 0.4);
  --ar-hotspots-bg: rgba(255, 255, 255, 0.1);
  --ar-hotspots-inner-bg: #fff;
  --ar-hotspots-hover-border-color: rgba(var(--ar-primary-rgb), 0.4);
  --ar-hotspots-hover-bg: rgba(var(--ar-primary-rgb), 0.2);
  --ar-hotspots-hover-inner-bg: var(--ar-primary);
}
.hotspots-alt .hotspot {
  background-color: var(--ar-hotspots-bg);
  -webkit-backdrop-filter: blur(0.25rem);
  backdrop-filter: blur(0.25rem);
}
.hotspots-alt .hotspot:hover, .hotspots-alt .hotspot:focus {
  background-color: var(--ar-hotspots-hover-bg);
}
.hotspots-alt .hotspot-inner {
  width: calc(var(--ar-hotspots-size) - 1.25rem);
  height: calc(var(--ar-hotspots-size) - 1.25rem);
}

img-comparison-slider {
  display: block;
  --divider-width: 0.1875rem;
  --divider-color: var(--ar-primary);
  --default-handle-opacity: 1;
}
img-comparison-slider:focus {
  outline: none;
}

.binded-content {
  position: relative;
  height: 100%;
}

.binded-item {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: opacity 0.35s ease-in-out, visibility 0.35s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
.binded-item.active {
  position: relative;
  visibility: visible;
  opacity: 1;
}

.swap-image {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.swap-image .swap-from,
.swap-image .swap-to {
  display: block;
  transition: opacity 0.25s ease-in-out;
}
.swap-image .swap-to {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
}
.swap-image:hover .swap-from, .swap-image.active .swap-from {
  opacity: 0;
}
.swap-image:hover .swap-to, .swap-image.active .swap-to {
  opacity: 1;
}

.card:hover > .swap-image .swap-from,
.card:hover > .swap-image .swap-to,
.card.card-active > .swap-image .swap-from,
.card.card-active > .swap-image .swap-to,
.nav-link:hover > .swap-image .swap-from,
.nav-link:hover > .swap-image .swap-to,
.nav-link.active > .swap-image .swap-from,
.nav-link.active > .swap-image .swap-to {
  transition-duration: 0.2s;
}
.card:hover > .swap-image .swap-from,
.card.card-active > .swap-image .swap-from,
.nav-link:hover > .swap-image .swap-from,
.nav-link.active > .swap-image .swap-from {
  opacity: 0;
}
.card:hover > .swap-image .swap-to,
.card.card-active > .swap-image .swap-to,
.nav-link:hover > .swap-image .swap-to,
.nav-link.active > .swap-image .swap-to {
  opacity: 1;
}

.zoom-effect-wrapper {
  position: relative;
  transform: translateZ(0);
  overflow: hidden;
}

.zoom-effect-img {
  display: block;
  transition: transform 0.4s ease-in-out;
}

.zoom-effect:hover .zoom-effect-img {
  transform: scale(1.05);
}

.polygon-avatar {
  display: block;
  width: 6.25rem;
  height: 6.25rem;
  -webkit-clip-path: path("M44.2392 4.03005C47.6985 1.61004 52.3015 1.61004 55.7608 4.03005C57.9922 5.59105 60.7615 6.1797 63.4349 5.66124C67.5795 4.85747 71.7845 6.72967 73.9604 10.3475C75.364 12.6811 77.6545 14.3453 80.3076 14.959C84.4207 15.9105 87.5007 19.3311 88.0171 23.5212C88.3501 26.224 89.7657 28.6759 91.9398 30.3157C95.3104 32.8578 96.7328 37.2355 95.5002 41.2733C94.7051 43.8779 95.0011 46.6936 96.3203 49.0759C98.3654 52.7692 97.8843 57.347 95.116 60.5344C93.3303 62.5904 92.4554 65.283 92.6915 67.996C93.0577 72.2018 90.7562 76.1881 86.9307 77.974C84.4632 79.1259 82.5687 81.2299 81.681 83.8044C80.3048 87.7955 76.581 90.5011 72.3599 90.5766C69.6371 90.6253 67.0507 91.7768 65.1926 93.7677C62.312 96.854 57.8096 97.811 53.9228 96.1631C51.4156 95.1002 48.5844 95.1002 46.0772 96.1631C42.1904 97.811 37.688 96.854 34.8074 93.7677C32.9493 91.7768 30.3629 90.6253 27.6401 90.5766C23.419 90.5011 19.6952 87.7955 18.319 83.8044C17.4313 81.2299 15.5368 79.1259 13.0693 77.974C9.24381 76.1881 6.94232 72.2018 7.30846 67.996C7.54464 65.283 6.66974 62.5904 4.88405 60.5344C2.1157 57.347 1.63456 52.7692 3.67971 49.0759C4.99892 46.6936 5.29487 43.8779 4.4998 41.2733C3.26722 37.2355 4.68961 32.8578 8.06016 30.3157C10.2343 28.6759 11.6499 26.224 11.9829 23.5212C12.4993 19.3311 15.5793 15.9105 19.6924 14.959C22.3455 14.3453 24.636 12.6811 26.0396 10.3475C28.2155 6.72967 32.4205 4.85747 36.5651 5.66124C39.2385 6.1797 42.0078 5.59105 44.2392 4.03005Z");
  clip-path: path("M44.2392 4.03005C47.6985 1.61004 52.3015 1.61004 55.7608 4.03005C57.9922 5.59105 60.7615 6.1797 63.4349 5.66124C67.5795 4.85747 71.7845 6.72967 73.9604 10.3475C75.364 12.6811 77.6545 14.3453 80.3076 14.959C84.4207 15.9105 87.5007 19.3311 88.0171 23.5212C88.3501 26.224 89.7657 28.6759 91.9398 30.3157C95.3104 32.8578 96.7328 37.2355 95.5002 41.2733C94.7051 43.8779 95.0011 46.6936 96.3203 49.0759C98.3654 52.7692 97.8843 57.347 95.116 60.5344C93.3303 62.5904 92.4554 65.283 92.6915 67.996C93.0577 72.2018 90.7562 76.1881 86.9307 77.974C84.4632 79.1259 82.5687 81.2299 81.681 83.8044C80.3048 87.7955 76.581 90.5011 72.3599 90.5766C69.6371 90.6253 67.0507 91.7768 65.1926 93.7677C62.312 96.854 57.8096 97.811 53.9228 96.1631C51.4156 95.1002 48.5844 95.1002 46.0772 96.1631C42.1904 97.811 37.688 96.854 34.8074 93.7677C32.9493 91.7768 30.3629 90.6253 27.6401 90.5766C23.419 90.5011 19.6952 87.7955 18.319 83.8044C17.4313 81.2299 15.5368 79.1259 13.0693 77.974C9.24381 76.1881 6.94232 72.2018 7.30846 67.996C7.54464 65.283 6.66974 62.5904 4.88405 60.5344C2.1157 57.347 1.63456 52.7692 3.67971 49.0759C4.99892 46.6936 5.29487 43.8779 4.4998 41.2733C3.26722 37.2355 4.68961 32.8578 8.06016 30.3157C10.2343 28.6759 11.6499 26.224 11.9829 23.5212C12.4993 19.3311 15.5793 15.9105 19.6924 14.959C22.3455 14.3453 24.636 12.6811 26.0396 10.3475C28.2155 6.72967 32.4205 4.85747 36.5651 5.66124C39.2385 6.1797 42.0078 5.59105 44.2392 4.03005Z");
}

@keyframes rotate-cw {
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate-ccw {
  to {
    transform: rotate(-360deg);
  }
}
@keyframes swinging {
  0% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@keyframes heartbeat {
  0%, 40%, 80%, 100% {
    transform: scale(0.8);
  }
  20%, 60% {
    transform: scale(1);
  }
}
.heartbeat {
  animation: heartbeat 1.4s infinite;
}

@keyframes blinking {
  from {
    opacity: 0;
  }
}
.blinking {
  animation: blinking 1s infinite;
}

.dark-mode {
  --ar-gray-100: rgba(255, 255, 255, .025);
  --ar-gray-200: rgba(255, 255, 255, .05);
  --ar-gray-300: rgba(255, 255, 255, .13);
  --ar-gray-400: rgba(255, 255, 255, .25);
  --ar-gray-500: rgba(255, 255, 255, .4);
  --ar-gray-600: rgba(255, 255, 255, .5);
  --ar-gray-700: rgba(255, 255, 255, .7);
  --ar-gray-800: rgba(255, 255, 255, .9);
  --ar-gray-900: #fff;
  --ar-body-bg: #121519;
  --ar-body-bg-rgb: 18, 21, 25;
  --ar-body-color: var(--ar-gray-700);
  --ar-secondary-bg: var(--ar-gray-100);
  --ar-border-color: rgba(255, 255, 255, 0.13);
  --ar-heading-color: var(--ar-gray-900);
  --ar-nav-link-color: var(--ar-gray-800);
}
.dark-mode .d-dark-mode-none {
  display: none !important;
}
.dark-mode .d-dark-mode-block {
  display: block !important;
}
.dark-mode .d-dark-mode-inline {
  display: inline !important;
}
.dark-mode .d-dark-mode-inline-block {
  display: inline-block !important;
}
.dark-mode .d-dark-mode-inline-flex {
  display: inline-flex !important;
}
.dark-mode .d-dark-mode-flex {
  display: flex !important;
}
.dark-mode .border-dashed {
  border-color: rgba(255, 255, 255, 0.18) !important;
}
.dark-mode .text-dark:not(.badge) {
  color: rgba(var(--ar-light-rgb), var(--ar-text-opacity)) !important;
}
.dark-mode .border-dark:not(.badge) {
  border-color: var(--ar-gray-800) !important;
}
.dark-mode .bg-light:not(.badge) {
  background-color: var(--ar-dark) !important;
}
.dark-mode .bg-white.badge, .dark-mode .bg-light.badge {
  color: #434a57;
}
.dark-mode .text-dark-mode-dark {
  color: var(--ar-dark) !important;
}
.dark-mode .bg-secondary {
  background-color: var(--ar-secondary-bg) !important;
}
.dark-mode body.bg-secondary {
  background-color: rgba(var(--ar-body-bg-rgb), 0.975) !important;
}
.dark-mode .bg-secondary.badge {
  background-color: var(--ar-gray-200) !important;
}
.dark-mode .shadow {
  box-shadow: 0 0.4375rem 1.25rem rgba(0, 0, 0, 0.4) !important;
}
.dark-mode .ignore-dark-mode {
  --ar-gray-100: #f6f9fc;
  --ar-gray-200: #edf1f5;
  --ar-gray-300: #e3e9ef;
  --ar-gray-400: #d7dde2;
  --ar-gray-500: #b4bbc3;
  --ar-gray-600: #858c97;
  --ar-gray-700: #576071;
  --ar-gray-800: #434a57;
  --ar-gray-900: #121519;
  --ar-border-color: var(--ar-gray-300);
  --ar-body-color: var(--ar-gray-700);
  --ar-heading-color: var(--ar-gray-900);
  --ar-nav-link-color: var(--ar-gray-800);
}
.dark-mode .ignore-dark-mode p, .dark-mode .ignore-dark-mode ul, .dark-mode .ignore-dark-mode ol, .dark-mode .ignore-dark-mode span, .dark-mode .ignore-dark-mode dd {
  color: var(--ar-body-color);
}
.dark-mode .ignore-dark-mode .text-dark {
  color: var(--ar-dark) !important;
}
.dark-mode .table:not(.ignore-dark-mode) {
  --ar-table-striped-bg: rgba(255, 255, 255, 0.0375);
  --ar-table-hover-bg: rgba(255, 255, 255, 0.05);
  --ar-table-hover-color: var(--ar-body-color);
}
.dark-mode .table:not(.ignore-dark-mode) > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}
.dark-mode .btn-secondary:not(.ignore-dark-mode) {
  --ar-btn-color: var(--ar-gray-800) !important;
  --ar-btn-hover-color: #fff !important;
  --ar-btn-active-color: #fff !important;
  --ar-btn-border-color: transparent;
  --ar-btn-hover-border-color: transparent;
  --ar-btn-active-border-color: transparent;
  --ar-btn-bg: var(--ar-gray-200);
  --ar-btn-hover-bg: rgba(255, 255, 255, .1);
  --ar-btn-active-bg: rgba(255, 255, 255, .1);
}
.dark-mode .btn-outline-secondary:not(.ignore-dark-mode) {
  --ar-btn-color: var(--ar-gray-800);
  --ar-btn-hover-color: var(--ar-gray-800);
  --ar-btn-active-color: var(--ar-gray-800);
  --ar-btn-border-color: rgba(255, 255, 255, .18);
  --ar-btn-hover-border-color: rgba(255, 255, 255, .18);
  --ar-btn-active-border-color: rgba(255, 255, 255, .18);
  --ar-btn-hover-bg: var(--ar-gray-200);
  --ar-btn-active-bg: var(--ar-gray-200);
}
.dark-mode .form-select:not(.ignore-dark-mode) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.6%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
@-moz-document url-prefix() {
  .dark-mode option {
    color: #434a57;
  }
}
.dark-mode .form-switch:not(.ignore-dark-mode) .form-check-input {
  background-color: rgba(255, 255, 255, 0.25);
}
.dark-mode .form-switch:not(.ignore-dark-mode) .form-check-input:checked {
  background-color: var(--ar-primary);
}
.dark-mode .btn-check + .btn-outline-secondary {
  --ar-btn-hover-bg: transparent;
  --ar-btn-active-bg: transparent;
}
.dark-mode .btn-check + .btn-outline-secondary.btn-icon {
  --ar-btn-border-color: transparent;
  --ar-btn-hover-border-color: transparent;
}
.dark-mode .btn-check:checked + .btn-icon.btn-outline-secondary {
  --ar-btn-active-border-color: rgba(255, 255, 255, .3);
}
.dark-mode .range-slider:not(.ignore-dark-mode) {
  --ar-range-slider-pips-border-color: rgba(255, 255, 255, .2);
}
.dark-mode .credit-card-icon:not(.ignore-dark-mode) {
  background-position: 0 -26px;
}
.dark-mode .navbar:not(.ignore-dark-mode) {
  --ar-navbar-stuck-bg: var(--ar-dark);
  --ar-navbar-collapse-bg: var(--ar-dark);
}
.dark-mode .navbar.ignore-dark-mode .dropdown-menu {
  --ar-dropdown-color: #576071;
  --ar-dropdown-bg: #fff;
  --ar-dropdown-border-color: #f0f3f6;
  --ar-dropdown-box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32);
  --ar-dropdown-link-color: var(--ar-gray-800);
  --ar-dropdown-link-hover-color: var(--ar-primary);
  --ar-dropdown-divider-bg: var(--ar-border-color);
  --ar-dropdown-link-hover-bg: transparent;
  --ar-dropdown-link-active-color: var(--ar-primary);
  --ar-dropdown-link-active-bg: transparent;
  --ar-dropdown-link-disabled-color: var(--ar-gray-600);
  --ar-dropdown-header-color: var(--ar-gray-900);
}
.dark-mode .placeholder {
  opacity: 0.24;
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.1;
  }
}
.dark-mode .placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.9) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.9) 75%, #000 95%);
}
.dark-mode .card:not(.ignore-dark-mode) {
  --ar-card-bg: var(--ar-dark);
}
.dark-mode .card-flip-back {
  background-color: var(--ar-dark);
}
.dark-mode .card-hover-primary:hover {
  border-color: var(--ar-primary) !important;
  background-color: var(--ar-primary) !important;
}
.dark-mode .accordion:not(.ignore-dark-mode) {
  --ar-accordion-bg: rgba(255, 255, 255, 0.02);
  --ar-accordion-btn-color: rgba(255, 255, 255, 0.9);
  --ar-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba%28255, 255, 255, 0.9%29'%3e%3cpath d='M.5 6.3c.6-.6 1.6-.6 2.3 0l9.3 9.3 9.3-9.3c.6-.6 1.6-.6 2.3 0 .6.6.6 1.6 0 2.3L13.3 18.8c-.6.6-1.6.6-2.3 0L.8 8.5c-.9-.8-.9-1.6-.3-2.2z'/%3e%3c/svg%3e");
}
.dark-mode .popover {
  --ar-popover-bg: #121519;
  --ar-popover-border-color: rgba(255, 255, 255, 0.13);
  box-shadow: 0 0.4375rem 1.25rem rgba(0, 0, 0, 0.4);
}
.dark-mode .modal {
  --ar-modal-bg: var(--ar-dark);
}
.dark-mode .alert-primary {
  --ar-alert-bg: rgba(68, 140, 116, .05);
  --ar-alert-border-color: rgba(68, 140, 116, .25);
}
.dark-mode .alert-secondary {
  --ar-alert-bg: rgba(237, 241, 245, .05);
  --ar-alert-border-color: rgba(237, 241, 245, .25);
}
.dark-mode .alert-success {
  --ar-alert-bg: rgba(63, 202, 144, .05);
  --ar-alert-border-color: rgba(63, 202, 144, .25);
}
.dark-mode .alert-info {
  --ar-alert-bg: rgba(63, 127, 202, .05);
  --ar-alert-border-color: rgba(63, 127, 202, .25);
}
.dark-mode .alert-warning {
  --ar-alert-bg: rgba(237, 203, 80, .05);
  --ar-alert-border-color: rgba(237, 203, 80, .25);
}
.dark-mode .alert-danger {
  --ar-alert-bg: rgba(237, 80, 80, .05);
  --ar-alert-border-color: rgba(237, 80, 80, .25);
}
.dark-mode .alert-light {
  --ar-alert-bg: rgba(255, 255, 255, .05);
  --ar-alert-border-color: rgba(255, 255, 255, .25);
}
.dark-mode .alert-dark {
  --ar-alert-bg: rgba(18, 21, 25, .05);
  --ar-alert-border-color: rgba(18, 21, 25, .25);
}
.dark-mode .alert-secondary,
.dark-mode .alert-light,
.dark-mode .alert-dark {
  --ar-alert-border-color: var( --ar-border-color);
}
.dark-mode .alert-secondary,
.dark-mode .alert-light {
  --ar-alert-bg: rgba(255, 255, 255, 0.04);
  --ar-alert-color: rgba(255, 255, 255, .8);
  --ar-alert-link-color: #fff;
}
.dark-mode .progress {
  background-color: rgba(255, 255, 255, 0.08);
}
.dark-mode .progress-bar.bg-dark {
  background-color: #fff !important;
  color: #121519;
}
.dark-mode .progress-bar.bg-dark.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(18, 21, 25, 0.15) 25%, transparent 25%, transparent 50%, rgba(18, 21, 25, 0.15) 50%, rgba(18, 21, 25, 0.15) 75%, transparent 75%, transparent);
}
.dark-mode .toast {
  --ar-toast-border-color: rgba(255, 255, 255, 0.13);
  --ar-toast-bg: #121519;
  box-shadow: 0 0.4375rem 1.25rem rgba(0, 0, 0, 0.4);
}
.dark-mode .toast-header::after {
  display: block !important;
}
.dark-mode .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.dark-mode .offcanvas, .dark-mode .offcanvas-sm, .dark-mode .offcanvas-md,
.dark-mode .offcanvas-lg, .dark-mode .offcanvas-xl, .dark-mode .offcanvas-xxl {
  --ar-offcanvas-bg: var(--ar-dark);
  --ar-offcanvas-box-shadow: 0 0.4375rem 1.25rem rgba(0, 0, 0, 0.4);
}
.dark-mode [data-simplebar] {
  --ar-scrollbar-bg: rgba(255, 255, 255, 0.28);
  --ar-scrollbar-track-bg: rgba(255, 255, 255, 0.13);
}
.dark-mode .flatpickr-calendar {
  border-color: rgba(255, 255, 255, 0.13);
  background: var(--ar-dark);
  box-shadow: 0 0.4375rem 1.25rem rgba(0, 0, 0, 0.4);
}
.dark-mode .flatpickr-day.inRange {
  border-color: transparent !important;
  box-shadow: -5px 0 0 rgba(255, 255, 255, 0.025), 5px 0 0 rgba(255, 255, 255, 0.025);
}
.dark-mode .ct-grid {
  stroke: rgba(255, 255, 255, 0.22);
}
.dark-mode .ct-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-mode .leaflet-popup-content-wrapper,
.dark-mode .leaflet-popup-tip {
  background: #121519;
}
.dark-mode .leaflet-container a.leaflet-popup-close-button {
  border-color: rgba(255, 255, 255, 0.18);
  color: rgba(255, 255, 255, 0.65);
}
.dark-mode .leaflet-container a.leaflet-popup-close-button:hover {
  color: #fff;
}
.dark-mode .fc-col-header-cell {
  border-bottom-color: var(--ar-gray-300) !important;
}
.dark-mode .fc-scrollgrid td {
  border-top-color: var(--ar-gray-300);
  border-left-color: var(--ar-gray-300);
}
.dark-mode .fc-list-day-cushion {
  background-color: #121519;
}