
.case-studies-container {
    display: flex;
    flex-direction: column;
    gap: var(--standard-seperation-distance);
}

/* Indivudal Case Study */
.case-study-container {
    display: flex;
    align-items: center;
    gap: 7.5%;
}

.case-study-image-container {
    position: relative;
    width: 50%;
    height: 45vh;
}

.case-study-background {
    position: absolute;
    background-color: #3F7FCA;
    width: 100%;
    height: 100%;
    border-radius: 7.5%;
}

.case-study-thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-image: url('https://around.createx.studio/assets/img/portfolio/list/06.png');
    background-size: cover;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 7.5%;
}

.case-study-container aside {
    width: 60%;
}

.case-study-container aside > h2 {
    width: 30ch;
}

.case-study-container aside > p {
    width: 50ch;
}

.case-study-tech-stack {
    display: flex;
    align-items: center;
    gap: 25px;
}

.case-study-company-logo {
    width: 90px;
}

.small-screen-only {
    display: none;
}

.large-screen-only {
    display: block;
}


@media (max-width: 992px) {
    .case-studies-section {
        padding: 0;
    }

    .case-studies-section > h1 {
        padding-top: 6%;
        text-align: center;
    }

    .case-studies-container {
        gap: 10vh;
    }

    .case-study-container {
        flex-direction: column;
    }

    .case-study-image-container {
        position: relative;
        width: 100%;
        height: 45vh;
    }

    .case-study-background {
        position: absolute;
        background-color: #3F7FCA;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }

    .case-study-thumbnail {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        border-radius: 0;
    }

    .case-study-container aside {
        width: 100%;
        padding: 5%;
    }


    .case-study-container aside > h2 {
        width: auto;
    }

    .case-study-container aside > p {
        width: auto;
    }

    .case-study-tech-stack {
        margin-top: 10% !important;
        justify-content: space-between;
        padding: 0;
    }

    .small-screen-only {
        display: block;
    }

    .large-screen-only {
        display: none !important;
    }
}