section.contact-container {
    display: flex;
    justify-content: center;
    margin-top: var(--standard-seperation-distance);
}

.contact-card {
    background-color: var(--ar-secondary);
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5% 5%;
    border-radius: var(--standard-border-radius);
    scroll-margin-top: calc(var(--navbar-height) + 5vh);
    margin-top: 0 !important;
}

.contact-card > h1 {
    color: white;
    margin-bottom: 4%;
}

.contact-card form {
    width: 70%;
}

@media (min-width: 768px) and (max-width: 992px) {
    .contact-card form {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .contact-card {
        width: 95%;
    }

    .contact-card form {
        width: 100%;
    }

    .contact-card form > *:not(.contact-card form > .form-group) {
        margin-top: 25px !important;
    }

    .contact-card form > .form-group > * {
        margin-top: 25px;
    }

    .contact-card {
        padding: 7.5% 5%;
    }
}