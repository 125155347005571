@media (max-width: 768px) {
    .form-group {
        margin-top: 0 !important;
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .form-group > * {
        margin-top: 3%;
    }
}

.error-label {
    color: red;
    animation: shake 0.2s ease-in-out 0s 2;
}

@keyframes shake {
    0% {
        margin-left: 0rem;
    }

    25% {
        margin-left: 0.5rem;
    }

    75% {
        margin-left: -0.5rem;
    }

    100% {
        margin-left: 0rem;
    }
}