section#about {
    margin-top: var(--standard-seperation-distance);
    background-color: var(--just-darker);
    padding: calc(var(--standard-seperation-distance) / 2) 0;
}

.about-item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 50px;
    margin: var(--standard-seperation-distance) auto 0 auto;
}

.about-item .thumbnail {
    border-radius: var(--standard-border-radius);
    background-size: cover;
    background-position: 45% 100%;
    height: 35vw;
    width: 35vw;
}

.about-item .content {
    flex: 1;
}

.about-item .content > h1 {
    width: 20ch;
}

/* Domain specific. */

#about-side-by-side {
    display: flex;
}

@media (max-width: 992px) {
    section#about {
        padding: calc(var(--standard-seperation-distance) / 2) 0;
    }

    .about-item {
        flex-direction: column-reverse;
        margin: 0;
        margin-top: var(--standard-seperation-distance);
    }

    .about-item .content {
        width: 100%;
    }

    .about-item .thumbnail-container {
        position: static !important;
        width: 100%;
    }

    .about-item .thumbnail {
        width: 100%;
        height: 40vh;
        border-radius: 0;
    }

    .about-item .content > h1 {
        width: auto;
    }

    .about-item p {
        width: auto !important;
    }

    #about-side-by-side {
        flex-direction: column;
        align-items: center;
    }

    #about-side-by-side > div {
        margin: 0 !important;
        margin-top: 30px !important;
        width: auto !important;
    }

    #about-side-by-side > div:first-child {
        margin-top: 15px !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .about-item .thumbnail {
        width: 100%;
        height: 60vh;
        border-radius: 0;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .about-item .thumbnail {
        width: 20vw;
        height: 30vw;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .about-item .thumbnail {
        width: 30vw;
        height: 30vw;
    }
}

@media (min-width: 1500px) {
    .about-item .thumbnail {
        width: 500px;
        height: 500px;
    }
}