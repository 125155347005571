:root {
    --footer-height: 25vh;
}

footer {
    margin-top: var(--standard-seperation-distance);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--footer-height);
    background-color: var(--ar-secondary) !important;
    padding: 0 10%;
}

footer > aside {
    display: flex;
    justify-content: flex-end;
    gap: 25%;
}

footer > div > p {
    padding-bottom: 2.5vh;
}

footer > div > a {
    text-decoration: none;
}

.icon-circle-btn {
    color: white;
    font-size: 34px;
    text-decoration: none;
    margin-bottom: 2.5vh;
    margin-block-end: 0;
}

.footer-legal-container p {
    color: var(--ar-gray-500);
}

.footer-legal-container a {
    text-decoration: underline;
    color: var(--ar-gray-300);
}

@media (max-width: 992px) {
    footer {
        flex-direction: column-reverse;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 10%;
        height: var(--footer-height) * 0.15;
    }

    .icon-circle-btn {
        font-size: 24px;
    }

    footer > div > p {
        margin-top: 15px;
        font-size: 11px;
    }

    footer > div > a {
        margin-top: 15px;
        font-size: 11px;
    }
}