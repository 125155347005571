/* Addapted from https://www.youtube.com/watch?v=nAjR0Oj0J8E, 
for an understanding of how the css works checkout that link. */

:root {
    --carousel-element-width: 150px;
    --carousel-element-spacing: 50px;
}

@keyframes carousel-slider {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.carousel {
  overflow: hidden;
  background: white;
  white-space: nowrap;
  position: relative;
}

.carousel-slide {
  display: inline-block;
  animation-name: carousel-slider;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.carousel:hover .carousel-slide {
    animation-play-state: paused;
}

.carousel-slide > * {
  width: var(--carousel-element-width);
  margin: 0 var(--carousel-element-spacing);
  display: inline-block;
}

.carousel-slide > *:last-of-type {
    margin-right: calc(var(--carousel-element-spacing) + calc(var(--carousel-element-width) / 18));
}

/* Fade one the sides. */

.carousel:before {
    left: 0;
    background: linear-gradient(to left, rgba(255,255,255, 0), white);
}

.carousel:after {
    right: 0;
    background: linear-gradient(to right, rgba(255,255,255, 0), white);
}

.carousel:before,
.carousel:after {
    position: absolute;
    top: 0;
    width: 75px;
    height: 100%;
    content: "";
    z-index: 2;
}

