.alert-container {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Apply a blured background to hide content behind modal. */
.background-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(1rem);
}

/* TODO: Need to make responsive. */
.alert-card {
    width: 25%;
}

/* Adjust width for screens smaller than 768px */
@media only screen and (max-width: 992px) {
    .alert-card {
        width: 35%;
    }
}

/* Adjust width for screens smaller than 768px */
@media only screen and (max-width: 768px) {
    .alert-card {
        width: 50%;
    }
}

/* Adjust width for screens smaller than 480px */
@media only screen and (max-width: 480px) {
    .alert-card {
        width: 75%;
    }
}